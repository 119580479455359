
import * as INVM_SAVEConstants from '../Constants/INVM_SAVEConstants';

const intialstate = {};

function SaveItemCategoryReducer(state = {
    SaveItemCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemCategory_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemCategory_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemCategory_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemCategoryData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemSubCategoryReducer(state = {
    SaveItemSubCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemSubCategory_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemSubCategory_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemSubCategory_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemSubCategoryData: action.payload
            }
        }
        default: return state;
    }

}
function SaveChargesReducer(state = {
    SaveChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.Charges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.Charges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.Charges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveChargesData: action.payload
            }
        }
        default: return state;
    }

}
function SaveUnitMasterReducer(state = {
    SaveUnitMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UnitMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UnitMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UnitMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveUnitMasterData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemUnitMasterReducer(state = {
    SaveItemUnitMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemUnitMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemUnitMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemUnitMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemUnitMasterData: action.payload
            }
        }
        default: return state;
    }

}
function SaveHSNGroupReducer(state = {
    SaveHSNGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.HSNGroup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.HSNGroup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.HSNGroup_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveHSNGroupData: action.payload
            }
        }
        default: return state;
    }

}
function SaveHSNCodeReducer(state = {
    SaveHSNCodeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.HSNCode_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.HSNCode_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.HSNCode_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveHSNCodeData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemMasterReducer(state = {
    SaveItemMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SaveNewItemRequestReducer(state = {
    SaveNewItemRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.NewItemRequest_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.NewItemRequest_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.NewItemRequest_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveNewItemRequestData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemChargesReducer(state = {
    SaveItemChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemCharges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemCharges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemCharges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemChargesData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemAttributesReducer(state = {
    SaveItemAttributesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemAttributes_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemAttributes_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemAttributes_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemAttributesData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemwithItemUnitMappingReducer(state = {
    SaveItemwithItemUnitMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemwithItemUnitMapping_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemwithItemUnitMapping_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemwithItemUnitMapping_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemwithItemUnitMappingData: action.payload
            }
        }
        default: return state;
    }

}
function SaveChargeTemplateMainReducer(state = {
    SaveChargeTemplateMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ChargeTemplateMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ChargeTemplateMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ChargeTemplateMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveChargeTemplateMainData: action.payload
            }
        }
        default: return state;
    }

}
function SaveChargeTemplateDetailsReducer(state = {
    SaveChargeTemplateDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ChargeTemplateDetails_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ChargeTemplateDetails_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ChargeTemplateDetails_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveChargeTemplateDetailsData: action.payload
            }
        }
        default: return state;
    }

}
function SaveTherapeuticCategoryReducer(state = {
    SaveTherapeuticCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.TherapeuticCategory_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.TherapeuticCategory_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.TherapeuticCategory_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveTherapeuticCategoryData: action.payload
            }
        }
        default: return state;
    }

}
function SaveTherapeuticSubCategoryReducer(state = {
    SaveTherapeuticSubCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.TherapeuticSubCategory_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.TherapeuticSubCategory_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.TherapeuticSubCategory_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveTherapeuticSubCategoryData: action.payload
            }
        }
        default: return state;
    }

}
//tony
function SaveGenericMasterReducer(state = {
    SaveGenericMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.GenericMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.GenericMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.GenericMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveGenericMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SaveEmpUpdateTimeForExportCSVReducer(state = {
    SaveEmpUpdateTimeForExportCSVData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveEmpUpdateTimeForExportCSV_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveEmpUpdateTimeForExportCSV_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveEmpUpdateTimeForExportCSV_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveEmpUpdateTimeForExportCSVData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemGenericMappingReducer(state = {
    SaveItemGenericData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemGenericMapping_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemGenericMapping_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemGenericMapping_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemGenericData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemFormMasterReducer(state = {
    SaveItemFormMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemFormMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemFormMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemFormMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemFormMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemFacilityMappingReducer(state = {
    SaveItemFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemFacilityMapping_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemFacilityMapping_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemFacilityMapping_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemFacilityData: action.payload
            }
        }
        default: return state;
    }

}

function SaveGRNMainReducer(state = {
    SaveGRNMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.GRNMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.GRNMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.GRNMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveGRNMainData: action.payload

            }
        }
        default: return state;


    }

}

function UpdateGRNReducer(state = {
    UpdateGRNData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateGrn_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateGrn_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateGrn_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UpdateGRNData: action.payload

            }
        }
        default: return state;
    }

}

function SavePOMainReducer(state = {
    SavePOMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.POMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.POMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.POMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePOMainData: action.payload

            }
        }
        default: return state;


    }

}
function SaveWorkOrderReducer(state = {
    SaveWorkOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.WorkOrder_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.WorkOrder_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.WorkOrder_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveWorkOrderData: action.payload

            }
        }
        default: return state;


    }

}
function SaveAddVaccinationReducer(state = {
    SaveAddVaccinationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.AddVaccination_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.AddVaccination_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.AddVaccination_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveAddVaccinationData: action.payload

            }
        }
        default: return state;


    }

}

function SaveReusableItemReducer(state = {
    SaveReusableItemData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ReusableItem_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ReusableItem_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ReusableItem_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveReusableItemData: action.payload

            }
        }
        default: return state;


    }

}
function SaveReusableItemChargesReducer(state = {
    SaveReusableItemChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ReusableItemCharges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ReusableItemCharges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ReusableItemCharges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveReusableItemChargesData: action.payload

            }
        }
        default: return state;


    }

}

function SaveGRNItemChargesReducer(state = {
    SaveGRNItemChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.GRNItemCharges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.GRNItemCharges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.GRNItemCharges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveGRNItemChargesData: action.payload

            }
        }
        default: return state;


    }

}
function SaveStocksReducer(state = {
    SaveStocksData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.Stocks_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.Stocks_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.Stocks_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStocksData: action.payload

            }
        }
        default: return state;


    }

}


function PostCancelGRNReducer(state = {
    PostCancelGRNData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.PostCancelGRN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.PostCancelGRN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.PostCancelGRN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PostCancelGRNData: action.payload

            }
        }
        default: return state;


    }

}

function SavePrescriptionUnitReducer(state = {
    SavePrescriptionUnitData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.PrescriptionUnit_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.PrescriptionUnit_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.PrescriptionUnit_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePrescriptionUnitData: action.payload

            }
        }
        default: return state;


    }

}

function SaveConsignmentMainReducer(state = {
    SaveConsignmentMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ConsignmentMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ConsignmentMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ConsignmentMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentMainData: action.payload

            }
        }
        default: return state;


    }

}

function SaveConsignmentItemChargesReducer(state = {
    SaveConsignmentItemChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ConsignmentItemCharges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ConsignmentItemCharges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ConsignmentItemCharges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentItemChargesData: action.payload

            }
        }
        default: return state;


    }

}

function PostCancelConsignmentReducer(state = {
    PostCancelConsignmentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.PostCancelConsignment_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.PostCancelConsignment_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.PostCancelConsignment_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PostCancelConsignmentData: action.payload

            }
        }
        default: return state;


    }

}



function SaveConsignmentReceiveMainReducer(state = {
    SaveConsignmentMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ConsignmentReceiveMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ConsignmentReceiveMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ConsignmentReceiveMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentReceiveMainData: action.payload

            }
        }
        default: return state;


    }

}

function SaveConsignmentReceiveItemChargesReducer(state = {
    SaveConsignmentReceiveItemChargesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ConsignmentReceiveItemCharges_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ConsignmentReceiveItemCharges_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ConsignmentReceiveItemCharges_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentReceiveItemChargesData: action.payload

            }
        }
        default: return state;


    }

}


//---START----------------------------POApproval Setup-----------------------------------By Shashi Prasad
function SavePOApprovalSetupReducer(state = {
    SavePOApprovalSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.POAPPROVALSETUP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.POAPPROVALSETUP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.POAPPROVALSETUP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePOApprovalSetupData: action.payload
            }

        }
        default: return state;
    }

}
function SaveSupplierDetailReducer(state = {
    SaveSupplierDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SUPPLIERDETAIL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SUPPLIERDETAIL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SUPPLIERDETAIL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierDetailData: action.payload

            }
        }
        default: return state;


    }

}
function SaveSupplierTermsConditionReducer(state = {
    SaveSupplierTermsConditionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SUPPLIERTERMSCONDITION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SUPPLIERTERMSCONDITION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SUPPLIERTERMSCONDITION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierTermsConditionData: action.payload

            }
        }
        default: return state;


    }

}
function SaveTermsConditionReducer(state = {
    SaveTermsConditionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.TERMS_CONDITION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.TERMS_CONDITION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.TERMS_CONDITION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveTermsConditionData: action.payload

            }
        }
        default: return state;


    }

}
function SaveSupplierFacilityMappingReducer(state = {
    SaveSupplierFacilityMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SUPPLIER_FACILITY_MAPPING_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SUPPLIER_FACILITY_MAPPING_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SUPPLIER_FACILITY_MAPPING_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierFacilityMappingData: action.payload

            }
        }
        default: return state;


    }

}
function SaveSupplierItemMappingReducer(state = {
    SaveSupplierItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SUPPLIER_ITEM_MAPPING_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SUPPLIER_ITEM_MAPPING_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SUPPLIER_ITEM_MAPPING_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierItemMappingData: action.payload

            }
        }
        default: return state;


    }

}

function SaveSupplierLoginDetailReducer(state = {
    SaveSupplierLoginDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SUPPLIER_LOGIN_DETAIL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SUPPLIER_LOGIN_DETAIL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SUPPLIER_LOGIN_DETAIL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierLoginDetailData: action.payload

            }
        }
        default: return state;


    }

}



//-----------------
function SaveStoreReducer(state = {
    SaveStoreData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreData: action.payload

            }
        }
        default: return state;


    }

}

function SaveStoreTransactionTypeReducer(state = {
    SaveStoreTransactionTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_TRANSACTIONTYPE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_TRANSACTIONTYPE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_TRANSACTIONTYPE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreTransactionTypeData: action.payload

            }
        }
        default: return state;


    }

}

function SaveStoreItemMappingReducer(state = {
    SaveStoreItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_ITEM_MAPPING_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_ITEM_MAPPING_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_ITEM_MAPPING_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreItemMappingData: action.payload

            }
        }
        default: return state;


    }

}
function SaveStoreItemAutoConsumptionReducer(state = {
    SaveStoreItemAutoConsumptionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_ItemAutoConsumption_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_ItemAutoConsumption_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_ItemAutoConsumption_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreItemAutoConsumptionData: action.payload

            }
        }
        default: return state;


    }

}

function SaveStoreROLSetupReducer(state = {
    SaveStoreROLSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_ROLSETUP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_ROLSETUP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_ROLSETUP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreROLSetupData: action.payload

            }
        }
        default: return state;


    }

}

function SaveStoreIndentAffilliationReducer(state = {
    SaveStoreIndentAffilliationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_AFFILLIATION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_AFFILLIATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_AFFILLIATION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreIndentAffilliationData: action.payload

            }
        }
        default: return state;


    }

}

function SaveStoreAutoIndetFrequencyReducer(state = {
    SaveStoreAutoIndetFrequencyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.STORE_AUTO_INDENT_FREQUENCY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.STORE_AUTO_INDENT_FREQUENCY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.STORE_AUTO_INDENT_FREQUENCY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreAutoIndetFrequencyData: action.payload

            }
        }
        default: return state;


    }

}

function SaveBinLocationReducer(state = {
    SaveBinLocationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.BINLOCATION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.BINLOCATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.BINLOCATION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveBinLocationData: action.payload

            }
        }
        default: return state;


    }

}
function SaveItemSalePriceSetupReducer(state = {
    SaveItemSalePriceSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ITEM_SALE_PRICE_SETUP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ITEM_SALE_PRICE_SETUP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ITEM_SALE_PRICE_SETUP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemSalePriceSetupData: action.payload

            }
        }
        default: return state;


    }

}
function saveKITMasterReducer(state = {
    saveKITMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.KITMASTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.KITMASTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.KITMASTER_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveKITMasterData: action.payload

            }
        }
        default: return state;


    }

}
function saveKITItemMappingReducer(state = {
    saveKITItemMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.KIT_ITEM_MAPPING_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.KIT_ITEM_MAPPING_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.KIT_ITEM_MAPPING_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveKITItemMappingData: action.payload

            }
        }
        default: return state;


    }

}
function saveDepartmentIndentReducer(state = {
    saveDepartmentIndentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DEPARTMENT_INDENT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DEPARTMENT_INDENT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DEPARTMENT_INDENT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDepartmentIndentData: action.payload

            }
        }
        default: return state;


    }

}
function saveDepartmentIndentApprovalOrRejectionReducer(state = {
    saveDepartmentIndentApprovalOrRejectionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DepartmentIndentApprovalOrRejection_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DepartmentIndentApprovalOrRejection_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DepartmentIndentApprovalOrRejection_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDepartmentIndentApprovalOrRejectionData: action.payload

            }
        }
        default: return state;


    }

}
function saveDepartmentIndentAllocationReducer(state = {
    saveDepartmentIndentAllocationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DepartmentIndent_Allocation_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DepartmentIndent_Allocation_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DepartmentIndent_Allocation_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDepartmentIndentAllocationData: action.payload

            }
        }
        default: return state;


    }

}
function saveDepartmentIssueReducer(state = {
    saveDepartmentIssueData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DEPARTMENTISSUE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DEPARTMENTISSUE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DEPARTMENTISSUE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDepartmentIssueData: action.payload

            }
        }
        default: return state;


    }

}
function SaveDepartmentReceiveReducer(state = {
    SaveDepartmentReceiveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.INDENTRECEIVE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.INDENTRECEIVE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.INDENTRECEIVE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDepartmentReceiveData: action.payload

            }
        }
        default: return state;


    }

}
function SaveDepartmentReceiveReturnReducer(state = {
    SaveDepartmentReceiveReturnData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.INDENT_RECEIVE_RETURN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.INDENT_RECEIVE_RETURN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.INDENT_RECEIVE_RETURN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDepartmentReceiveReturnData: action.payload

            }
        }
        default: return state;


    }

}
function SaveDepartmentReturnReducer(state = {
    SaveDepartmentReturnData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.INDENTRETURN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.INDENTRETURN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.INDENTRETURN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDepartmentReturnData: action.payload

            }
        }
        default: return state;


    }

}
function SaveDepartmentReturnAckReducer(state = {
    SaveDepartmentReturnAckData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.INDENTRETURN_ACK_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.INDENTRETURN_ACK_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.INDENTRETURN_ACK_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDepartmentReturnAckData: action.payload

            }
        }
        default: return state;


    }

}
function DepartmentReturnCancelReducer(state = {
    DepartmentReturnCancelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.INDENTRETURN_CANCEL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.INDENTRETURN_CANCEL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.INDENTRETURN_CANCEL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DepartmentReturnCancelData: action.payload

            }
        }
        default: return state;


    }

}
function savePurchaseIndentReducer(state = {
    savePurchaseIndentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.PURCHASEINDENT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.PURCHASEINDENT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.PURCHASEINDENT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePurchaseIndentData: action.payload

            }
        }
        default: return state;


    }

}
function SavePurchaseIndentApprovalorRejectionReducer(state = {
    SavePurchaseIndentApprovalorRejectionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.PURCHASEINDENT_APPROVE_REJECT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.PURCHASEINDENT_APPROVE_REJECT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.PURCHASEINDENT_APPROVE_REJECT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePurchaseIndentApprovalorRejectionData: action.payload

            }
        }
        default: return state;


    }

}
function saveDepartmentConsumptionReducer(state = {
    saveDepartmentConsumptionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveDepartmentConsumption_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveDepartmentConsumption_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveDepartmentConsumption_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDepartmentConsumptionData: action.payload

            }
        }
        default: return state;


    }

}
function saveIPIssueReducer(state = {
    saveIPIssueData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SAVEIPISSUE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SAVEIPISSUE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SAVEIPISSUE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveIPIssueData: action.payload

            }
        }
        default: return state;


    }

}

function saveIPIssueAllocationReducer(state = {
    saveIPIssueAllocationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SAVEIPIssueAllocation_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SAVEIPIssueAllocation_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SAVEIPIssueAllocation_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveIPIssueAllocationData: action.payload

            }
        }
        default: return state;


    }

}

function saveRxWardAcknowledgeReducer(state = {
    saveRxWardAcknowledgeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SAVERxWardAcknowledge_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SAVERxWardAcknowledge_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SAVERxWardAcknowledge_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveRxWardAcknowledgeData: action.payload

            }
        }
        default: return state;


    }

}

function saveIPIssueReturnReducer(state = {
    saveIPIssueReturnData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SAVEIPISSUERETURN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SAVEIPISSUERETURN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SAVEIPISSUERETURN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveIPIssueReturnData: action.payload

            }
        }
        default: return state;


    }

}

function saveSupplierReturnReducer(state = {
    saveSupplierReturnData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveSupplierReturn_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveSupplierReturn_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveSupplierReturn_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveSupplierReturnData: action.payload

            }
        }
        default: return state;


    }

}

function SaveQuotationReducer(state = {
    SaveQuotationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveQuotation_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveQuotation_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveQuotation_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveQuotationData: action.payload

            }
        }
        default: return state;


    }

}
function UpdateQuotationStatusReducer(state = {
    UpdateQuotationStatusData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateQuotationStatus_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateQuotationStatus_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateQuotationStatus_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UpdateQuotationStatusData: action.payload

            }
        }
        default: return state;


    }

}
function InActiveQuotationItemsReducer(state = {
    InActiveQuotationItemsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.InActiveQuotationItems_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.InActiveQuotationItems_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.InActiveQuotationItems_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InActiveQuotationItemsData: action.payload

            }
        }
        default: return state;


    }

}
function SaveManufacturerSupplierMappingReducer(state = {
    SaveManufacturerSupplierMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ManufacturerSupplierMapping_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ManufacturerSupplierMapping_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ManufacturerSupplierMapping_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveManufacturerSupplierMappingData: action.payload

            }
        }
        default: return state;


    }

}
function ItemWisePOCloseReducer(state = {
    ItemWisePOCloseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemWisePOClose_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemWisePOClose_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemWisePOClose_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemWisePOCloseData: action.payload

            }
        }
        default: return state;


    }

}
function saveConsignmentAllocationReducer(state = {
    saveConsignmentAllocationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveConsignmentAllocation_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveConsignmentAllocation_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveConsignmentAllocation_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveConsignmentAllocationData: action.payload

            }
        }
        default: return state;


    }

}
function saveConsignmentReturnReducer(state = {
    saveConsignmentReturnData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveConsignmentReturn_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveConsignmentReturn_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveConsignmentReturn_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveConsignmentReturnData: action.payload

            }
        }
        default: return state;


    }

}
function SaveConsignmentIssueReducer(state = {
    SaveConsignmentIssueData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveConsignmentIssue_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveConsignmentIssue_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveConsignmentIssue_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentIssueData: action.payload

            }
        }
        default: return state;


    }

}
function SaveConsignmentIssueReturnAsyncReducer(state = {
    SaveConsignmentIssueReturnAsyncData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveConsignmentIssueReturnAsync_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveConsignmentIssueReturnAsync_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveConsignmentIssueReturnAsync_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveConsignmentIssueReturnAsyncData: action.payload

            }
        }
        default: return state;


    }

}
function DocumentsUploadReducer(state = {
    DocumentsUploadData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DocumentsUpload_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DocumentsUpload_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DocumentsUpload_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DocumentsUploadData: action.payload

            }
        }
        default: return state;


    }

}
function DeleteDocumentReducer(state = {
    DeleteDocumentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.DeleteDocument_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.DeleteDocument_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.DeleteDocument_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeleteDocumentData: action.payload

            }
        }
        default: return state;


    }

}
//---END-------------------------------------------------------------------------------------------------

//----------------------------------------TONY-----------------------------------------------------------

//----------------------------------SHRIKANT SAINI-------------------------------------------------------
function SaveOPIssueSaleMainReducer(state = {
    SaveOPIssueSaleMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.OPIssueSaleMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.OPIssueSaleMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.OPIssueSaleMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveOPIssueSaleMainData: action.payload
            }
        }
        default: return state;
    }
}
function saveBounceItemsReducer(state = {
    saveBounceItemsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.BounceItem_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.BounceItem_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.BounceItem_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveBounceItemsData: action.payload
            }
        }
        default: return state;
    }
}

function SaveRxReviewReducer(state = {
    SaveRxReviewData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.RxReview_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.RxReview_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.RxReview_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveRxReviewData: action.payload
            }
        }
        default: return state;
    }
}

function SaveOPIssueSaleReturnMainReducer(state = {
    SaveOPIssueSaleReturnMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.OPIssueSaleReturnMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.OPIssueSaleReturnMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.OPIssueSaleReturnMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveOPIssueSaleReturnMainData: action.payload
            }
        }
        default: return state;
    }
}
function SaveEmpStoreAccessDataReducer(state = {
    SaveEmpStoreAccessData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.EmpStoreAccessData_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.EmpStoreAccessData_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.EmpStoreAccessData_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveEmpStoreAccessData: action.payload
            }
        }
        default: return state;
    }
}


function SaveStockAdjMainReducer(state = {
    SaveStockAdjMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.StockAdj_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.StockAdj_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.StockAdj_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStockAdjMainData: action.payload

            }
        }
        default: return state;


    }

}

function SaveBatchExpiryMainReducer(state = {
    SaveBatchExpiryMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.BatchExpiry_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.BatchExpiry_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.BatchExpiry_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveBatchExpiryMainData: action.payload

            }
        }
        default: return state;


    }

}

function SaveItemRouteReducer(state = {
    SaveItemRouteData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemRoute_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemRoute_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemRoute_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemRouteData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemRouteFormReducer(state = {
    SaveItemRouteFormData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemRouteForm_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemRouteForm_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemRouteForm_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemRouteFormData: action.payload
            }
        }
        default: return state;
    }

}

function SaveFrequencyMasterReducer(state = {
    SaveFrequencyMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.Frequency_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.Frequency_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.Frequency_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveFrequencyMasterData: action.payload
            }
        }
        default: return state;
    }

}
function SaveItemDiscountSetupReducer(state = {
    ItemDiscountSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.ItemDisc_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.ItemDisc_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.ItemDisc_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemDiscountSetupData: action.payload
            }
        }
        default: return state;
    }

}
function saveItemDiscountReducer(state = {
    saveItemDiscountData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveItemDiscount_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveItemDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveItemDiscount_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveItemDiscountData: action.payload
            }
        }
        default: return state;
    }

}
function SaveCompanyNonDiscountableItemsReducer(state = {
    CompanyNonDiscountableItemsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.CompanyNonDiscountable_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.CompanyNonDiscountable_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.CompanyNonDiscountable_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyNonDiscountableItemsData: action.payload
            }
        }
        default: return state;
    }

}
function SaveCompanyItemExclusionReducer(state = {
    CompanyItemExclusionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.CompanyItemExclusion_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.CompanyItemExclusion_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.CompanyItemExclusion_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyItemExclusionData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemNameSetupReducer(state = {
    SaveItemNameSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveItemNameSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveItemNameSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveItemNameSetup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemNameSetupData: action.payload
            }
        }
        default: return state;
    }

}

function SaveItemCommonMasterReducer(state = {
    SaveItemCommonMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.saveCommonMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.saveCommonMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.saveCommonMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveItemCommonMasterData: action.payload
            }
        }
        default: return state;
    }
}
function SavePractitionerDetailsReducer(state = {
    SavePractitionerDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.savePractitioner_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.savePractitioner_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.savePractitioner_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePractitionerDetailsData: action.payload
            }
        }
        default: return state;
    }
}
function SavePatientRegistrationDetailsReducer(state = {
    SavePatientRegistrationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.savePatientRegistration_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.savePatientRegistration_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.savePatientRegistration_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePatientRegistrationData: action.payload
            }
        }
        default: return state;
    }
}
function UpdateOPIssueSaleAfterPrintReducer(state = {
    UpdateOPIssueSaleAfterPrintData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateOPIssueSaleAfterPrint_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateOPIssueSaleAfterPrint_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateOPIssueSaleAfterPrint_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveOPIssueSaleMainData: action.payload
            }
        }
        default: return state;
    }
}
function UpdateIPIssueAfterPrintReducer(state = {
    UpdateIPIssueAfterPrintData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateIPIssueAfterPrint_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateIPIssueAfterPrint_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateIPIssueAfterPrint_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveIPIssueMainData: action.payload
            }
        }
        default: return state;
    }
}

function UpdateReusableItemStatusReducer(state = {
    UpdateReusableItemStatusData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateReusableItemUsage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateReusableItemUsage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateReusableItemUsage_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UpdateReusableItemStatusData: action.payload
            }
        }
        default: return state;
    }
}

function GRNApprovalCancelReducer(state = {
    GRNApprovalCancelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.GRNApprovalCancel_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.GRNApprovalCancel_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.GRNApprovalCancel_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GRNApprovalCancelData: action.payload
            }
        }
        default: return state;
    }
}
function SaveStoreSubMappingList(state = {
    SaveStoreSubMappingListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveStoreSubMappingList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveStoreSubMappingList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveStoreSubMappingList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStoreSubMappingListData: action.payload

            }
        }
        default: return state;


    }

}


function SavePOAdvanceScheduleReducer(state = {
    SavePOAdvanceScheduleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SavePOAdvanceSchedule_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SavePOAdvanceSchedule_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SavePOAdvanceSchedule_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePOAdvanceScheduleData: action.payload

            }
        }
        default: return state;

    }

}


function SaveWorkOrderAdvanceScheduleReducer(state = {
    SaveWorkOrderAdvanceScheduleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveWorkOrderAdvanceSchedule_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveWorkOrderAdvanceSchedule_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveWorkOrderAdvanceSchedule_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveWorkOrderAdvanceScheduleData: action.payload

            }
        }
        default: return state;

    }

}


function UpdateGrnAcknowledgementReducer(state = {
    UpdateGrnAcknowledgementData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.UpdateGrnAcknowledgement_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.UpdateGrnAcknowledgement_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.UpdateGrnAcknowledgement_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UpdateGrnAcknowledgementData: action.payload

            }
        }
        default: return state;

    }

}


function SaveSupplierCreditNoteReducer(state = {
    SaveSupplierCreditNoteData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case INVM_SAVEConstants.SaveSupplierCreditNote_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case INVM_SAVEConstants.SaveSupplierCreditNote_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case INVM_SAVEConstants.SaveSupplierCreditNote_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSupplierCreditNoteData: action.payload

            }
        }
        default: return state;

    }

}


//-------------------------------------------------------------------------------------------------------

export {
    SaveItemCategoryReducer, SaveItemSubCategoryReducer, SaveChargesReducer, SaveUnitMasterReducer, SaveItemUnitMasterReducer,
    SaveHSNGroupReducer, SaveHSNCodeReducer, SaveItemMasterReducer, SaveItemChargesReducer,
    SaveItemAttributesReducer, SaveItemwithItemUnitMappingReducer, SaveChargeTemplateMainReducer, SaveChargeTemplateDetailsReducer,
    SaveTherapeuticCategoryReducer, SaveTherapeuticSubCategoryReducer, SaveGenericMasterReducer, SaveEmpUpdateTimeForExportCSVReducer, SaveItemGenericMappingReducer,
    SaveItemFormMasterReducer, SaveItemFacilityMappingReducer, SaveGRNMainReducer, SavePOMainReducer, SaveReusableItemReducer, SaveWorkOrderReducer, SaveReusableItemChargesReducer, SaveAddVaccinationReducer,
    SaveGRNItemChargesReducer, SaveStocksReducer, PostCancelGRNReducer, SavePrescriptionUnitReducer,
    SaveConsignmentMainReducer, SaveConsignmentItemChargesReducer, PostCancelConsignmentReducer,
    SaveConsignmentReceiveMainReducer, SaveConsignmentReceiveItemChargesReducer, SaveNewItemRequestReducer, UpdateGRNReducer,

    SavePOApprovalSetupReducer, SaveSupplierDetailReducer, SaveSupplierTermsConditionReducer, SaveStoreItemAutoConsumptionReducer,
    SaveTermsConditionReducer, SaveSupplierFacilityMappingReducer, SaveSupplierItemMappingReducer, SaveSupplierLoginDetailReducer, SaveStoreReducer
    , SaveStoreTransactionTypeReducer, SaveStoreItemMappingReducer, SaveStoreROLSetupReducer, SaveStoreIndentAffilliationReducer, SaveStoreAutoIndetFrequencyReducer
    , SaveBinLocationReducer, SaveItemSalePriceSetupReducer, saveKITMasterReducer, saveKITItemMappingReducer, saveDepartmentIndentReducer
    , saveDepartmentIndentApprovalOrRejectionReducer, saveDepartmentIndentAllocationReducer, saveDepartmentIssueReducer,
    SaveDepartmentReceiveReducer, SaveDepartmentReceiveReturnReducer, SaveDepartmentReturnReducer, SaveDepartmentReturnAckReducer,
    DepartmentReturnCancelReducer, savePurchaseIndentReducer, SavePurchaseIndentApprovalorRejectionReducer,
    saveDepartmentConsumptionReducer, saveIPIssueReducer, saveIPIssueAllocationReducer, SaveBatchExpiryMainReducer,
    SaveOPIssueSaleMainReducer, SaveEmpStoreAccessDataReducer, SaveOPIssueSaleReturnMainReducer, SaveStockAdjMainReducer
    , saveIPIssueReturnReducer, saveRxWardAcknowledgeReducer, SaveItemRouteReducer, SaveItemRouteFormReducer, SaveFrequencyMasterReducer, saveSupplierReturnReducer
    , SaveItemDiscountSetupReducer, SaveCompanyNonDiscountableItemsReducer, SaveCompanyItemExclusionReducer, SaveQuotationReducer, UpdateQuotationStatusReducer
    , InActiveQuotationItemsReducer, SaveRxReviewReducer, SaveManufacturerSupplierMappingReducer, ItemWisePOCloseReducer
    , saveConsignmentAllocationReducer, saveConsignmentReturnReducer, SaveConsignmentIssueReducer, SaveConsignmentIssueReturnAsyncReducer, saveItemDiscountReducer
    , SaveItemNameSetupReducer, SaveItemCommonMasterReducer, UpdateOPIssueSaleAfterPrintReducer, DocumentsUploadReducer, DeleteDocumentReducer
    , UpdateReusableItemStatusReducer, saveBounceItemsReducer, UpdateIPIssueAfterPrintReducer, SavePractitionerDetailsReducer, SavePatientRegistrationDetailsReducer
    , GRNApprovalCancelReducer,SaveStoreSubMappingList,SavePOAdvanceScheduleReducer,SaveWorkOrderAdvanceScheduleReducer,UpdateGrnAcknowledgementReducer,SaveSupplierCreditNoteReducer
};