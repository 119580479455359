export const PATIENT_SEARCH_LIST_REQUEST = 'PATIENT_SEARCH_LIST_REQUEST';
export const PATIENT_SEARCH_LIST_SUCCESS = 'PATIENT_SEARCH_LIST_SUCCESS';
export const PATIENT_SEARCH_LIST_FAIL = 'PATIENT_SEARCH_LIST_FAIL';

export const CONSULTAION_CHARGE_LIST_REQUEST = 'CONSULTAION_CHARGE_LIST_REQUEST';
export const CONSULTAION_CHARGE_LIST_SUCCESS = 'CONSULTAION_CHARGE_LIST_SUCCESS';
export const CONSULTAION_CHARGE_LIST_FAIL = 'CONSULTAION_CHARGE_LIST_FAIL';

export const REGISTRATION_SAVE_REQUEST = 'REGISTRATION_SAVE_REQUEST';
export const REGISTRATION_SAVE_SUCCESS = 'REGISTRATION_SAVE_SUCCESS';
export const REGISTRATION_SAVE_FAIL = 'REGISTRATION_SAVE_FAIL';

export const SaveOtherdetailData_REQUEST = 'SaveOtherdetailData_REQUEST';
export const SaveOtherdetailData_SUCCESS = 'SaveOtherdetailData_SUCCESS';
export const SaveOtherdetailData_FAIL = 'SaveOtherdetailData_FAIL';

export const REGISTRATION_GET_REQUEST = 'REGISTRATION_GET_REQUEST';
export const REGISTRATION_GET_SUCCESS = 'REGISTRATION_GET_SUCCESS';
export const REGISTRATION_GET_FAIL = 'REGISTRATION_GET_FAIL';

export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_FAIL = 'BANK_LIST_FAIL';

export const PAYMENTMODE_LIST_REQUEST = 'PAYMENTMODE_LIST_REQUEST';
export const PAYMENTMODE_LIST_SUCCESS = 'PAYMENTMODE_LIST_SUCCESS';
export const PAYMENTMODE_LIST_FAIL = 'PAYMENTMODE_LIST_FAIL';

export const PAYMENTMODE_SAVE_REQUEST = 'PAYMENTMODE_SAVE_REQUEST';
export const PAYMENTMODE_SAVE_SUCCESS = 'PAYMENTMODE_SAVE_SUCCESS';
export const PAYMENTMODE_SAVE_FAIL = 'PAYMENTMODE_SAVE_FAIL';

export const TariffChargeAudit_LIST_REQUEST = 'TariffChargeAudit_LIST_REQUEST';
export const TariffChargeAudit_LIST_SUCCESS = 'TariffChargeAudit_LIST_SUCCESS';
export const TariffChargeAudit_LIST_FAIL = 'TariffChargeAudit_LIST_FAIL';

export const ServiceMstAudit_LIST_REQUEST = 'ServiceMstAudit_LIST_REQUEST';
export const ServiceMstAudit_LIST_SUCCESS = 'ServiceMstAudit_LIST_SUCCESS';
export const ServiceMstAudit_LIST_FAIL = 'ServiceMstAudit_LIST_FAIL';

export const REGISTRATIONTYPE_LIST_REQUEST = 'REGISTRATIONTYPE_LIST_REQUEST';
export const REGISTRATIONTYPE_LIST_SUCCESS = 'REGISTRATIONTYPE_LIST_SUCCESS';
export const REGISTRATIONTYPE_LIST_FAIL = 'REGISTRATIONTYPE_LIST_FAIL';

export const REGISTRATIONTYPE_SAVE_REQUEST = 'REGISTRATIONTYPE_SAVE_REQUEST';
export const REGISTRATIONTYPE_SAVE_SUCCESS = 'REGISTRATIONTYPE_SAVE_SUCCESS';
export const REGISTRATIONTYPE_SAVE_FAIL = 'REGISTRATIONTYPE_SAVE_FAIL';

export const Clinic_LIST_REQUEST = 'Clinic_SAVE_REQUEST';
export const Clinic_LIST_SUCCESS = 'Clinic_LIST_SUCCESS';
export const Clinic_LIST_FAIL = 'Clinic_SAVE_FAIL';

export const RELATIONSHIP_LIST_REQUEST = 'RELATIONSHIP_LIST_REQUEST';
export const RELATIONSHIP_LIST_SUCCESS = 'RELATIONSHIP_LIST_SUCCESS';
export const RELATIONSHIP_LIST_FAIL = 'RELATIONSHIP_LIST_FAIL';

export const RELATIONSHIP_SAVE_REQUEST = 'RELATIONSHIP_SAVE_REQUEST';
export const RELATIONSHIP_SAVE_SUCCESS = 'RELATIONSHIP_SAVE_SUCCESS';
export const RELATIONSHIP_SAVE_FAIL = 'RELATIONSHIP_SAVE_FAIL';

export const TITLE_LIST_REQUEST = 'TITLE_LIST_REQUEST';
export const TITLE_LIST_SUCCESS = 'TITLE_LIST_SUCCESS';
export const TITLE_LIST_FAIL = 'TITLE_LIST_FAIL';

export const TITLE_SAVE_REQUEST = 'TITLE_SAVE_REQUEST';
export const TITLE_SAVE_SUCCESS = 'TITLE_SAVE_SUCCESS';
export const TITLE_SAVE_FAIL = 'TITLE_SAVE_FAIL';

export const REFERRALTYPE_LIST_REQUEST = 'REFERRALTYPE_LIST_REQUEST';
export const REFERRALTYPE_LIST_SUCCESS = 'REFERRALTYPE_LIST_SUCCESS';
export const REFERRALTYPE_LIST_FAIL = 'REFERRALTYPE_LIST_FAIL';

export const REFERRALTYPE_SAVE_REQUEST = 'REFERRALTYPE_SAVE_REQUEST';
export const REFERRALTYPE_SAVE_SUCCESS = 'REFERRALTYPE_SAVE_SUCCESS';
export const REFERRALTYPE_SAVE_FAIL = 'REFERRALTYPE_SAVE_FAIL';

export const OCCUPATION_LIST_REQUEST = 'OCCUPATION_LIST_REQUEST';
export const OCCUPATION_LIST_SUCCESS = 'OCCUPATION_LIST_SUCCESS';
export const OCCUPATION_LIST_FAIL = 'OCCUPATION_LIST_FAIL';

export const OCCUPATION_SAVE_REQUEST = 'OCCUPATION_SAVE_REQUEST';
export const OCCUPATION_SAVE_SUCCESS = 'OCCUPATION_SAVE_SUCCESS';
export const OCCUPATION_SAVE_FAIL = 'OCCUPATION_SAVE_FAIL';

export const NATIONALITY_LIST_REQUEST = 'NATIONALITY_LIST_REQUEST';
export const NATIONALITY_LIST_SUCCESS = 'NATIONALITY_LIST_SUCCESS';
export const NATIONALITY_LIST_FAIL = 'NATIONALITY_LIST_FAIL';

export const NATIONALITY_SAVE_REQUEST = 'NATIONALITY_SAVE_REQUEST';
export const NATIONALITY_SAVE_SUCCESS = 'NATIONALITY_SAVE_SUCCESS';
export const NATIONALITY_SAVE_FAIL = 'NATIONALITY_SAVE_FAIL';

export const LANGUAGE_LIST_REQUEST = 'LANGUAGE_LIST_REQUEST';
export const LANGUAGE_LIST_SUCCESS = 'LANGUAGE_LIST_SUCCESS';
export const LANGUAGE_LIST_FAIL = 'LANGUAGE_LIST_FAIL';

export const LANGUAGE_SAVE_REQUEST = 'LANGUAGE_SAVE_REQUEST';
export const LANGUAGE_SAVE_SUCCESS = 'LANGUAGE_SAVE_SUCCESS';
export const LANGUAGE_SAVE_FAIL = 'LANGUAGE_SAVE_FAIL';

export const HWCTYPE_LIST_REQUEST = 'HWCTYPE_LIST_REQUEST';
export const HWCTYPE_LIST_SUCCESS = 'HWCTYPE_LIST_SUCCESS';
export const HWCTYPE_LIST_FAIL = 'HWCTYPE_LIST_FAIL';

export const HWCTYPE_SAVE_REQUEST = 'HWCTYPE_SAVE_REQUEST';
export const HWCTYPE_SAVE_SUCCESS = 'HWCTYPE_SAVE_SUCCESS';
export const HWCTYPE_SAVE_FAIL = 'HWCTYPE_SAVE_FAIL';

export const RELIGION_LIST_REQUEST = 'RELIGION_LIST_REQUEST';
export const RELIGION_LIST_SUCCESS = 'RELIGION_LIST_SUCCESS';
export const RELIGION_LIST_FAIL = 'RELIGION_LIST_FAIL';

export const RELIGION_SAVE_REQUEST = 'RELIGION_SAVE_REQUEST';
export const RELIGION_SAVE_SUCCESS = 'RELIGION_SAVE_SUCCESS';
export const RELIGION_SAVE_FAIL = 'RELIGION_SAVE_FAIL';

export const EDUCATION_LIST_REQUEST = 'EDUCATION_LIST_REQUEST';
export const EDUCATION_LIST_SUCCESS = 'EDUCATION_LIST_SUCCESS';
export const EDUCATION_LIST_FAIL = 'EDUCATION_LIST_FAIL';

export const EDUCATION_SAVE_REQUEST = 'EDUCATION_SAVE_REQUEST';
export const EDUCATION_SAVE_SUCCESS = 'EDUCATION_SAVE_SUCCESS';
export const EDUCATION_SAVE_FAIL = 'EDUCATION_SAVE_FAIL';

export const DOCUMENTTYPE_LIST_REQUEST = 'DOCUMENTTYPE_LIST_REQUEST';
export const DOCUMENTTYPE_LIST_SUCCESS = 'DOCUMENTTYPE_LIST_SUCCESS';
export const DOCUMENTTYPE_LIST_FAIL = 'DOCUMENTTYPE_LIST_FAIL';

export const DOCUMENTTYPE_SAVE_REQUEST = 'DOCUMENTTYPE_SAVE_REQUEST';
export const DOCUMENTTYPE_SAVE_SUCCESS = 'DOCUMENTTYPE_SAVE_SUCCESS';
export const DOCUMENTTYPE_SAVE_FAIL = 'DOCUMENTTYPE_SAVE_FAIL';

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';

export const COUNTRY_LIST_BILLING_REQUEST = 'COUNTRY_LIST_BILLING_REQUEST';
export const COUNTRY_LIST_BILLING_SUCCESS = 'COUNTRY_LIST_BILLING_SUCCESS';
export const COUNTRY_LIST_BILLING_FAIL = 'COUNTRY_LIST_BILLING_FAIL';

export const COUNTRY_SAVE_REQUEST = 'COUNTRY_SAVE_REQUEST';
export const COUNTRY_SAVE_SUCCESS = 'COUNTRY_SAVE_SUCCESS';
export const COUNTRY_SAVE_FAIL = 'COUNTRY_SAVE_FAIL';

export const STATE_LIST_REQUEST = 'STATE_LIST_REQUEST';
export const STATE_LIST_SUCCESS = 'STATE_LIST_SUCCESS';
export const STATE_LIST_FAIL = 'STATE_LIST_FAIL';

export const STATE_LIST_BILLING_REQUEST = 'STATE_LIST_BILLING_REQUEST';
export const STATE_LIST_BILLING_SUCCESS = 'STATE_LIST_BILLING_SUCCESS';
export const STATE_LIST_BILLING_FAIL = 'STATE_LIST_BILLING_FAIL';

export const STATE_SAVE_REQUEST = 'STATE_SAVE_REQUEST';
export const STATE_SAVE_SUCCESS = 'STATE_SAVE_SUCCESS';
export const STATE_SAVE_FAIL = 'STATE_SAVE_FAIL';

export const STATE_LIST_REQUEST_PER = 'STATE_LIST_REQUEST_PER';
export const STATE_LIST_SUCCESS_PER = 'STATE_LIST_SUCCESS_PER';
export const STATE_LIST_FAIL_PER = 'STATE_LIST_FAIL_PER';

export const Currency_LIST_REQUEST_PER = 'Currency_LIST_REQUEST_PER';
export const Currency_LIST_SUCCESS_PER = 'Currency_LIST_SUCCESS_PER';
export const Currency_LIST_FAIL_PER = 'Currency_LIST_FAIL_PER';

export const CURRENCYMASTER_LIST_REQUEST = 'CURRENCYMASTER_LIST_REQUEST';
export const CURRENCYMASTER_LIST_FAIL = 'CURRENCYMASTER_LIST_FAIL';
export const CURRENCYMASTER_LIST_SUCCESS = 'CURRENCYMASTER_LIST_SUCCESS';

export const CURRENCYMASTER_SAVE_REQUEST = 'CURRENCYMASTER_SAVE_REQUEST';
export const CURRENCYMASTER_SAVE_FAIL = 'CURRENCYMASTER_SAVE_FAIL';
export const CURRENCYMASTER_SAVE_SUCCESS = 'CURRENCYMASTER_SAVE_SUCCESS';

export const STATE_LIST_REQUEST_PRE = 'STATE_LIST_REQUEST_PRE';
export const STATE_LIST_SUCCESS_PRE = 'STATE_LIST_SUCCESS_PRE';
export const STATE_LIST_FAIL_PRE = 'STATE_LIST_FAIL_PRE';

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';
export const CITY_LIST_FAIL = 'CITY_LIST_FAIL';

export const CITY_LIST_BILLING_REQUEST = 'CITY_LIST_BILLING_REQUEST';
export const CITY_LIST_BILLING_SUCCESS = 'CITY_LIST_BILLING_SUCCESS';
export const CITY_LIST_BILLING_FAIL = 'CITY_LIST_BILLING_FAIL';

export const CITY_SAVE_REQUEST = 'CITY_SAVE_REQUEST';
export const CITY_SAVE_SUCCESS = 'CITY_SAVE_SUCCESS';
export const CITY_SAVE_FAIL = 'CITY_SAVE_FAIL';

export const CITY_LIST_REQUEST_PRE = 'CITY_LIST_REQUEST_PRE';
export const CITY_LIST_SUCCESS_PRE = 'CITY_LIST_SUCCESS_PRE';
export const CITY_LIST_FAIL_PRE = 'CITY_LIST_FAIL_PRE';

export const CITY_LIST_REQUEST_PER = 'CITY_LIST_REQUEST_PER';
export const CITY_LIST_SUCCESS_PER = 'CITY_LIST_SUCCESS_PER';
export const CITY_LIST_FAIL_PER = 'CITY_LIST_FAIL_PER';

export const AREAPER_LIST_REQUEST = 'AREAPER_LIST_REQUEST';
export const AREAPER_LIST_SUCCESS = 'AREAPER_LIST_SUCCESS';
export const AREAPER_LIST_FAIL = 'AREAPER_LIST_FAIL';

export const AREA_LIST_REQUEST = 'AREA_LIST_REQUEST';
export const AREA_LIST_SUCCESS = 'AREA_LIST_SUCCESS';
export const AREA_LIST_FAIL = 'AREA_LIST_FAIL';

export const AREA_LIST_BILLING_REQUEST = 'AREA_LIST_BILLING_REQUEST';
export const AREA_LIST_BILLING_SUCCESS = 'AREA_LIST_BILLING_SUCCESS';
export const AREA_LIST_BILLING_FAIL = 'AREA_LIST_BILLING_FAIL';

export const AREA_SAVE_REQUEST = 'AREA_SAVE_REQUEST';
export const AREA_SAVE_SUCCESS = 'AREA_SAVE_SUCCESS';
export const AREA_SAVE_FAIL = 'AREA_SAVE_FAIL';

export const HCF_LIST_REQUEST = 'HCF_LIST_REQUEST';
export const HCF_LIST_SUCCESS = 'HCF_LIST_SUCCESS';
export const HCF_LIST_FAIL = 'HCF_LIST_FAIL';

export const HCF_SAVE_REQUEST = 'HCF_SAVE_REQUEST';
export const HCF_SAVE_SUCCESS = 'HCF_SAVE_SUCCESS';
export const HCF_SAVE_FAIL = 'HCF_SAVE_FAIL';

export const PAYORCAT_LIST_REQUEST = 'PAYORCAT_LIST_REQUEST';
export const PAYORCAT_LIST_SUCCESS = 'PAYORCAT_LIST_SUCCESS';
export const PAYORCAT_LIST_FAIL = 'PAYORCAT_LIST_FAIL';

export const PAYORCAT_SAVE_REQUEST = 'PAYORCAT_SAVE_REQUEST';
export const PAYORCAT_SAVE_SUCCESS = 'PAYORCAT_SAVE_SUCCESS';
export const PAYORCAT_SAVE_FAIL = 'PAYORCAT_SAVE_FAIL';

export const REFERRAL_LIST_REQUEST = 'REFERRAL_LIST_REQUEST';
export const REFERRAL_LIST_SUCCESS = 'REFERRAL_LIST_SUCCESS';
export const REFERRAL_LIST_FAIL = 'REFERRAL_LIST_FAIL';

export const REFERRAL_SAVE_REQUEST = 'REFERRAL_SAVE_REQUEST';
export const REFERRAL_SAVE_SUCCESS = 'REFERRAL_SAVE_SUCCESS';
export const REFERRAL_SAVE_FAIL = 'REFERRAL_SAVE_FAIL';

export const CURRENCY_LIST_REQUEST = 'CURRENCY_LIST_REQUEST';
export const CURRENCY_LIST_SUCCESS = 'CURRENCY_LIST_SUCCESS';
export const CURRENCY_LIST_FAIL = 'CURRENCY_LIST_FAIL';

export const Month_LIST_REQUEST = 'Month_LIST_REQUEST';
export const Month_LIST_SUCCESS = 'Month_LIST_SUCCESS';
export const Month_LIST_FAIL = 'Month_LIST_FAIL';

export const YearMonth_LIST_REQUEST = 'YearMonth_LIST_REQUEST';
export const YearMonth_LIST_SUCCESS = 'YearMonth_LIST_SUCCESS';
export const YearMonth_LIST_FAIL = 'YearMonth_LIST_FAIL';

export const DepartmentName_LIST_REQUEST = 'DepartmentName_LIST_REQUEST';
export const DepartmentName_LIST_SUCCESS = 'DepartmentName_LIST_SUCCESS';
export const DepartmentName_LIST_FAIL = 'DepartmentName_LIST_FAIL';

export const Hospitalperformance_LIST_REQUEST = 'Hospitalperformance_LIST_REQUEST';
export const Hospitalperformance_LIST_SUCCESS = 'Hospitalperformance_LIST_SUCCESS';
export const Hospitalperformance_LIST_FAIL = 'Hospitalperformance_LIST_FAIL';

export const HospitalperformanceDocterDetailsList_LIST_REQUEST = 'HospitalperformanceDocterDetailsList_LIST_REQUEST';
export const HospitalperformanceDocterDetailsList_LIST_SUCCESS = 'HospitalperformanceDocterDetailsList_LIST_SUCCESS';
export const HospitalperformanceDocterDetailsList_LIST_FAIL = 'HospitalperformanceDocterDetailsList_LIST_FAIL';

export const HospitalPerformanceDaslist_LIST_REQUEST = 'HospitalPerformanceDaslist_LIST_REQUEST';
export const HospitalPerformanceDaslist_LIST_SUCCESS = 'HospitalPerformanceDaslist_LIST_SUCCESS';
export const HospitalPerformanceDaslist_LIST_FAIL = 'HospitalPerformanceDaslist_LIST_FAIL';

export const HospitalPerformanceREVlist_LIST_REQUEST = 'HospitalPerformanceREVlist_LIST_REQUEST';
export const HospitalPerformanceREVlist_LIST_SUCCESS = 'HospitalPerformanceREVlist_LIST_SUCCESS';
export const HospitalPerformanceREVlist_LIST_FAIL = 'HospitalPerformanceREVlist_LIST_FAIL';

export const HospitalPerformanceREV_divlist_LIST_REQUEST = 'HospitalPerformanceREV_divlist_LIST_REQUEST';
export const HospitalPerformanceREV_divlist_LIST_SUCCESS = 'HospitalPerformanceREV_divlist_LIST_SUCCESS';
export const HospitalPerformanceREV_divlist_LIST_FAIL = 'HospitalPerformanceREV_divlist_LIST_FAIL';

export const HospitalPerformanceSCM_divlist_LIST_REQUEST = 'HospitalPerformanceSCM_divlist_LIST_REQUEST';
export const HospitalPerformanceSCM_divlist_LIST_SUCCESS = 'HospitalPerformanceSCM_divlist_LIST_SUCCESS';
export const HospitalPerformanceSCM_divlist_LIST_FAIL = 'HospitalPerformanceSCM_divlist_LIST_FAIL';

export const HospitaldashboardSCM_LIST_REQUEST = 'HospitaldashboardSCM_LIST_REQUEST';
export const HospitaldashboardSCM_LIST_SUCCESS = 'HospitaldashboardSCM_LIST_SUCCESS';
export const HospitaldashboardSCM_LIST_FAIL = 'HospitaldashboardSCM_LIST_FAIL';

export const HospitaldashboardDetails_LIST_REQUEST = 'HospitaldashboardDetails_LIST_REQUEST';
export const HospitaldashboardDetails_LIST_SUCCESS = 'HospitaldashboardDetails_LIST_SUCCESS';
export const HospitaldashboardDetails_LIST_FAIL = 'HospitaldashboardDetails_LIST_FAIL';

export const HospitaldashboardREV_LIST_REQUEST = 'HospitaldashboardREV_LIST_REQUEST';
export const HospitaldashboardREV_LIST_SUCCESS = 'HospitaldashboardREV_LIST_SUCCESS';
export const HospitaldashboardREV_LIST_FAIL = 'HospitaldashboardREV_LIST_FAIL';

export const HospitalperformanceDetails_LIST_REQUEST = 'HospitalperformanceDetails_LIST_REQUEST';
export const HospitalperformanceDetails_LIST_SUCCESS = 'HospitalperformanceDetails_LIST_SUCCESS';
export const HospitalperformanceDetails_LIST_FAIL = 'HospitalperformanceDetails_LIST_FAIL';

export const RevenueAndTarget_SAVE_REQUEST = 'RevenueAndTarget_SAVE_REQUEST';
export const RevenueAndTarget_SAVE_SUCCESS = 'RevenueAndTarget_SAVE_SUCCESS';
export const RevenueAndTarget_SAVE_FAIL = 'RevenueAndTarget_SAVE_FAIL';

export const SurgeryGradeService_LIST_REQUEST = 'SurgeryGradeService_LIST_REQUEST';
export const SurgeryGradeService_LIST_SUCCESS = 'SurgeryGradeService_LIST_SUCCESS';
export const SurgeryGradeService_LIST_FAIL = 'SurgeryGradeService_LIST_FAIL';

export const SurgeryGradeService_SAVE_REQUEST = 'SurgeryGradeService_SAVE_REQUEST';
export const SurgeryGradeService_SAVE_SUCCESS = 'SurgeryGradeService_SAVE_SUCCESS';
export const SurgeryGradeService_SAVE_FAIL = 'SurgeryGradeService_SAVE_FAIL';

export const CURRENCY_SAVE_REQUEST = 'CURRENCY_SAVE_REQUEST';
export const CURRENCY_SAVE_SUCCESS = 'CURRENCY_SAVE_SUCCESS';
export const CURRENCY_SAVE_FAIL = 'CURRENCY_SAVE_FAIL';

export const CURRENCYEX_LIST_REQUEST = 'CURRENCYEX_LIST_REQUEST';
export const CURRENCYEX_LIST_SUCCESS = 'CURRENCYEX_LIST_SUCCESS';
export const CURRENCYEX_LIST_FAIL = 'CURRENCYEX_LIST_FAIL';

export const COMPBENE_LIST_REQUEST = 'COMPBENE_LIST_REQUEST';
export const COMPBENE_LIST_SUCCESS = 'COMPBENE_LIST_SUCCESS';
export const COMPBENE_LIST_FAIL = 'COMPBENE_LIST_FAIL';

export const COMPBENE_SAVE_REQUEST = 'COMPBENE_SAVE_REQUEST';
export const COMPBENE_SAVE_SUCCESS = 'COMPBENE_SAVE_SUCCESS';
export const COMPBENE_SAVE_FAIL = 'COMPBENE_SAVE_FAIL';

export const COMPEXLAUTO_LIST_REQUEST = 'COMPEXLAUTO_LIST_REQUEST';
export const COMPEXLAUTO_LIST_SUCCESS = 'COMPEXLAUTO_LIST_SUCCESS';
export const COMPEXLAUTO_LIST_FAIL = 'COMPEXLAUTO_LIST_FAIL';

export const COMPEXLAUTO_SAVE_REQUEST = 'COMPEXLAUTO_SAVE_REQUEST';
export const COMPEXLAUTO_SAVE_SUCCESS = 'COMPEXLAUTO_SAVE_SUCCESS';
export const COMPEXLAUTO_SAVE_FAIL = 'COMPEXLAUTO_SAVE_FAIL';

export const COMPWFAC_LIST_REQUEST = 'COMPWFAC_LIST_REQUEST';
export const COMPWFAC_LIST_SUCCESS = 'COMPWFAC_LIST_SUCCESS';
export const COMPWFAC_LIST_FAIL = 'COMPWFAC_LIST_FAIL';

export const COMPFAC_LIST_REQUEST = 'COMPFAC_LIST_REQUEST';
export const COMPFAC_LIST_SUCCESS = 'COMPFAC_LIST_SUCCESS';
export const COMPFAC_LIST_FAIL = 'COMPFAC_LIST_FAIL';

export const COMPFAC_SAVE_REQUEST = 'COMPFAC_SAVE_REQUEST';
export const COMPFAC_SAVE_SUCCESS = 'COMPFAC_SAVE_SUCCESS';
export const COMPFAC_SAVE_FAIL = 'COMPFAC_SAVE_FAIL';

export const GetPayorPageAuditExclusionItem_LIST_REQUEST = 'GetPayorPageAuditExclusionItem_LIST_REQUEST';
export const GetPayorPageAuditExclusionItem_LIST_SUCCESS = 'GetPayorPageAuditExclusionItem_LIST_SUCCESS';
export const GetPayorPageAuditExclusionItem_LIST_FAIL = 'GetPayorPageAuditExclusionItem_LIST_FAIL';

export const COMPSPO_LIST_REQUEST = 'COMPSPO_LIST_REQUEST';
export const COMPSPO_LIST_SUCCESS = 'COMPSPO_LIST_SUCCESS';
export const COMPSPO_LIST_FAIL = 'COMPSPO_LIST_FAIL';

export const COMPSPO_SAVE_REQUEST = 'COMPSPO_SAVE_REQUEST';
export const COMPSPO_SAVE_SUCCESS = 'COMPSPO_SAVE_SUCCESS';
export const COMPSPO_SAVE_FAIL = 'COMPSPO_SAVE_FAIL';

export const COMPTAR_LIST_REQUEST = 'COMPTAR_LIST_REQUEST';
export const COMPTAR_LIST_SUCCESS = 'COMPTAR_LIST_SUCCESS';
export const COMPTAR_LIST_FAIL = 'COMPTAR_LIST_FAIL';

export const COMPTAR_SAVE_REQUEST = 'COMPTAR_SAVE_REQUEST';
export const COMPTAR_SAVE_SUCCESS = 'COMPTAR_SAVE_SUCCESS';
export const COMPTAR_SAVE_FAIL = 'COMPTAR_SAVE_FAIL';

export const COMPMARK_LIST_REQUEST = 'COMPMARK_LIST_REQUEST';
export const COMPMARK_LIST_SUCCESS = 'COMPMARK_LIST_SUCCESS';
export const COMPMARK_LIST_FAIL = 'COMPMARK_LIST_FAIL';

export const COMPMARK_SAVE_REQUEST = 'COMPMARK_SAVE_REQUEST';
export const COMPMARK_SAVE_SUCCESS = 'COMPMARK_SAVE_SUCCESS';
export const COMPMARK_SAVE_FAIL = 'COMPMARK_SAVE_FAIL';

export const COMPMARKEXL_LIST_REQUEST = 'COMPMARKEXL_LIST_REQUEST';
export const COMPMARKEXL_LIST_SUCCESS = 'COMPMARKEXL_LIST_SUCCESS';
export const COMPMARKEXL_LIST_FAIL = 'COMPMARKEXL_LIST_FAIL';

export const COMPMARKEXL_SAVE_REQUEST = 'COMPMARKEXL_SAVE_REQUEST';
export const COMPMARKEXL_SAVE_SUCCESS = 'COMPMARKEXL_SAVE_SUCCESS';
export const COMPMARKEXL_SAVE_FAIL = 'COMPMARKEXL_SAVE_FAIL';

export const COMPSEREXL_LIST_REQUEST = 'COMPSEREXL_LIST_REQUEST';
export const COMPSEREXL_LIST_SUCCESS = 'COMPSEREXL_LIST_SUCCESS';
export const COMPSEREXL_LIST_FAIL = 'COMPSEREXL_LIST_FAIL';

export const COMPSEREXL_SAVE_REQUEST = 'COMPSEREXL_SAVE_REQUEST';
export const COMPSEREXL_SAVE_SUCCESS = 'COMPSEREXL_SAVE_SUCCESS';
export const COMPSEREXL_SAVE_FAIL = 'COMPSEREXL_SAVE_FAIL';

export const COMPNDISC_LIST_REQUEST = 'COMPNDISC_LIST_REQUEST';
export const COMPNDISC_LIST_SUCCESS = 'COMPNDISC_LIST_SUCCESS';
export const COMPNDISC_LIST_FAIL = 'COMPNDISC_LIST_FAIL';

export const COMPNDISC_SAVE_REQUEST = 'COMPNDISC_SAVE_REQUEST';
export const COMPNDISC_SAVE_SUCCESS = 'COMPNDISC_SAVE_SUCCESS';
export const COMPNDISC_SAVE_FAIL = 'COMPNDISC_SAVE_FAIL';

export const COMPBEDCAT_LIST_REQUEST = 'COMPBEDCAT_LIST_REQUEST';
export const COMPBEDCAT_LIST_SUCCESS = 'COMPBEDCAT_LIST_SUCCESS';
export const COMPBEDCAT_LIST_FAIL = 'COMPBEDCAT_LIST_FAIL';

export const COMPBEDCAT_SAVE_REQUEST = 'COMPBEDCAT_SAVE_REQUEST';
export const COMPBEDCAT_SAVE_SUCCESS = 'COMPBEDCAT_SAVE_SUCCESS';
export const COMPBEDCAT_SAVE_FAIL = 'COMPBEDCAT_SAVE_FAIL';

export const GetPayorPageAudit_LIST_REQUEST = 'GetPayorPageAudit_LIST_REQUEST';
export const GetPayorPageAudit_LIST_SUCCESS = 'GetPayorPageAudit_LIST_SUCCESS';
export const GetPayorPageAudit_LIST_FAIL = 'GetPayorPageAudit_LIST_FAIL';

export const GetPayorPageAuditFacilityMapping_LIST_REQUEST = 'GetPayorPageAuditFacilityMapping_LIST_REQUEST';
export const GetPayorPageAuditFacilityMapping_LIST_SUCCESS = 'GetPayorPageAuditFacilityMapping_LIST_SUCCESS';
export const GetPayorPageAuditFacilityMapping_LIST_FAIL = 'GetPayorPageAuditFacilityMapping_LIST_FAIL';

export const GetPayorPageAuditPlan_LIST_REQUEST = 'GetPayorPageAuditPlan_LIST_REQUEST';
export const GetPayorPageAuditPlan_LIST_SUCCESS = 'GetPayorPageAuditPlan_LIST_SUCCESS';
export const GetPayorPageAuditPlan_LIST_FAIL = 'GetPayorPageAuditPlan_LIST_FAIL';

export const GetPayorPageAuditTariffAndDiscount_LIST_REQUEST = 'GetPayorPageAuditTariffAndDiscount_LIST_REQUEST';
export const GetPayorPageAuditTariffAndDiscount_LIST_SUCCESS = 'GetPayorPageAuditTariffAndDiscount_LIST_SUCCESS';
export const GetPayorPageAuditTariffAndDiscount_LIST_FAIL = 'GetPayorPageAuditTariffAndDiscount_LIST_FAIL';

export const GetPayorPageAuditCompNonDiscService_LIST_REQUEST = 'GetPayorPageAuditCompNonDiscService_LIST_REQUEST';
export const GetPayorPageAuditCompNonDiscService_LIST_SUCCESS = 'GetPayorPageAuditCompNonDiscService_LIST_SUCCESS';
export const GetPayorPageAuditCompNonDiscService_LIST_FAIL = 'GetPayorPageAuditCompNonDiscService_LIST_FAIL';

export const GetPayorPageAuditCompServiceExl_LIST_REQUEST = 'GetPayorPageAuditCompServiceExl_LIST_REQUEST';
export const GetPayorPageAuditCompServiceExl_LIST_SUCCESS = 'GetPayorPageAuditCompServiceExl_LIST_SUCCESS';
export const GetPayorPageAuditCompServiceExl_LIST_FAIL = 'GetPayorPageAuditCompServiceExl_LIST_FAIL';

export const GetPayorPageAuditBedCategory_LIST_REQUEST = 'GetPayorPageAuditBedCategory_LIST_REQUEST';
export const GetPayorPageAuditBedCategory_LIST_SUCCESS = 'GetPayorPageAuditBedCategory_LIST_SUCCESS';
export const GetPayorPageAuditBedCategory_LIST_FAIL = 'GetPayorPageAuditBedCategory_LIST_FAIL';

export const GetPayorPageAuditExlAutoServiceDtl_LIST_REQUEST = 'GetPayorPageAuditExlAutoServiceDtl_LIST_REQUEST';
export const GetPayorPageAuditExlAutoServiceDtl_LIST_SUCCESS = 'GetPayorPageAuditExlAutoServiceDtl_LIST_SUCCESS';
export const GetPayorPageAuditExlAutoServiceDtl_LIST_FAIL = 'GetPayorPageAuditExlAutoServiceDtl_LIST_FAIL';

export const GetPayorPageAuditExclusionGroupMapping_LIST_REQUEST = 'GetPayorPageAuditExclusionGroupMapping_LIST_REQUEST';
export const GetPayorPageAuditExclusionGroupMapping_LIST_SUCCESS = 'GetPayorPageAuditExclusionGroupMapping_LIST_SUCCESS';
export const GetPayorPageAuditExclusionGroupMapping_LIST_FAIL = 'GetPayorPageAuditExclusionGroupMapping_LIST_FAIL';

export const GetPayorPageAuditMarkupByService_LIST_REQUEST = 'GetPayorPageAuditMarkupByService_LIST_REQUEST';
export const GetPayorPageAuditMarkupByService_LIST_SUCCESS = 'GetPayorPageAuditMarkupByService_LIST_SUCCESS';
export const GetPayorPageAuditMarkupByService_LIST_FAIL = 'GetPayorPageAuditMarkupByService_LIST_FAIL';

export const GetPayorPageAuditMarkupExclusion_LIST_REQUEST = 'GetPayorPageAuditMarkupExclusion_LIST_REQUEST';
export const GetPayorPageAuditMarkupExclusion_LIST_SUCCESS = 'GetPayorPageAuditMarkupExclusion_LIST_SUCCESS';
export const GetPayorPageAuditMarkupExclusion_LIST_FAIL = 'GetPayorPageAuditMarkupExclusion_LIST_FAIL';

export const COMP_LIST_REQUEST = 'COMP_LIST_REQUEST';
export const COMP_LIST_SUCCESS = 'COMP_LIST_SUCCESS';
export const COMP_LIST_FAIL = 'COMP_LIST_FAIL';

export const COMP_SAVE_REQUEST = 'COMP_SAVE_REQUEST';
export const COMP_SAVE_SUCCESS = 'COMP_SAVE_SUCCESS';
export const COMP_SAVE_FAIL = 'COMP_SAVE_FAIL';

export const PRNGRP_LIST_REQUEST = 'PRNGRP_LIST_REQUEST';
export const PRNGRP_LIST_SUCCESS = 'PRNGRP_LIST_SUCCESS';
export const PRNGRP_LIST_FAIL = 'PRNGRP_LIST_FAIL';

export const SERCAT_LIST_REQUEST = 'SERCAT_LIST_REQUEST';
export const SERCAT_LIST_SUCCESS = 'SERCAT_LIST_SUCCESS';
export const SERCAT_LIST_FAIL = 'SERCAT_LIST_FAIL';

export const BEDCATSER_LIST_REQUEST = 'BEDCATSER_LIST_REQUEST';
export const BEDCATSER_LIST_SUCCESS = 'BEDCATSER_LIST_SUCCESS';
export const BEDCATSER_LIST_FAIL = 'BEDCATSER_LIST_FAIL';

export const ServiceNomenclature_LIST_REQUEST = 'ServiceNomenclature_LIST_REQUEST';
export const ServiceNomenclature_LIST_SUCCESS = 'ServiceNomenclature_LIST_SUCCESS';
export const ServiceNomenclature_LIST_FAIL = 'ServiceNomenclature_LIST_FAIL';

export const ServiceNomenclature_SAVE_REQUEST = 'ServiceNomenclature_SAVE_REQUEST';
export const ServiceNomenclature_SAVE_SUCCESS = 'ServiceNomenclature_SAVE_SUCCESS';
export const ServiceNomenclature_SAVE_FAIL = 'ServiceNomenclature_SAVE_FAIL';

export const FACILITY_LIST_REQUEST = 'FACILITY_LIST_REQUEST';
export const FACILITY_LIST_SUCCESS = 'FACILITY_LIST_SUCCESS';
export const FACILITY_LIST_FAIL = 'FACILITY_LIST_FAIL';

export const FACILITYEMP_LIST_REQUEST = 'FACILITYEMP_LIST_REQUEST';
export const FACILITYEMP_LIST_SUCCESS = 'FACILITYEMP_LIST_SUCCESS';
export const FACILITYEMP_LIST_FAIL = 'FACILITYEMP_LIST_FAIL';

export const FACILITY_SAVE_REQUEST = 'FACILITY_SAVE_REQUEST';
export const FACILITY_SAVE_SUCCESS = 'FACILITY_SAVE_SUCCESS';
export const FACILITY_SAVE_FAIL = 'FACILITY_SAVE_FAIL';

export const REPORT_DATA_REQUEST = 'REPORT_DATA_REQUEST';
export const REPORT_DATA_SUCCESS = 'REPORT_DATA_SUCCESS';
export const REPORT_DATA_FAIL = 'REPORT_DATA_FAIL';

export const REPORT_DATA_Parameter_REQUEST = 'REPORT_DATA_Parameter_REQUEST';
export const REPORT_DATA_Parameter_SUCCESS = 'REPORT_DATA_Parameter_SUCCESS';
export const REPORT_DATA_Parameter_FAIL = 'REPORT_DATA_Parameter_FAIL';

export const EMP_LIST_REQUEST = 'EMP_LIST_REQUEST';
export const EMP_LIST_SUCCESS = 'EMP_LIST_SUCCESS';
export const EMP_LIST_FAIL = 'EMP_LIST_FAIL';

export const DOC_LIST_REQUEST = 'DOC_LIST_REQUEST';
export const DOC_LIST_SUCCESS = 'DOC_LIST_SUCCESS';
export const DOC_LIST_FAIL = 'DOC_LIST_FAIL';

export const EMPBYSPECIALITY_LIST_REQUEST = 'EMPBYSPECIALITY_LIST_REQUEST';
export const EMPBYSPECIALITY_LIST_SUCCESS = 'EMPBYSPECIALITY_LIST_SUCCESS';
export const EMPBYSPECIALITY_LIST_FAIL = 'EMPBYSPECIALITY_LIST_FAIL';

export const EMP_COPY_LIST_REQUEST = 'EMP_COPY_LIST_REQUEST';
export const EMP_COPY_LIST_SUCCESS = 'EMP_COPY_LIST_SUCCESS';
export const EMP_COPY_LIST_FAIL = 'EMP_COPY_LIST_FAIL';

export const EMP_SAVE_REQUEST = 'EMP_SAVE_REQUEST';
export const EMP_SAVE_SUCCESS = 'EMP_SAVE_SUCCESS';
export const EMP_SAVE_FAIL = 'EMP_SAVE_FAIL';

export const REPFOR_LIST_REQUEST = 'REPFOR_LIST_REQUEST';
export const REPFOR_LIST_SUCCESS = 'REPFOR_LIST_SUCCESS';
export const REPFOR_LIST_FAIL = 'REPFOR_LIST_FAIL';

export const REPFOROP_LIST_REQUEST = 'REPFOROP_LIST_REQUEST';
export const REPFOROP_LIST_SUCCESS = 'REPFOROP_LIST_SUCCESS';
export const REPFOROP_LIST_FAIL = 'REPFOROP_LIST_FAIL';

export const REPFORER_LIST_REQUEST = 'REPFORER_LIST_REQUEST';
export const REPFORER_LIST_SUCCESS = 'REPFORER_LIST_SUCCESS';
export const REPFORER_LIST_FAIL = 'REPFORER_LIST_FAIL';

export const REPFORIP_LIST_REQUEST = 'REPFORIP_LIST_REQUEST';
export const REPFORIP_LIST_SUCCESS = 'REPFORIP_LIST_SUCCESS';
export const REPFORIP_LIST_FAIL = 'REPFORIP_LIST_FAIL';

export const REPFOR_SAVE_REQUEST = 'REPFOR_SAVE_REQUEST';
export const REPFOR_SAVE_SUCCESS = 'REPFOR_SAVE_SUCCESS';
export const REPFOR_SAVE_FAIL = 'REPFOR_SAVE_FAIL';

export const TARIFF_SAVE_REQUEST = 'TARIFF_SAVE_REQUEST';
export const TARIFF_SAVE_SUCCESS = 'TARIFF_SAVE_SUCCESS';
export const TARIFF_SAVE_FAIL = 'TARIFF_SAVE_FAIL';

export const PAYORPLAN_LIST_REQUEST = 'PAYORPLAN_LIST_REQUEST';
export const PAYORPLAN_LIST_SUCCESS = 'PAYORPLAN_LIST_SUCCESS';
export const PAYORPLAN_LIST_FAIL = 'PAYORPLAN_LIST_FAIL';

export const PAYORPLAN_SAVE_REQUEST = 'PAYORPLAN_SAVE_REQUEST';
export const PAYORPLAN_SAVE_SUCCESS = 'PAYORPLAN_SAVE_SUCCESS';
export const PAYORPLAN_SAVE_FAIL = 'PAYORPLAN_SAVE_FAIL';

export const TARIFFLOGIN_LIST_REQUEST = 'TARIFF_LIST_REQUEST';
export const TARIFFLOGIN_LIST_SUCCESS = 'TARIFF_LIST_SUCCESS';
export const TARIFFLOGIN_LIST_FAIL = 'TARIFF_LIST_FAIL';

export const TARIFFRULE_LIST_REQUEST = 'TARIFFRULE_LIST_REQUEST';
export const TARIFFRULE_LIST_SUCCESS = 'TARIFFRULE_LIST_SUCCESS';
export const TARIFFRULE_LIST_FAIL = 'TARIFFRULE_LIST_FAIL';

export const TARIFF_LIST_REQUEST = 'TARIFF_LIST_REQUEST';
export const TARIFF_LIST_SUCCESS = 'TARIFF_LIST_SUCCESS';
export const TARIFF_LIST_FAIL = 'TARIFF_LIST_FAIL';

export const DISCTEM_LIST_REQUEST = 'DISCTEM_LIST_REQUEST';
export const DISCTEM_LIST_SUCCESS = 'DISCTEM_LIST_SUCCESS';
export const DISCTEM_LIST_FAIL = 'DISCTEM_LIST_FAIL';

export const DISCTEM_SAVE_REQUEST = 'DISCTEM_SAVE_REQUEST';
export const DISCTEM_SAVE_SUCCESS = 'DISCTEM_SAVE_SUCCESS';
export const DISCTEM_SAVE_FAIL = 'DISCTEM_SAVE_FAIL';

export const COUPTEM_LIST_REQUEST = 'COUPTEM_LIST_REQUEST';
export const COUPTEM_LIST_FAIL = 'COUPTEM_LIST_FAIL';
export const COUPTEM_LIST_SUCCESS = 'COUPTEM_LIST_SUCCESS';

export const DEPTMST_LIST_REQUEST = 'DEPTMST_LIST_REQUEST';
export const DEPTMST_LIST_SUCCESS = 'DEPTMST_LIST_SUCCESS';
export const DEPTMST_LIST_FAIL = 'DEPTMST_LIST_FAIL';

export const DEPTMSTBYSERVICETYPE_LIST_REQUEST = 'DEPTMSTBYSERVICETYPE_LIST_REQUEST';
export const DEPTMSTBYSERVICETYPE_LIST_SUCCESS = 'DEPTMSTBYSERVICETYPE_LIST_SUCCESS';
export const DEPTMSTBYSERVICETYPE_LIST_FAIL = 'DEPTMSTBYSERVICETYPE_LIST_FAIL';

export const DEPTMST_SAVE_REQUEST = 'DEPTMST_SAVE_REQUEST';
export const DEPTMST_SAVE_SUCCESS = 'DEPTMST_SAVE_SUCCESS';
export const DEPTMST_SAVE_FAIL = 'DEPTMST_SAVE_FAIL';

export const DEPTSUBMST_LIST_REQUEST = 'DEPTSUBMST_LIST_REQUEST';
export const DEPTSUBMST_LIST_SUCCESS = 'DEPTSUBMST_LIST_SUCCESS';
export const DEPTSUBMST_LIST_FAIL = 'DEPTSUBMST_LIST_FAIL';

export const DEPTSUBMSTBYTYPE_LIST_REQUEST = 'DEPTSUBMSTBYTYPE_LIST_REQUEST';
export const DEPTSUBMSTBYTYPE_LIST_SUCCESS = 'DEPTSUBMSTBYTYPE_LIST_SUCCESS';
export const DEPTSUBMSTBYTYPE_LIST_FAIL = 'DEPTSUBMSTBYTYPE_LIST_FAIL';

export const DEPTSUBMST_SAVE_REQUEST = 'DEPTSUBMST_SAVE_REQUEST';
export const DEPTSUBMST_SAVE_SUCCESS = 'DEPTSUBMST_SAVE_SUCCESS';
export const DEPTSUBMST_SAVE_FAIL = 'DEPTSUBMST_SAVE_FAIL';

export const SERVMST_LIST_REQUEST = 'SERVMST_LIST_REQUEST';
export const SERVMST_LIST_SUCCESS = 'SERVMST_LIST_SUCCESS';
export const SERVMST_LIST_FAIL = 'SERVMST_LIST_FAIL';

export const SERVMST_SAVE_REQUEST = 'SERVMST_SAVE_REQUEST';
export const SERVMST_SAVE_SUCCESS = 'SERVMST_SAVE_SUCCESS';
export const SERVMST_SAVE_FAIL = 'SERVMST_SAVE_FAIL';

export const CUPNDTL_SAVE_REQUEST = 'CUPNDTL_SAVE_REQUEST';
export const CUPNDTL_SAVE_SUCCESS = 'CUPNDTL_SAVE_SUCCESS';
export const CUPNDTL_SAVE_FAIL = 'CUPNDTL_SAVE_FAIL';

export const SERBYFAC_LIST_REQUEST = 'SERBYFAC_LIST_REQUEST';
export const SERBYFAC_LIST_SUCCESS = 'SERBYFAC_LIST_SUCCESS';
export const SERBYFAC_LIST_FAIL = 'SERBYFAC_LIST_FAIL';

export const PACBYFAC_LIST_REQUEST = 'PACBYFAC_LIST_REQUEST';
export const PACBYFAC_LIST_SUCCESS = 'PACBYFAC_LIST_SUCCESS';
export const PACBYFAC_LIST_FAIL = 'PACBYFAC_LIST_FAIL';

export const SUBSERBYFAC_LIST_REQUEST = 'SUBSERBYFAC_LIST_REQUEST';
export const SUBSERBYFAC_LIST_SUCCESS = 'SUBSERBYFAC_LIST_SUCCESS';
export const SUBSERBYFAC_LIST_FAIL = 'SUBSERBYFAC_LIST_FAIL';

export const SUBSERBYFACTNI_LIST_REQUEST = 'SUBSERBYFACTNI_LIST_REQUEST';
export const SUBSERBYFACTNI_LIST_SUCCESS = 'SUBSERBYFACTNI_LIST_SUCCESS';
export const SUBSERBYFACTNI_LIST_FAIL = 'SUBSERBYFACTNI_LIST_FAIL';

export const ServiceByServiceCategory_LIST_REQUEST = 'ServiceByServiceCategory_LIST_REQUEST';
export const ServiceByServiceCategory_LIST_SUCCESS = 'ServiceByServiceCategory_LIST_SUCCESS';
export const ServiceByServiceCategory_LIST_FAIL = 'ServiceByServiceCategory_LIST_FAIL';

export const SERBYFAC_SAVE_REQUEST = 'SERBYFAC_SAVE_REQUEST';
export const SERBYFAC_SAVE_SUCCESS = 'SERBYFAC_SAVE_SUCCESS';
export const SERBYFAC_SAVE_FAIL = 'SERBYFAC_SAVE_FAIL';

export const SERFAC_LIST_REQUEST = 'SERFAC_LIST_REQUEST';
export const SERFAC_LIST_SUCCESS = 'SERFAC_LIST_SUCCESS';
export const SERFAC_LIST_FAIL = 'SERFAC_LIST_FAIL';

export const SERFAC_SAVE_REQUEST = 'SERFAC_SAVE_REQUEST';
export const SERFAC_SAVE_SUCCESS = 'SERFAC_SAVE_SUCCESS';
export const SERFAC_SAVE_FAIL = 'SERFAC_SAVE_FAIL';

export const SERVCAT_LIST_REQUEST = 'SERVCAT_LIST_REQUEST';
export const SERVCAT_LIST_SUCCESS = 'SERVCAT_LIST_SUCCESS';
export const SERVCAT_LIST_FAIL = 'SERVCAT_LIST_FAIL';

export const SERVCAT_SAVE_REQUEST = 'SERVCAT_SAVE_REQUEST';
export const SERVCAT_SAVE_SUCCESS = 'SERVCAT_SAVE_SUCCESS';
export const SERVCAT_SAVE_FAIL = 'SERVCAT_SAVE_FAIL';

export const SERLINK_LIST_REQUEST = 'SERLINK_LIST_REQUEST';
export const SERLINK_LIST_SUCCESS = 'SERLINK_LIST_SUCCESS';
export const SERLINK_LIST_FAIL = 'SERLINK_LIST_FAIL';

export const SERLINK_SAVE_REQUEST = 'SERLINK_SAVE_REQUEST';
export const SERLINK_SAVE_SUCCESS = 'SERLINK_SAVE_SUCCESS';
export const SERLINK_SAVE_FAIL = 'SERLINK_SAVE_FAIL';

export const SERCOD_LIST_REQUEST = 'SERCOD_LIST_REQUEST';
export const SERCOD_LIST_SUCCESS = 'SERCOD_LIST_SUCCESS';
export const SERCOD_LIST_FAIL = 'SERCOD_LIST_FAIL';

export const SERCOD_SAVE_REQUEST = 'SERCOD_SAVE_REQUEST';
export const SERCOD_SAVE_SUCCESS = 'SERCOD_SAVE_SUCCESS';
export const SERCOD_SAVE_FAIL = 'SERCOD_SAVE_FAIL';

export const SERTAR_LIST_REQUEST = 'SERTAR_LIST_REQUEST';
export const SERTAR_LIST_SUCCESS = 'SERTAR_LIST_SUCCESS';
export const SERTAR_LIST_FAIL = 'SERTAR_LIST_FAIL';

export const SERTAR_SAVE_REQUEST = 'SERTAR_SAVE_REQUEST';
export const SERTAR_SAVE_SUCCESS = 'SERTAR_SAVE_SUCCESS';
export const SERTAR_SAVE_FAIL = 'SERTAR_SAVE_FAIL';

export const CONSTAR_LIST_REQUEST = 'CONSTAR_LIST_REQUEST';
export const CONSTAR_LIST_SUCCESS = 'CONSTAR_LIST_SUCCESS';
export const CONSTAR_LIST_FAIL = 'CONSTAR_LIST_FAIL';

export const CONSTAR_SAVE_REQUEST = 'CONSTAR_SAVE_REQUEST';
export const CONSTAR_SAVE_SUCCESS = 'CONSTAR_SAVE_SUCCESS';
export const CONSTAR_SAVE_FAIL = 'CONSTAR_SAVE_FAIL';

export const SERTARDTL_LIST_REQUEST = 'SERTARDTL_LIST_REQUEST';
export const SERTARDTL_LIST_SUCCESS = 'SERTARDTL_LIST_SUCCESS';
export const SERTARDTL_LIST_FAIL = 'SERTARDTL_LIST_FAIL';

export const SERTARDTL_SAVE_REQUEST = 'SERTARDTL_SAVE_REQUEST';
export const SERTARDTL_SAVE_SUCCESS = 'SERTARDTL_SAVE_SUCCESS';
export const SERTARDTL_SAVE_FAIL = 'SERTARDTL_SAVE_FAIL';

export const SPECIALITY_LIST_REQUEST = 'SPECIALITY_LIST_REQUEST';
export const SPECIALITY_LIST_SUCCESS = 'SPECIALITY_LIST_SUCCESS';
export const SPECIALITY_LIST_FAIL = 'SPECIALITY_LIST_FAIL';

export const SPECIALITY_SAVE_REQUEST = 'SPECIALITY_SAVE_REQUEST';
export const SPECIALITY_SAVE_SUCCESS = 'SPECIALITY_SAVE_SUCCESS';
export const SPECIALITY_SAVE_FAIL = 'SPECIALITY_SAVE_FAIL';

export const STREET_LIST_REQUEST = 'STREET_LIST_REQUEST';
export const STREET_LIST_SUCCESS = 'STREET_LIST_SUCCESS';
export const STREET_LIST_FAIL = 'STREET_LIST_FAIL';

export const PAYOR_LIST_REQUEST = 'PAYOR_LIST_REQUEST';
export const PAYOR_LIST_SUCCESS = 'PAYOR_LIST_SUCCESS';
export const PAYOR_LIST_FAIL = 'PAYOR_LIST_FAIL';

export const SPONSOR_LIST_REQUEST = 'SPONSOR_LIST_REQUEST';
export const SPONSOR_LIST_SUCCESS = 'SPONSOR_LIST_SUCCESS';
export const SPONSOR_LIST_FAIL = 'SPONSOR_LIST_FAIL';

export const EMPLOYER_LIST_REQUEST = 'EMPLOYER_LIST_REQUEST';
export const EMPLOYER_LIST_SUCCESS = 'EMPLOYER_LIST_SUCCESS';
export const EMPLOYER_LIST_FAIL = 'EMPLOYER_LIST_FAIL';

export const AGETYPE_LIST_REQUEST = 'AGETYPE_LIST_REQUEST';
export const AGETYPE_LIST_SUCCESS = 'AGETYPE_LIST_SUCCESS';
export const AGETYPE_LIST_FAIL = 'AGETYPE_LIST_FAIL';

export const STATUSBYTYPE_LIST_REQUEST = 'STATUSBYTYPE_LIST_REQUEST';
export const STATUSBYTYPE_LIST_SUCCESS = 'STATUSBYTYPE_LIST_SUCCESS';
export const STATUSBYTYPE_LIST_FAIL = 'STATUSBYTYPE_LIST_FAIL';

export const STATUSBYTYPE_LIST_REQUEST_Global = 'STATUSBYTYPE_LIST_REQUEST_Global';
export const STATUSBYTYPE_LIST_SUCCESS_Global = 'STATUSBYTYPE_LIST_SUCCESS_Global';
export const STATUSBYTYPE_LIST_FAIL_Global = 'STATUSBYTYPE_LIST_FAIL_Global';

export const ExclusionMain_LIST_REQUEST = 'ExclusionMain_LIST_REQUEST';
export const ExclusionMain_LIST_SUCCESS = 'ExclusionMain_LIST_SUCCESS';
export const ExclusionMain_LIST_FAIL = 'ExclusionMain_LIST_FAIL';

export const ExclusionMain_SAVE_REQUEST = 'ExclusionMain_SAVE_REQUEST';
export const ExclusionMain_SAVE_SUCCESS = 'ExclusionMain_SAVE_SUCCESS';
export const ExclusionMain_SAVE_FAIL = 'ExclusionMain_SAVE_FAIL';

export const ExclusionDetail_LIST_REQUEST = 'ExclusionDetail_LIST_REQUEST';
export const ExclusionDetail_LIST_SUCCESS = 'ExclusionDetail_LIST_SUCCESS';
export const ExclusionDetail_LIST_FAIL = 'ExclusionDetail_LIST_FAIL';

export const ExclusionDetail_SAVE_REQUEST = 'ExclusionDetail_SAVE_REQUEST';
export const ExclusionDetail_SAVE_SUCCESS = 'ExclusionDetail_SAVE_SUCCESS';
export const ExclusionDetail_SAVE_FAIL = 'ExclusionDetail_SAVE_FAIL';

export const UserGroup_LIST_REQUEST = 'UserGroup_LIST_REQUEST';
export const UserGroup_LIST_SUCCESS = 'UserGroup_LIST_SUCCESS';
export const UserGroup_LIST_FAIL = 'UserGroup_LIST_FAIL';

export const UserGroup_SAVE_REQUEST = 'UserGroup_SAVE_REQUEST';
export const UserGroup_SAVE_SUCCESS = 'UserGroup_SAVE_SUCCESS';
export const UserGroup_SAVE_FAIL = 'UserGroup_SAVE_FAIL';

export const Designation_LIST_REQUEST = 'Designation_LIST_REQUEST';
export const Designation_LIST_SUCCESS = 'Designation_LIST_SUCCESS';
export const Designation_LIST_FAIL = 'Designation_LIST_FAIL';

export const Designation_SAVE_REQUEST = 'Designation_SAVE_REQUEST';
export const Designation_SAVE_SUCCESS = 'Designation_SAVE_SUCCESS';
export const Designation_SAVE_FAIL = 'Designation_SAVE_FAIL';

export const Grade_LIST_REQUEST = 'Grade_LIST_REQUEST';
export const Grade_LIST_SUCCESS = 'Grade_LIST_SUCCESS';
export const Grade_LIST_FAIL = 'Grade_LIST_FAIL';

export const Grade_SAVE_REQUEST = 'Grade_SAVE_REQUEST';
export const Grade_SAVE_SUCCESS = 'Grade_SAVE_SUCCESS';
export const Grade_SAVE_FAIL = 'Grade_SAVE_FAIL';

export const UserSubGroup_LIST_REQUEST = 'UserSubGroup_LIST_REQUEST';
export const UserSubGroup_LIST_SUCCESS = 'UserSubGroup_LIST_SUCCESS';
export const UserSubGroup_LIST_FAIL = 'UserSubGroup_LIST_FAIL';

export const UserSubGroup_SAVE_REQUEST = 'UserSubGroup_SAVE_REQUEST';
export const UserSubGroup_SAVE_SUCCESS = 'UserSubGroup_SAVE_SUCCESS';
export const UserSubGroup_SAVE_FAIL = 'UserSubGroup_SAVE_FAIL';

export const OPPackage_LIST_REQUEST = 'OPPackage_LIST_REQUEST';
export const OPPackage_LIST_SUCCESS = 'OPPackage_LIST_SUCCESS';
export const OPPackage_LIST_FAIL = 'OPPackage_LIST_FAIL';

export const OPPackagePayor_LIST_REQUEST = 'OPPackagePayor_LIST_REQUEST';
export const OPPackagePayor_LIST_SUCCESS = 'OPPackagePayor_LIST_SUCCESS';
export const OPPackagePayor_LIST_FAIL = 'OPPackagePayor_LIST_FAIL';

export const OPPackage_SAVE_REQUEST = 'OPPackage_SAVE_REQUEST';
export const OPPackage_SAVE_SUCCESS = 'OPPackage_SAVE_SUCCESS';
export const OPPackage_SAVE_FAIL = 'OPPackage_SAVE_FAIL';

export const OPPackageRender_LIST_REQUEST = 'OPPackageRender_LIST_REQUEST';
export const OPPackageRender_LIST_SUCCESS = 'OPPackageRender_LIST_SUCCESS';
export const OPPackageRender_LIST_FAIL = 'OPPackageRender_LIST_FAIL';

export const BEDCATFAC_LIST_REQUEST = 'BEDCATFAC_LIST_REQUEST';
export const BEDCATFAC_LIST_SUCCESS = 'BEDCATFAC_LIST_SUCCESS';
export const BEDCATFAC_LIST_FAIL = 'BEDCATFAC_LIST_FAIL';

export const BEDCATFAC_SAVE_REQUEST = 'BEDCATFAC_SAVE_REQUEST';
export const BEDCATFAC_SAVE_SUCCESS = 'BEDCATFAC_SAVE_SUCCESS';
export const BEDCATFAC_SAVE_FAIL = 'BEDCATFAC_SAVE_FAIL';

export const BEDCATFACDTL_LIST_REQUEST = 'BEDCATFACDTL_LIST_REQUEST';
export const BEDCATFACDTL_LIST_SUCCESS = 'BEDCATFACDTL_LIST_SUCCESS';
export const BEDCATFACDTL_LIST_FAIL = 'BEDCATFACDTL_LIST_FAIL';

export const BEDCATFACDTL_SAVE_REQUEST = 'BEDCATFACDTL_SAVE_REQUEST';
export const BEDCATFACDTL_SAVE_SUCCESS = 'BEDCATFACDTL_SAVE_SUCCESS';
export const BEDCATFACDTL_SAVE_FAIL = 'BEDCATFACDTL_SAVE_FAIL';

// ujjwal work start
export const BedType_LIST_REQUEST = 'BedType_LIST_REQUEST';
export const BedType_LIST_SUCCESS = 'BedType_LIST_SUCCESS';
export const BedType_LIST_FAIL = 'BedType_LIST_FAIL';

export const BedType_SAVE_REQUEST = 'BedType_SAVE_REQUEST';
export const BedType_SAVE_SUCCESS = 'BedType_SAVE_SUCCESS';
export const BedType_SAVE_FAIL = 'BedType_SAVE_FAIL';

export const Module_LIST_REQUEST = 'Module_LIST_REQUEST';
export const Module_LIST_SUCCESS = 'Module_LIST_SUCCESS';
export const Module_LIST_FAIL = 'Module_LIST_FAIL';

export const Form_LIST_REQUEST = 'Form_LIST_REQUEST';
export const Form_LIST_SUCCESS = 'Form_LIST_SUCCESS';
export const Form_LIST_FAIL = 'Form_LIST_FAIL';

export const RolePage_LIST_REQUEST = 'RolePage_LIST_REQUEST';
export const RolePage_LIST_SUCCESS = 'RolePage_LIST_SUCCESS';
export const RolePage_LIST_FAIL = 'RolePage_LIST_FAIL';

export const RolePermissionMaster_LIST_REQUEST = 'RolePermissionMaster_LIST_REQUEST';
export const RolePermissionMaster_LIST_SUCCESS = 'RolePermissionMaster_LIST_SUCCESS';
export const RolePermissionMaster_LIST_FAIL = 'RolePermissionMaster_LIST_FAIL';

export const AccessControlRoleReport_LIST_REQUEST = 'AccessControlRoleReport_LIST_REQUEST';
export const AccessControlRoleReport_LIST_SUCCESS = 'AccessControlRoleReport_LIST_SUCCESS';
export const AccessControlRoleReport_LIST_FAIL = 'AccessControlRoleReport_LIST_FAIL';

export const RolePageSection_LIST_REQUEST = 'RolePageSection_LIST_REQUEST';
export const RolePageSection_LIST_SUCCESS = 'RolePageSection_LIST_SUCCESS';
export const RolePageSection_LIST_FAIL = 'RolePageSection_LIST_FAIL';

export const EmployeePage_LIST_REQUEST = 'EmployeePage_LIST_REQUEST';
export const EmployeePage_LIST_SUCCESS = 'EmployeePage_LIST_SUCCESS';
export const EmployeePage_LIST_FAIL = 'EmployeePage_LIST_FAIL';

export const EmployeePageSection_LIST_REQUEST = 'EmployeePageSection_LIST_REQUEST';
export const EmployeePageSection_LIST_SUCCESS = 'EmployeePageSection_LIST_SUCCESS';
export const EmployeePageSection_LIST_FAIL = 'EmployeePageSection_LIST_FAIL';

export const Section_LIST_REQUEST = 'Section_LIST_REQUEST';
export const Section_LIST_SUCCESS = 'Section_LIST_SUCCESS';
export const Section_LIST_FAIL = 'Section_LIST_FAIL';

export const BEDCAT_LIST_REQUEST = 'BEDCAT_LIST_REQUEST';
export const BEDCAT_LIST_SUCCESS = 'BEDCAT_LIST_SUCCESS';
export const BEDCAT_LIST_FAIL = 'BEDCAT_LIST_FAIL';

export const ERBEDCAT_LIST_REQUEST = 'ERBEDCAT_LIST_REQUEST';
export const ERBEDCAT_LIST_SUCCESS = 'ERBEDCAT_LIST_SUCCESS';
export const ERBEDCAT_LIST_FAIL = 'ERBEDCAT_LIST_FAIL';
// ujjwal work end
//rajesh work start
export const AdmissionType_LIST_REQUEST = 'AdmissionType_LIST_REQUEST';
export const AdmissionType_LIST_SUCCESS = 'AdmissionType_LIST_SUCCESS';
export const AdmissionType_LIST_FAIL = 'AdmissionType_LIST_FAIL';
export const AgeGroup_LIST_REQUEST = 'AgeGroup_LIST_REQUEST';
export const AgeGroup_LIST_SUCCESS = 'AgeGroup_LIST_SUCCESS';
export const AgeGroup_LIST_FAIL = 'AgeGroup_LIST_FAIL';

export const AnesthesiaType_LIST_REQUEST = 'AnesthesiaType_LIST_REQUEST';
export const AnesthesiaType_LIST_SUCCESS = 'AnesthesiaType_LIST_SUCCESS';
export const AnesthesiaType_LIST_FAIL = 'AnesthesiaType_LIST_FAIL';

export const BedMaster_LIST_REQUEST = 'BedMaster_LIST_REQUEST';
export const BedMaster_LIST_SUCCESS = 'BedMaster_LIST_SUCCESS';
export const BedMaster_LIST_FAIL = 'BedMaster_LIST_FAIL';
export const Block_LIST_REQUEST = 'Block_LIST_REQUEST';
export const Block_LIST_SUCCESS = 'Block_LIST_SUCCESS';
export const Block_LIST_FAIL = 'Block_LIST_FAIL';
export const Building_LIST_REQUEST = 'Building_LIST_REQUEST';
export const Building_LIST_SUCCESS = 'Building_LIST_SUCCESS';
export const Building_LIST_FAIL = 'Building_LIST_FAIL';

export const CaseType_LIST_REQUEST = 'CaseType_LIST_REQUEST';
export const CaseType_LIST_SUCCESS = 'CaseType_LIST_SUCCESS';
export const CaseType_LIST_FAIL = 'CaseType_LIST_FAIL';

export const CaseSubType_LIST_REQUEST = 'CaseSubType_LIST_REQUEST';
export const CaseSubType_LIST_SUCCESS = 'CaseSubType_LIST_SUCCESS';
export const CaseSubType_LIST_FAIL = 'CaseSubType_LIST_FAIL';

export const ItemSapIntegration_LIST_REQUEST = 'ItemSapIntegration_LIST_REQUEST';
export const ItemSapIntegration_LIST_SUCCESS = 'ItemSapIntegration_LIST_SUCCESS';
export const ItemSapIntegration_LIST_FAIL = 'ItemSapIntegration_LIST_FAIL';

export const SAPItemPurchageGroupMaster_LIST_REQUEST = 'SAPItemPurchageGroupMaster_LIST_REQUEST';
export const SAPItemPurchageGroupMaster_LIST_SUCCESS = 'SAPItemPurchageGroupMaster_LIST_SUCCESS';
export const SAPItemPurchageGroupMaster_LIST_FAIL = 'SAPItemPurchageGroupMaster_LIST_FAIL';

export const Floor_LIST_REQUEST = 'Floor_LIST_REQUEST';
export const Floor_LIST_SUCCESS = 'Floor_LIST_SUCCESS';
export const Floor_LIST_FAIL = 'Floor_LIST_FAIL';

export const RoomMaster_LIST_REQUEST = 'RoomMaster_LIST_REQUEST';
export const RoomMaster_LIST_SUCCESS = 'RoomMaster_LIST_SUCCESS';
export const RoomMaster_LIST_FAIL = 'RoomMaster_LIST_FAIL';

export const AdmissionType_SAVE_REQUEST = 'AdmissionType_SAVE_REQUEST';
export const AdmissionType_SAVE_SUCCESS = 'AdmissionType_SAVE_SUCCESS';
export const AdmissionType_SAVE_FAIL = 'AdmissionType_SAVE_FAIL';
export const AgeGroup_SAVE_REQUEST = 'AgeGroup_SAVE_REQUEST';
export const AgeGroup_SAVE_SUCCESS = 'AgeGroup_SAVE_SUCCESS';
export const AgeGroup_SAVE_FAIL = 'AgeGroup_SAVE_FAIL';

export const AnesthesiaType_SAVE_REQUEST = 'AnesthesiaType_SAVE_REQUEST';
export const AnesthesiaType_SAVE_SUCCESS = 'AnesthesiaType_SAVE_SUCCESS';
export const AnesthesiaType_SAVE_FAIL = 'AnesthesiaType_SAVE_FAIL';

export const BedMaster_SAVE_REQUEST = 'BedMaster_SAVE_REQUEST';
export const BedMaster_SAVE_SUCCESS = 'BedMaster_SAVE_SUCCESS';
export const BedMaster_SAVE_FAIL = 'BedMaster_SAVE_FAIL';
export const Block_SAVE_REQUEST = 'Block_SAVE_REQUEST';
export const Block_SAVE_SUCCESS = 'Block_SAVE_SUCCESS';
export const Block_SAVE_FAIL = 'Block_SAVE_FAIL';
export const CaseType_SAVE_REQUEST = 'CaseType_SAVE_REQUEST';
export const CaseType_SAVE_SUCCESS = 'CaseType_SAVE_SUCCESS';
export const CaseType_SAVE_FAIL = 'CaseType_SAVE_FAIL';
export const Floor_SAVE_REQUEST = 'Floor_SAVE_REQUEST';
export const Floor_SAVE_SUCCESS = 'Floor_SAVE_SUCCESS';
export const Floor_SAVE_FAIL = 'Floor_SAVE_FAIL';
export const NursingUnit_SAVE_REQUEST = 'NursingUnit_SAVE_REQUEST';
export const NursingUnit_SAVE_SUCCESS = 'NursingUnit_SAVE_SUCCESS';
export const NursingUnit_SAVE_FAIL = 'NursingUnit_SAVE_FAIL';
export const RoomMaster_SAVE_REQUEST = 'RoomMaster_SAVE_REQUEST';
export const RoomMaster_SAVE_SUCCESS = 'RoomMaster_SAVE_SUCCESS';
export const RoomMaster_SAVE_FAIL = 'RoomMaster_SAVE_FAIL';

export const DISCTEMCON_LIST_REQUEST = 'DISCTEMCON_LIST_REQUEST';
export const DISCTEMCON_LIST_SUCCESS = 'DISCTEMCON_LIST_SUCCESS';
export const DISCTEMCON_LIST_FAIL = 'DISCTEMCON_LIST_FAIL';

export const DISCTEMCON_SAVE_REQUEST = 'DISCTEMCON_SAVE_REQUEST';
export const DISCTEMCON_SAVE_SUCCESS = 'DISCTEMCON_SAVE_SUCCESS';
export const DISCTEMCON_SAVE_FAIL = 'DISCTEMCON_SAVE_FAIL';

//Billing start

export const PACKAGERENDERSAVE_LIST_REQUEST = 'PACKAGERENDERSAVE_LIST_REQUEST';
export const PACKAGERENDERSAVE_LIST_SUCCESS = 'PACKAGERENDERSAVE_LIST_SUCCESS';
export const PACKAGERENDERSAVE_LIST_FAIL = 'PACKAGERENDERSAVE_LIST_FAIL';

export const PACKAGEMULREG_LIST_REQUEST = 'PACKAGEMULREG_LIST_REQUEST';
export const PACKAGEMULREG_LIST_SUCCESS = 'PACKAGEMULREG_LIST_SUCCESS';
export const PACKAGEMULREG_LIST_FAIL = 'PACKAGEMULREG_LIST_FAIL';

export const PACKAGEMULREGDET_LIST_REQUEST = 'PACKAGEMULREGDET_LIST_REQUEST';
export const PACKAGEMULREGDET_LIST_SUCCESS = 'PACKAGEMULREGDET_LIST_SUCCESS';
export const PACKAGEMULREGDET_LIST_FAIL = 'PACKAGEMULREGDET_LIST_FAIL';

export const RECEIPT_LIST_REQUEST = 'RECEIPT_LIST_REQUEST';
export const RECEIPT_LIST_SUCCESS = 'RECEIPT_LIST_SUCCESS';
export const RECEIPT_LIST_FAIL = 'RECEIPT_LIST_FAIL';

export const receiptpayReSync_REQ_LIST_REQUEST = "receiptpayReSync_REQ_LIST_REQUEST";
export const receiptpayReSync_REQ_LIST_SUCCESS = "receiptpayReSync_REQ_LIST_SUCCESS";
export const receiptpayReSync_REQ_LIST_FAIL = "receiptpayReSync_REQ_LIST_FAIL";

export const get_gstIPBill_LIST_REQUEST = 'get_gstIPBill_LIST_REQUEST';
export const get_gstIPBill_LIST_SUCCESS = 'get_gstIPBill_LIST_SUCCESS';
export const get_gstIPBill_LIST_FAIL = 'get_gstIPBill_LIST_FAIL';

export const REFUNDGETDETAILS_REQUEST = 'REFUNDGETDETAILS_REQUEST';
export const REFUNDGETDETAILS_SUCCESS = 'REFUNDGETDETAILS_SUCCESS';
export const REFUNDGETDETAILS_FAIL = 'REFUNDGETDETAILS_FAIL';

export const RECEIPTGETDETAILS_REQUEST = 'RECEIPTGETDETAILS_REQUEST';
export const RECEIPTGETDETAILS_SUCCESS = 'RECEIPTGETDETAILS_SUCCESS';
export const RECEIPTGETDETAILS_FAIL = 'RECEIPTGETDETAILS_FAIL';

export const RECEIPT_SAVE_REQUEST = 'RECEIPT_SAVE_REQUEST';
export const RECEIPT_SAVE_SUCCESS = 'RECEIPT_SAVE_SUCCESS';
export const RECEIPT_SAVE_FAIL = 'RECEIPT_SAVE_FAIL';

export const Encounter_LIST_REQUEST = 'Encounter_LIST_REQUEST';
export const Encounter_LIST_SUCCESS = 'Encounter_LIST_SUCCESS';
export const Encounter_LIST_FAIL = 'Encounter_LIST_FAIL';

export const Encounter_LIST_Bill_REQUEST = 'Encounter_LIST_Bill_REQUEST';
export const Encounter_LIST_Bill_SUCCESS = 'Encounter_LIST_Bill_SUCCESS';
export const Encounter_LIST_Bill_FAIL = 'Encounter_LIST_Bill_FAIL';

export const Encounter_SAVE_REQUEST = 'Encounter_SAVE_REQUEST';
export const Encounter_SAVE_SUCCESS = 'Encounter_SAVE_SUCCESS';
export const Encounter_SAVE_FAIL = 'Encounter_SAVE_FAIL';

export const Encounter_LISTFORSTP_REQUEST = 'Encounter_LISTFORSTP_REQUEST';
export const Encounter_LISTFORSTP_SUCCESS = 'Encounter_LISTFORSTP_SUCCESS';
export const Encounter_LISTFORSTP_FAIL = 'Encounter_LISTFORSTP_FAIL';

export const Refund_SAVE_REQUEST = 'Refund_SAVE_REQUEST';
export const Refund_SAVE_SUCCESS = 'Refund_SAVE_SUCCESS';
export const Refund_SAVE_FAIL = 'Refund_SAVE_FAIL';

export const ServCxl_SAVE_REQUEST = 'ServCxl_SAVE_REQUEST';
export const ServCxl_SAVE_SUCCESS = 'ServCxl_SAVE_SUCCESS';
export const ServCxl_SAVE_FAIL = 'ServCxl_SAVE_FAIL';

export const ServOrd_SAVE_REQUEST = 'ServOrd_SAVE_REQUEST';
export const ServOrd_SAVE_SUCCESS = 'ServOrd_SAVE_SUCCESS';
export const ServOrd_SAVE_FAIL = 'ServOrd_SAVE_FAIL';

export const SingleServiceCharge_REQUEST = 'SingleServiceCharge_REQUEST';
export const SingleServiceCharge_SUCCESS = 'SingleServiceCharge_SUCCESS';
export const SingleServiceCharge_FAIL = 'SingleServiceCharge_FAIL';

export const OPSEARCHINVOICE_SAVE_REQUEST = 'OPSEARCHINVOICE_SAVE_REQUEST';
export const OPSEARCHINVOICE_SAVE_SUCCESS = 'OPSEARCHINVOICE_SAVE_SUCCESS';
export const OPSEARCHINVOICE_SAVE_FAIL = 'OPSEARCHINVOICE_SAVE_FAIL';

export const OPSEARCHINVOICEData_REQUEST = 'OPSEARCHINVOICEData_REQUEST';
export const OPSEARCHINVOICEData_SUCCESS = 'OPSEARCHINVOICEData_SUCCESS';
export const OPSEARCHINVOICEData_FAIL = 'OPSEARCHINVOICEData_FAIL';


export const OPPRINTINVOICEData_REQUEST = 'OPPRINTINVOICEData_REQUEST';
export const OPPRINTINVOICEData_SUCCESS = 'OPPRINTINVOICEData_SUCCESS';
export const OPPRINTINVOICEData_FAIL = 'OPPRINTINVOICEData_FAIL';

export const INVSEARCHWITHOUTBILLDATA_REQUEST = 'INVSEARCHWITHOUTBILLDATA_REQUEST';
export const INVSEARCHWITHOUTBILLDATA_SUCCESS = 'INVSEARCHWITHOUTBILLDATA_SUCCESS';
export const INVSEARCHWITHOUTBILLDATA_FAIL = 'INVSEARCHWITHOUTBILLDATA_FAIL';

//Billing END

export const RemarksMaster_LIST_REQUEST = 'RemarksMaster_LIST_REQUEST';
export const RemarksMaster_LIST_SUCCESS = 'RemarksMaster_LIST_SUCCESS';
export const RemarksMaster_LIST_FAIL = 'RemarksMaster_LIST_FAIL';

export const RemarksMaster_SAVE_REQUEST = 'RemarksMaster_SAVE_REQUEST';
export const RemarksMaster_SAVE_SUCCESS = 'RemarksMaster_SAVE_SUCCESS';
export const RemarksMaster_SAVE_FAIL = 'RemarksMaster_SAVE_FAIL';

export const NursingUnit_LIST_REQUEST = 'NursingUnit_LIST_REQUEST';
export const NursingUnit_LIST_SUCCESS = 'NursingUnit_LIST_SUCCESS';
export const NursingUnit_LIST_FAIL = 'NursingUnit_LIST_FAIL';



export const NursingUnit_Transfer_LIST_REQUEST = 'NursingUnit_Transfer_LIST_REQUEST';
export const NursingUnit_Transfer_LIST_SUCCESS = 'NursingUnit_Transfer_LIST_SUCCESS';
export const NursingUnit_Transfer_LIST_FAIL = 'NursingUnit_Transfer_LIST_FAIL';


export const NursingUnit_MarkArvl_LIST_REQUEST = 'NursingUnit_MarkArvl_LIST_REQUEST';
export const NursingUnit_MarkArvl_LIST_SUCCESS = 'NursingUnit_MarkArvl_LIST_SUCCESS';
export const NursingUnit_MarkArvl_LIST_FAIL = 'NursingUnit_MarkArvl_LIST_FAIL';

export const BedOCCUP_LIST_REQUEST = 'BedOCCUP_LIST_REQUEST';
export const BedOCCUP_LIST_SUCCESS = 'BedOCCUP_LIST_SUCCESS';
export const BedOCCUP_LIST_FAIL = 'BedOCCUP_LIST_FAIL';

export const getBedList_REQUEST = 'getBedList_REQUEST';
export const getBedList_SUCCESS = 'getBedList_SUCCESS';
export const getBedList_FAIL = 'getBedList_FAIL';

export const Bed_LIST_REQUEST = 'Bed_LIST_REQUEST';
export const Bed_LIST_SUCCESS = 'Bed_LIST_SUCCESS';
export const Bed_LIST_FAIL = 'Bed_LIST_FAIL';

export const ERBed_LIST_REQUEST = 'ERBed_LIST_REQUEST';
export const ERBed_LIST_SUCCESS = 'ERBed_LIST_SUCCESS';
export const ERBed_LIST_FAIL = 'ERBed_LIST_FAIL';

export const Bed_SAVE_REQUEST = 'Bed_SAVE_REQUEST';
export const Bed_SAVE_SUCCESS = 'Bed_SAVE_SUCCESS';
export const Bed_SAVE_FAIL = 'Bed_SAVE_FAIL';

export const FromBed_LIST_REQUEST = 'FromBed_LIST_REQUEST';
export const FromBed_LIST_SUCCESS = 'FromBed_LIST_SUCCESS';
export const FromBed_LIST_FAIL = 'FromBed_LIST_FAIL';
export const FromBed_SAVE_REQUEST = 'FromBed_SAVE_REQUEST';
export const FromBed_SAVE_SUCCESS = 'FromBed_SAVE_SUCCESS';
export const FromBed_SAVE_FAIL = 'FromBed_SAVE_FAIL';

export const ToBed_LIST_REQUEST = 'ToBed_LIST_REQUEST';
export const ToBed_LIST_SUCCESS = 'ToBed_LIST_SUCCESS';
export const ToBed_LIST_FAIL = 'ToBed_LIST_FAIL';
export const ToBed_SAVE_REQUEST = 'ToBed_SAVE_REQUEST';
export const ToBed_SAVE_SUCCESS = 'ToBed_SAVE_SUCCESS';
export const ToBed_SAVE_FAIL = 'ToBed_SAVE_FAIL';

export const COMPANY_FACILITY_WISE_REQUEST = 'COMPANY_FACILITY_WISE_REQUEST';
export const COMPANY_FACILITY_WISE_SUCCESS = 'COMPANY_FACILITY_WISE_SUCCESS';
export const COMPANY_FACILITY_WISE_FAIL = 'COMPANY_FACILITY_WISE_FAIL';

export const ServiceOrder_REQUEST = 'ServiceOrder_REQUEST';
export const ServiceOrder_SUCCESS = 'ServiceOrder_SUCCESS';
export const ServiceOrder_FAIL = 'ServiceOrder_FAIL';

export const OPRefund_REQUEST = 'OPRefund_REQUEST';
export const OPRefund_SUCCESS = 'OPRefund_SUCCESS';
export const OPRefund_FAIL = 'OPRefund_FAIL';

export const OPInvoice_REQUEST = 'OPInvoice_REQUEST';
export const OPInvoice_SUCCESS = 'OPInvoice_SUCCESS';
export const OPInvoice_FAIL = 'OPInvoice_FAIL';

export const SaveServiceDetailRateChange_REQUEST = 'SaveServiceDetailRateChange_REQUEST';
export const SaveServiceDetailRateChange_SUCCESS = 'SaveServiceDetailRateChange_SUCCESS';
export const SaveServiceDetailRateChange_FAIL = 'SaveServiceDetailRateChange_FAIL';

export const UnBillServiceOrder_REQUEST = 'UnBillServiceOrder_REQUEST';
export const UnBillServiceOrder_SUCCESS = 'UnBillServiceOrder_SUCCESS';
export const UnBillServiceOrder_FAIL = 'UnBillServiceOrder_FAIL';

export const SurgeryServiceOrder_REQUEST = 'SurgeryServiceOrder_REQUEST';
export const SurgeryServiceOrder_SUCCESS = 'SurgeryServiceOrder_SUCCESS';
export const SurgeryServiceOrder_FAIL = 'SurgeryServiceOrder_FAIL';

export const IPUnBillServiceOrder_REQUEST = 'IPUnBillServiceOrder_REQUEST';
export const IPUnBillServiceOrder_SUCCESS = 'IPUnBillServiceOrder_SUCCESS';
export const IPUnBillServiceOrder_FAIL = 'IPUnBillServiceOrder_FAIL';

export const MOUDetails_REQUEST = 'MOUDetails_REQUEST';
export const MOUDetails_SUCCESS = 'MOUDetails_SUCCESS';
export const MOUDetails_FAIL = 'MOUDetails_FAIL';

export const DoctorDetail_REQUEST = 'DoctorDetail_REQUEST';
export const DoctorDetail_SUCCESS = 'DoctorDetail_SUCCESS';
export const DoctorDetail_FAIL = 'DoctorDetail_FAIL';

export const DoctorDetailList_REQUEST = 'DoctorDetailList_REQUEST';
export const DoctorDetailList_SUCCESS = 'DoctorDetailList_SUCCESS';
export const DoctorDetailList_FAIL = 'DoctorDetailList_FAIL';

export const MOUDetails_List_REQUEST = 'MOUDetails_List_REQUEST';
export const MOUDetails_List_SUCCESS = 'MOUDetails_List_SUCCESS';
export const MOUDetails_List_FAIL = 'MOUDetails_List_FAIL';

export const ItemExclusion_REQUEST = 'ItemExclusion_REQUEST';
export const ItemExclusion_SUCCESS = 'ItemExclusion_SUCCESS';
export const ItemExclusion_FAIL = 'ItemExclusion_FAIL';

export const ItemExclusion_List_REQUEST = 'ItemExclusion_List_REQUEST';
export const ItemExclusion_List_SUCCESS = 'ItemExclusion_List_SUCCESS';
export const ItemExclusion_List_FAIL = 'ItemExclusion_List_FAIL';

export const GroupExclusion_REQUEST = 'GroupExclusion_REQUEST';
export const GroupExclusion_SUCCESS = 'GroupExclusion_SUCCESS';
export const GroupExclusion_FAIL = 'GroupExclusion_FAIL';

export const GroupExclusion_List_REQUEST = 'GroupExclusion_List_REQUEST';
export const GroupExclusion_List_SUCCESS = 'GroupExclusion_List_SUCCESS';
export const GroupExclusion_List_FAIL = 'GroupExclusion_List_FAIL';

export const NONDISCOUNT_ITEM_REQUEST = 'NONDISCOUNT_ITEM_REQUEST';
export const NONDISCOUNT_ITEM_SUCCESS = 'NONDISCOUNT_ITEM_SUCCESS';
export const NONDISCOUNT_ITEM_FAIL = 'NONDISCOUNT_ITEM_FAIL';

export const NONDISCOUNT_ITEM_LIST_REQUEST = 'NONDISCOUNT_ITEM_LIST_REQUEST';
export const NONDISCOUNT_ITEM_LIST_SUCCESS = 'NONDISCOUNT_ITEM_LIST_SUCCESS';
export const NONDISCOUNT_ITEM_LIST_FAIL = 'NONDISCOUNT_ITEM_LIST_FAIL';

export const NONDISCOUNT_GROUP_REQUEST = 'NONDISCOUNT_GROUP_REQUEST';
export const NONDISCOUNT_GROUP_SUCCESS = 'NONDISCOUNT_GROUP_SUCCESS';
export const NONDISCOUNT_GROUP_FAIL = 'NONDISCOUNT_GROUP_FAIL';

export const NONDISCOUNT_GROUP_LIST_REQUEST = 'NONDISCOUNT_GROUP_LIST_REQUEST';
export const NONDISCOUNT_GROUP_LIST_SUCCESS = 'NONDISCOUNT_GROUP_LIST_SUCCESS';
export const NONDISCOUNT_GROUP_LIST_FAIL = 'NONDISCOUNT_GROUP_LIST_FAIL';

export const EmploymentType_LIST_REQUEST = 'EmploymentType_LIST_REQUEST';
export const EmploymentType_LIST_SUCCESS = 'EmploymentType_LIST_SUCCESS';
export const EmploymentType_LIST_FAIL = 'EmploymentType_LIST_FAIL';

export const ClinicForEmployee_LIST_REQUEST = 'ClinicForEmployee_LIST_REQUEST';
export const ClinicForEmployee_LIST_SUCCESS = 'ClinicForEmployee_LIST_SUCCESS';
export const ClinicForEmployee_LIST_FAIL = 'ClinicForEmployee_LIST_FAIL';

export const DoctorVisitMaster_LIST_REQUEST = 'DoctorVisitMaster_LIST_REQUEST';
export const DoctorVisitMaster_LIST_SUCCESS = 'DoctorVisitMaster_LIST_SUCCESS';
export const DoctorVisitMaster_LIST_FAIL = 'DoctorVisitMaster_LIST_FAIL';

export const EmployeeFacility_LIST_REQUEST = 'EmployeeFacility_LIST_REQUEST';
export const EmployeeFacility_LIST_SUCCESS = 'EmployeeFacility_LIST_SUCCESS';
export const EmployeeFacility_LIST_FAIL = 'EmployeeFacility_LIST_FAIL';

export const EmploymentType_SAVE_REQUEST = 'EmploymentType_SAVE_REQUEST';
export const EmploymentType_SAVE_SUCCESS = 'EmploymentType_SAVE_SUCCESS';
export const EmploymentType_SAVE_FAIL = 'EmploymentType_SAVE_FAIL';

export const PagePermission_LIST_REQUEST = 'PagePermission_LIST_REQUEST';
export const PagePermission_LIST_SUCCESS = 'PagePermission_LIST_SUCCESS';
export const PagePermission_LIST_FAIL = 'PagePermission_LIST_FAIL';

export const EmployeePagePermission_LIST_REQUEST = 'EmployeePagePermission_LIST_REQUEST';
export const EmployeePagePermission_LIST_SUCCESS = 'EmployeePagePermission_LIST_SUCCESS';
export const EmployeePagePermission_LIST_FAIL = 'EmployeePagePermission_LIST_FAIL';

export const FacilitywiseRole_LIST_REQUEST = 'FacilitywiseRole_LIST_REQUEST';
export const FacilitywiseRole_LIST_SUCCESS = 'FacilitywiseRole_LIST_SUCCESS';
export const FacilitywiseRole_LIST_FAIL = 'FacilitywiseRole_LIST_FAIL';

export const FacilitywiseEmployee_LIST_REQUEST = 'FacilitywiseEmployee_LIST_REQUEST';
export const FacilitywiseEmployee_LIST_SUCCESS = 'FacilitywiseEmployee_LIST_SUCCESS';
export const FacilitywiseEmployee_LIST_FAIL = 'FacilitywiseEmployee_LIST_FAIL';

export const PageFunctionPermission_LIST_REQUEST = 'PageFunctionPermission_LIST_REQUEST';
export const PageFunctionPermission_LIST_SUCCESS = 'PageFunctionPermission_LIST_SUCCESS';
export const PageFunctionPermission_LIST_FAIL = 'PageFunctionPermission_LIST_FAIL';

export const EmployeePageFunctionPermission_LIST_REQUEST = 'EmployeePageFunctionPermission_LIST_REQUEST';
export const EmployeePageFunctionPermission_LIST_SUCCESS = 'EmployeePageFunctionPermission_LIST_SUCCESS';
export const EmployeePageFunctionPermission_LIST_FAIL = 'EmployeePageFunctionPermission_LIST_FAIL';

export const saveRolePermissions_SAVE_REQUEST = 'saveRolePermissions_SAVE_REQUEST';
export const saveRolePermissions_SAVE_SUCCESS = 'saveRolePermissions_SAVE_SUCCESS';
export const saveRolePermissions_SAVE_FAIL = 'saveRolePermissions_SAVE_FAIL';

export const saveEmployeePermissions_SAVE_REQUEST = 'saveEmployeePermissions_SAVE_REQUEST';
export const saveEmployeePermissions_SAVE_SUCCESS = 'saveEmployeePermissions_SAVE_SUCCESS';
export const saveEmployeePermissions_SAVE_FAIL = 'saveEmployeePermissions_SAVE_FAIL';

export const GetModuleReasonAudit_REQUEST = 'GetModuleReasonAudit_REQUEST';
export const GetModuleReasonAudit_SUCCESS = 'GetModuleReasonAudit_SUCCESS';
export const GetModuleReasonAudit_FAIL = 'GetModuleReasonAudit_FAIL';

export const GetReasonMasterAudit_REQUEST = 'GetReasonMasterAudit_REQUEST';
export const GetReasonMasterAudit_SUCCESS = 'GetReasonMasterAudit_SUCCESS';
export const GetReasonMasterAudit_FAIL = 'GetReasonMasterAudit_FAIL';

export const getUploadPrescriptionAudit_REQUEST = 'getUploadPrescriptionAudit_REQUEST';
export const getUploadPrescriptionAudit_SUCCESS = 'getUploadPrescriptionAudit_SUCCESS';
export const getUploadPrescriptionAudit_FAIL = 'getUploadPrescriptionAudit_FAIL';

export const getInpatientRequestAudit_REQUEST = 'getInpatientRequestAudit_REQUEST';
export const getInpatientRequestAudit_SUCCESS = 'getInpatientRequestAudit_SUCCESS';
export const getInpatientRequestAudit_FAIL = 'getInpatientRequestAudit_FAIL';

export const saveCopyRolePermissions_SAVE_REQUEST = 'saveCopyRolePermissions_SAVE_REQUEST';
export const saveCopyRolePermissions_SAVE_SUCCESS = 'saveCopyRolePermissions_SAVE_SUCCESS';
export const saveCopyRolePermissions_SAVE_FAIL = 'saveCopyRolePermissions_SAVE_FAIL';

export const CompanyChangeRate_SAVE_REQUEST = 'CompanyChangeRate_SAVE_REQUEST';
export const CompanyChangeRate_SAVE_SUCCESS = 'CompanyChangeRate_SAVE_SUCCESS';
export const CompanyChangeRate_SAVE_FAIL = 'CompanyChangeRate_SAVE_FAIL';

//Added By Chandan
export const EQUIPMENT_LIST_REQUEST = 'EQUIPMENT_LIST_REQUEST';
export const EQUIPMENT_LIST_SUCCESS = 'EQUIPMENT_LIST_SUCCESS';
export const EQUIPMENT_LIST_FAIL = 'EQUIPMENT_LIST_FAIL';

export const EQUIPMENTLIST_SAVE_REQUEST = 'EQUIPMENTLIST_SAVE_REQUEST';
export const EQUIPMENTLIST_SAVE_SUCCESS = 'EQUIPMENTLIST_SAVE_SUCCESS';
export const EQUIPMENTLIST_SAVE_FAIL = 'EQUIPMENTLIST_SAVE_FAIL';

export const ESTIMATE_LIST_REQUEST = 'ESTIMATE_LIST_REQUEST';
export const ESTIMATE_LIST_SUCCESS = 'ESTIMATE_LIST_SUCCESS';
export const ESTIMATE_LIST_FAIL = 'ESTIMATE_LIST_FAIL';
//End Here

export const PAYOR_ALL_LIST_REQUEST = 'PAYOR_ALL_LIST_REQUEST';
export const PAYOR_ALL_LIST_SUCCESS = 'PAYOR_ALL_LIST_SUCCESS';
export const PAYOR_ALL_LIST_FAIL = 'PAYOR_ALL_LIST_FAIL';

export const REASON_LIST_REQUEST = 'REASON_LIST_REQUEST';
export const REASON_LIST_SUCCESS = 'REASON_LIST_SUCCESS';
export const REASON_LIST_FAIL = 'REASON_LIST_FAIL';

export const APPROVALMATRIX_LIST_REQUEST = 'APPROVALMATRIX_LIST_REQUEST';
export const APPROVALMATRIX_LIST_SUCCESS = 'APPROVALMATRIX_LIST_SUCCESS';
export const APPROVALMATRIX_LIST_FAIL = 'APPROVALMATRIX_LIST_FAIL';
//Added by Ritesh
export const BANK_MASTER_SAVE_REQUEST = 'BANK_MASTER_SAVE_REQUEST';
export const BANK_MASTER_SAVE_SUCCESS = 'BANK_MASTER_SAVE_SUCCESS';
export const BANK_MASTER_SAVE_FAIL = 'BANK_MASTER_SAVE_FAIL';

export const BANK_MASTER_LIST_REQUEST = 'BANK_MASTER_LIST_REQUEST';
export const BANK_MASTER_LIST_SUCCESS = 'BANK_MASTER_LIST_SUCCESS';
export const BANK_MASTER_REASON_LIST_FAIL = 'BANK_MASTER_REASON_LIST_FAIL';

export const CARD_MASTER_SAVE_REQUEST = 'CARD_MASTER_SAVE_REQUEST';
export const CARD_MASTER_SAVE_SUCCESS = 'CARD_MASTER_SAVE_SUCCESS';
export const CARD_MASTER_SAVE_FAIL = 'CARD_MASTER_SAVE_FAIL';

export const CARD_MASTER_LIST_REQUEST = 'CARD_MASTER_LIST_REQUEST';
export const CARD_MASTER_LIST_SUCCESS = 'CARD_MASTER_LIST_SUCCESS';
export const CARD_MASTER_REASON_LIST_FAIL = 'CARD_MASTER_REASON_LIST_FAIL';

//END
//Rajesh Yati
export const Desination_LIST_REQUEST = 'Desination_LIST_REQUEST';
export const Desination_LIST_SUCCESS = 'Desination_LIST_SUCCESS';
export const Desination_LIST_FAIL = 'Desination_LIST_FAIL';

export const APPROVALMATRIX_SAVE_REQUEST = 'APPROVALMATRIX_SAVE_REQUEST';
export const APPROVALMATRIX_SAVE_SUCCESS = 'APPROVALMATRIX_SAVE_SUCCESS';
export const APPROVALMATRIX_SAVE_FAIL = 'APPROVALMATRIX_SAVE_FAIL';

//End
export const GET_MODULEPAGE_MENU_REQUEST = 'GET_MODULEPAGE_MENU_REQUEST';
export const GET_MODULEPAGE_MENU_SUCCESS = 'GET_MODULEPAGE_MENU_SUCCESS';
export const GET_MODULEPAGE_MENU_FAIL = 'GET_MODULEPAGE_MENU_FAIL';

export const GET_USERLOGIN_REQUEST = 'GET_USERLOGIN_REQUEST';
export const GET_USERLOGIN_SUCCESS = 'GET_USERLOGIN_SUCCESS';
export const GET_USERLOGIN_FAIL = 'GET_USERLOGIN_FAIL';

export const GET_MODULEPAGE_BUTTON_REQUEST = 'GET_MODULEPAGE_BUTTON_REQUEST';
export const GET_MODULEPAGE_BUTTON_SUCCESS = 'GET_MODULEPAGE_BUTTON_SUCCESS';
export const GET_MODULEPAGE_BUTTON_FAIL = 'GET_MODULEPAGE_BUTTON_FAIL';

// Patient Series by Enterprise and Facility 
export const PatientSeries_LIST_REQUEST = 'PatientSeries_LIST_REQUEST';
export const PatientSeries_LIST_SUCCESS = 'PatientSeries_LIST_SUCCESS';
export const PatientSeries_LIST_FAIL = 'PatientSeries_LIST_FAIL';

export const PatientSeries_SAVE_REQUEST = 'PatientSeries_SAVE_REQUEST';
export const PatientSeries_SAVE_SUCCESS = 'PatientSeries_SAVE_SUCCESS';
export const PatientSeries_SAVE_FAIL = 'PatientSeries_SAVE_FAIL';

//Added by Ritesh
export const AUDIT_MASTER_LIST_REQUEST = 'AUDIT_MASTER_LIST_REQUEST';
export const AUDIT_MASTER_LIST_SUCCESS = 'AUDIT_MASTER_LIST_SUCCESS';
export const AUDIT_MASTER_LIST_FAIL = 'AUDIT_MASTER_LIST_FAIL';
//END

export const Enterprise_LIST_REQUEST = 'Enterprise_LIST_REQUEST';
export const Enterprise_LIST_SUCCESS = 'Enterprise_LIST_SUCCESS';
export const Enterprise_LIST_FAIL = 'Enterprise_LIST_FAIL';

export const Enterprise_SAVE_REQUEST = 'Enterprise_SAVE_REQUEST';
export const Enterprise_SAVE_SUCCESS = 'Enterprise_SAVE_SUCCESS';
export const Enterprise_SAVE_FAIL = 'Enterprise_SAVE_FAIL';

export const IPPATIENT_REQUEST = 'IPPATIENT_REQUEST';
export const IPPATIENT_SUCCESS = 'IPPATIENT_SUCCESS';
export const IPPATIENT_FAIL = 'IPPATIENT_FAIL';

export const ENCSTATUS_LIST_REQUEST = 'ENCSTATUS_LIST_REQUEST';
export const ENCSTATUS_LIST_SUCCESS = 'ENCSTATUS_LIST_SUCCESS';
export const ENCSTATUS_LIST_FAIL = 'ENCSTATUS_LIST_FAIL';

export const DISCHARGE_LIST_REQUEST = 'DISCHARGE_LIST_REQUEST';
export const DISCHARGE_LIST_SUCCESS = 'DISCHARGE_LIST_SUCCESS';
export const DISCHARGE_LIST_FAIL = 'DISCHARGE_LIST_FAIL';

export const IPBILLSUMM_REQUEST = 'IPBILLSUMM_REQUEST';
export const IPBILLSUMM_SUCCESS = 'IPBILLSUMM_SUCCESS';
export const IPBILLSUMM_FAIL = 'IPBILLSUMM_FAIL';

export const DOCIPVISIT_REQUEST = 'DOCIPVISIT_REQUEST';
export const DOCIPVISIT_SUCCESS = 'DOCIPVISIT_SUCCESS';
export const DOCIPVISIT_FAIL = 'DOCIPVISIT_FAIL';

export const DOCERVISIT_REQUEST = 'DOCERVISIT_REQUEST';
export const DOCERVISIT_SUCCESS = 'DOCERVISIT_SUCCESS';
export const DOCERVISIT_FAIL = 'DOCERVISIT_FAIL';

export const ModulePagePinned_SAVE_REQUEST = 'ModulePagePinned_SAVE_REQUEST';
export const ModulePagePinned_SAVE_SUCCESS = 'ModulePagePinned_SAVE_SUCCESS';
export const ModulePagePinned_SAVE_FAIL = 'ModulePagePinned_SAVE_FAIL';

export const BulkRegistration_SAVE_REQUEST = 'BulkRegistration_SAVE_REQUEST';
export const BulkRegistration_SAVE_SUCCESS = 'BulkRegistration_SAVE_SUCCESS';
export const BulkRegistration_SAVE_FAIL = 'BulkRegistration_SAVE_FAIL';

export const EmployeeByTeam_LIST_REQUEST = 'EmployeeByTeam_LIST_REQUEST';
export const EmployeeByTeam_LIST_SUCCESS = 'EmployeeByTeam_LIST_SUCCESS';
export const EmployeeByTeam_LIST_FAIL = 'EmployeeByTeam_LIST_FAIL';

export const FIELDPERMISSION_LIST_REQUEST = 'FIELDPERMISSION_LIST_REQUEST';
export const FIELDPERMISSION_LIST_SUCCESS = 'FIELDPERMISSION_LIST_SUCCESS';
export const FIELDPERMISSION_LIST_FAIL = 'FIELDPERMISSION_LIST_FAIL';

export const ENCCXLSER_REQUEST = 'ENCCXLSER_REQUEST';
export const ENCCXLSER_SUCCESS = 'ENCCXLSER_SUCCESS';
export const ENCCXLSER_FAIL = 'ENCCXLSER_FAIL';

export const WEEKLYOFF_LIST_REQUEST = 'WEEKLYOFF_LIST_REQUEST';
export const WEEKLYOFF_LIST_SUCCESS = 'WEEKLYOFF_LIST_SUCCESS';
export const WEEKLYOFF_LIST_FAIL = 'WEEKLYOFF_LIST_FAIL';

export const HOLIDAYLIST_LIST_REQUEST = 'HOLIDAYLIST_LIST_REQUEST';
export const HOLIDAYLIST_LIST_SUCCESS = 'HOLIDAYLIST_LIST_SUCCESS';
export const HOLIDAYLIST_LIST_FAIL = 'HOLIDAYLIST_LIST_FAIL';

export const WEEKLYOFF_SAVE_REQUEST = 'WEEKLYOFF_SAVE_REQUEST';
export const WEEKLYOFF_SAVE_SUCCESS = 'WEEKLYOFF_SAVE_SUCCESS';
export const WEEKLYOFF_SAVE_FAIL = 'WEEKLYOFF_SAVE_FAIL';

export const HOLIDAY_SAVE_REQUEST = 'HOLIDAY_SAVE_REQUEST';
export const HOLIDAY_SAVE_SUCCESS = 'HOLIDAY_SAVE_SUCCESS';
export const HOLIDAY_SAVE_FAIL = 'HOLIDAY_SAVE_FAIL';

export const UPDBILLCUR_SAVE_SUCCESS = 'UPDBILLCUR_SAVE_SUCCESS';
export const UPDBILLCUR_SAVE_FAIL = 'UPDBILLCUR_SAVE_FAIL';

export const HOLIDAYMARKUP_LIST_REQUEST = 'HOLIDAYMARKUP_LIST_REQUEST';
export const HOLIDAYMARKUP_LIST_SUCCESS = 'HOLIDAYMARKUP_LIST_SUCCESS';
export const HOLIDAYMARKUP_LIST_FAIL = 'HOLIDAYMARKUP_LIST_FAIL';

export const HOLIDAYMARKUP_SAVE_REQUEST = 'HOLIDAYMARKUP_SAVE_REQUEST';
export const HOLIDAYMARKUP_SAVE_SUCCESS = 'HOLIDAYMARKUP_SAVE_SUCCESS';
export const HOLIDAYMARKUP_SAVE__FAIL = 'HOLIDAYMARKUP_SAVE__FAIL';

export const SURGERYCOMPONENT_SAVE_REQUEST = 'SURGERYCOMPONENT_SAVE_REQUEST';
export const SURGERYCOMPONENT_SAVE_SUCCESS = 'SURGERYCOMPONENT_SAVE_SUCCESS';
export const SURGERYCOMPONENT_SAVE__FAIL = 'SURGERYCOMPONENT_SAVE__FAIL';

export const UNACKService_REQUEST = 'UNACKService_REQUEST';
export const UNACKService_SUCCESS = 'UNACKService_SUCCESS';
export const UNACKService_FAIL = 'UNACKService_SUCCESS_FAIL';

export const ServiceRateChange_REQUEST = 'ServiceRateChange_REQUEST';
export const ServiceRateChange_SUCCESS = 'ServiceRateChange_SUCCESS';
export const ServiceRateChange_FAIL = 'ServiceRateChange_SUCCESS_FAIL';

export const IPSupplServiceDtl_REQUEST = 'IPSupplServiceDtl_REQUEST';
export const IPSupplServiceDtl_SUCCESS = 'IPSupplServiceDtl_SUCCESS';
export const IPSupplServiceDtl_FAIL = 'IPSupplServiceDtl_SUCCESS_FAIL';

export const IPPACKAGE_SAVE_REQUEST = 'IPPACKAGE_SAVE_REQUEST';
export const IPPACKAGE_SAVE_SUCCESS = 'IPPACKAGE_SAVE_SUCCESS';
export const IPPACKAGE_SAVE_FAIL = 'IPPACKAGE_SAVE_FAIL';

export const IPPACKAGE_SERVICEINCLUSION_SAVE_REQUEST = 'IPPACKAGE_SERVICEINCLUSION_SAVE_REQUEST';
export const IPPACKAGE_SERVICEINCLUSION_SAVE_SUCCESS = 'IPPACKAGE_SERVICEINCLUSION_SAVE_SUCCESS';
export const IPPACKAGE_SERVICEINCLUSION_SAVE_FAIL = 'IPPACKAGE_SERVICEINCLUSION_SAVE_FAIL';

export const IPPACKAGE_ITEMINCLUSION_SAVE_REQUEST = 'IPPACKAGE_ITEMINCLUSION_SAVE_REQUEST';
export const IPPACKAGE_ITEMINCLUSION_SAVE_SUCCESS = 'IPPACKAGE_ITEMINCLUSION_SAVE_SUCCESS';
export const IPPACKAGE_ITEMINCLUSION_SAVE_FAIL = 'IPPACKAGE_ITEMINCLUSION_SAVE_FAIL';

export const IPPACKAGE_EXCLUSIONS_SAVE_REQUEST = 'IPPACKAGE_EXCLUSIONS_SAVE_REQUEST';
export const IPPACKAGE_EXCLUSIONS_SAVE_SUCCESS = 'IPPACKAGE_EXCLUSIONS_SAVE_SUCCESS';
export const IPPACKAGE_EXCLUSIONS_SAVE_FAIL = 'IPPACKAGE_EXCLUSIONS_SAVE_FAIL';

export const IPPACKAGE_BEDCHARGE_SAVE_REQUEST = 'IPPACKAGE_BEDCHARGE_SAVE_REQUEST';
export const IPPACKAGE_BEDCHARGE_SAVE_SUCCESS = 'IPPACKAGE_BEDCHARGE_SAVE_SUCCESS';
export const IPPACKAGE_BEDCHARGE_SAVE_FAIL = 'IPPACKAGE_BEDCHARGE_SAVE_FAIL';

export const IPPACKAGE_SURGERYCOMP_SAVE_REQUEST = 'IPPACKAGE_SURGERYCOMP_SAVE_REQUEST';
export const IPPACKAGE_SURGERYCOMP_SAVE_SUCCESS = 'IPPACKAGE_SURGERYCOMP_SAVE_SUCCESS';
export const IPPACKAGE_SURGERYCOMP_SAVE_FAIL = 'IPPACKAGE_SURGERYCOMP_SAVE_FAIL';

export const IPPACKAGE_PAYOR_SAVE_REQUEST = 'IPPACKAGE_PAYOR_SAVE_REQUEST';
export const IPPACKAGE_PAYOR_SAVE_SUCCESS = 'IPPACKAGE_PAYOR_SAVE_SUCCESS';
export const IPPACKAGE_PAYOR_SAVE_FAIL = 'IPPACKAGE_PAYOR_SAVE_FAIL';

export const IPPACKAGE_LIST_REQUEST = 'IPPACKAGE_LIST_REQUEST';
export const IPPACKAGE_LIST_SUCCESS = 'IPPACKAGE_LIST_SUCCESS';
export const IPPACKAGE_LIST_FAIL = 'IPPACKAGE_LIST_FAIL';

export const IPPACKAGE_ALL_LIST_REQUEST = 'IPPACKAGE_ALL_LIST_REQUEST';
export const IPPACKAGE_ALL_LIST_SUCCESS = 'IPPACKAGE_ALL_LIST_SUCCESS';
export const IPPACKAGE_ALL_LIST_FAIL = 'IPPACKAGE_ALL_LIST_FAIL';

export const ORDERUPDATE_REQUEST = 'ORDERUPDATE_REQUEST';
export const ORDERUPDATE_SUCCESS = 'ORDERUPDATE_SUCCESS';
export const ORDERUPDATE_FAIL = 'ORDERUPDATE_FAIL';

export const updBillPayCat_REQUEST = 'updBillPayCat_REQUEST';
export const updBillPayCat_SUCCESS = 'updBillPayCat_SUCCESS';
export const updBillPayCat_FAIL = 'updBillPayCat_FAIL';

export const CLINIC_SAVE_REQUEST = 'CLINIC_SAVE_REQUEST';
export const CLINIC_SAVE_SUCCESS = 'CLINIC_SAVE_SUCCESS';
export const CLINIC_SAVE_FAIL = 'CLINIC_SAVE_FAIL';

export const IPPACKAGE_GETBEDCHARGE_REQUEST = 'IPPACKAGE_GETBEDCHARGE_REQUEST';
export const IPPACKAGE_GETBEDCHARGE_SUCCESS = 'IPPACKAGE_GETBEDCHARGE_SUCCESS';
export const IPPACKAGE_GETBEDCHARGE_FAIL = 'IPPACKAGE_GETBEDCHARGE_FAIL';

export const IPPACKAGE_GETSURGERY_REQUEST = 'IPPACKAGE_GETSURGERY_REQUEST';
export const IPPACKAGE_GETSURGERY_SUCCESS = 'IPPACKAGE_GETSURGERY_SUCCESS';
export const IPPACKAGE_GETSURGERY_FAIL = 'IPPACKAGE_GETSURGERY_FAIL';

export const IPPACKAGE_SAVECOPYFROM_REQUEST = 'IPPACKAGE_SAVECOPYFROM_REQUEST';
export const IPPACKAGE_SAVECOPYFROM_SUCCESS = 'IPPACKAGE_SAVECOPYFROM_SUCCESS';
export const IPPACKAGE_SAVECOPYFROM_FAIL = 'IPPACKAGE_SAVECOPYFROM_FAIL';

export const IncExlSuppDtl_REQUEST = 'IncExlSuppDtl_REQUEST';
export const IncExlSuppDtl_SUCCESS = 'IncExlSuppDtl_SUCCESS';
export const IncExlSuppDtl_FAIL = 'IncExlSuppDtl_FAIL';

export const srchADMList_REQUEST = 'srchADMList_REQUEST';
export const srchADMList_SUCCESS = 'srchADMList_SUCCESS';
export const srchADMList_FAIL = 'srchADMList_FAIL';

export const IPbillPackageSummary_REQUEST = 'IPbillPackageSummary_REQUEST';
export const IPbillPackageSummary_SUCCESS = 'IPbillPackageSummary_SUCCESS';
export const IPbillPackageSummary_FAIL = 'IPbillPackageSummary_FAIL';

export const RoomRent_SAVE_REQUEST = 'RoomRent_SAVE_REQUEST';
export const RoomRent_SAVE_SUCCESS = 'RoomRent_SAVE_SUCCESS';
export const RoomRent_SAVE_FAIL = 'RoomRent_SAVE_FAIL';

export const NurDis_SAVE_REQUEST = 'NurDis_SAVE_REQUEST';
export const NurDis_SAVE_SUCCESS = 'NurDis_SAVE_SUCCESS';
export const NurDis_SAVE_FAIL = 'NurDis_SAVE_FAIL';

export const NurDis_GET_REQUEST = 'NurDis_GET_REQUEST';
export const NurDis_GET_SUCCESS = 'NurDis_GET_SUCCESS';
export const NurDis_GET_FAIL = 'NurDis_GET_FAIL';

export const Disposition_List_REQUEST = 'Disposition_List_REQUEST';
export const Disposition_List_SUCCESS = 'Disposition_List_SUCCESS';
export const Disposition_List_FAIL = 'Disposition_List_FAIL';

export const Discharge_SAVE_REQUEST = 'Discharge_SAVE_REQUEST';
export const Discharge_SAVE_SUCCESS = 'Discharge_SAVE_SUCCESS';
export const Discharge_SAVE_FAIL = 'Discharge_SAVE_FAIL';

export const CurrEXRate_REQUEST = 'CurrEXRate_REQUEST';
export const CurrEXRate_SUCCESS = 'CurrEXRate_SUCCESS';
export const CurrEXRate_FAIL = 'CurrEXRate_FAIL';
export const Ip_bill_settlement_byInvoiceId_List_request = 'Ip_bill_settlement_byInvoiceId_List_request';
export const Ip_bill_settlement_byInvoiceId_List_SUCCESS = 'Ip_bill_settlement_byInvoiceId_List_SUCCESS';
export const Ip_bill_settlement_byInvoiceId_List_FAIL = 'Ip_bill_settlement_byInvoiceId_List_FAIL';

export const Ip_bill_settlement_save_request = 'Ip_bill_settlement_save_request';
export const Ip_bill_settlement_save_SUCCESS = 'Ip_bill_settlement_save_SUCCESS';
export const Ip_bill_settlement_save_FAIL = 'Ip_bill_settlement_save_FAIL';

export const IPbillVerify_SAVE_REQUEST = 'IPbillVerify_SAVE_REQUEST';
export const IPbillVerify_SAVE_SUCCESS = 'IPbillVerify_SAVE_SUCCESS';
export const IPbillVerify_SAVE_FAIL = 'IPbillVerify_SAVE_FAIL';

export const TPAClearance_SAVE_REQUEST = 'TPAClearance_SAVE_REQUEST';
export const TPAClearance_SAVE_SUCCESS = 'TPAClearance_SAVE_SUCCESS';
export const TPAClearance_SAVE_FAIL = 'TPAClearance_SAVE_FAIL';

export const IPPackServiceDtl_REQUEST = 'IPPackServiceDtl_REQUEST';
export const IPPackServiceDtl_SUCCESS = 'IPPackServiceDtl_SUCCESS';
export const IPPackServiceDtl_FAIL = 'IPPackServiceDtl_FAIL';

export const IncExlPckDtl_REQUEST = 'IncExlPckDtl_REQUEST';
export const IncExlPckDtl_SUCCESS = 'IncExlPckDtl_SUCCESS';
export const IncExlPckDtl_FAIL = 'IncExlPckDtl_FAIL';

export const saveIPInvoice_REQUEST = 'saveIPInvoice_REQUEST';
export const saveIPInvoice_SUCCESS = 'saveIPInvoice_SUCCESS';
export const saveIPInvoice_FAIL = 'saveIPInvoice_FAIL';

export const save_DischargeIntimation_REQUEST = 'save_DischargeIntimation_REQUEST';
export const save_DischargeIntimation_SUCCESS = 'save_DischargeIntimation_SUCCESS';
export const save_DischargeIntimation_FAIL = 'save_DischargeIntimation_FAIL';

export const PatientUnSettleAdv_LIST_REQUEST = 'PatientUnSettleAdv_LIST_REQUEST';
export const PatientUnSettleAdv_LIST_SUCCESS = 'PatientUnSettleAdv_LIST_SUCCESS';
export const PatientUnSettleAdv_LIST_FAIL = 'PatientUnSettleAdv_LIST_FAIL';

export const saveFinancialClearance_REQUEST = 'saveFinancialClearance_REQUEST';
export const saveFinancialClearance_SUCCESS = 'saveFinancialClearance_SUCCESS';
export const saveFinancialClearance_FAIL = 'saveFinancialClearance_FAIL';

export const SurgeryPackageCharges_LIST_REQUEST = 'SurgeryPackageCharges_LIST_REQUEST';
export const SurgeryPackageCharges_LIST_SUCCESS = 'SurgeryPackageCharges_LIST_SUCCESS';
export const SurgeryPackageCharges_LIST_FAIL = 'SurgeryPackageCharges_LIST_FAIL';

export const PackageSurgeonCharges_LIST_REQUEST = 'PackageSurgeonCharges_LIST_REQUEST';
export const PackageSurgeonCharges_LIST_SUCCESS = 'PackageSurgeonCharges_LIST_SUCCESS';
export const PackageSurgeonCharges_LIST_FAIL = 'PackageSurgeonCharges_LIST_FAIL';

export const CalculatePackage_REQUEST = 'CalculatePackage_REQUEST';
export const CalculatePackage_SUCCESS = 'CalculatePackage_SUCCESS';
export const CalculatePackage_FAIL = 'CalculatePackage_FAIL';

export const SaveSurgeryPackageOrder_LIST_REQUEST = 'SaveSurgeryPackageOrder_LIST_REQUEST';
export const SaveSurgeryPackageOrder_LIST_SUCCESS = 'SaveSurgeryPackageOrder_LIST_SUCCESS';
export const SaveSurgeryPackageOrder_LIST_FAIL = 'SaveSurgeryPackageOrder_LIST_FAIL';

export const InsEncRecTag_REQUEST = 'InsEncRecTag_REQUEST';
export const InsEncRecTag_SUCCESS = 'InsEncRecTag_SUCCESS';
export const InsEncRecTag_FAIL = 'InsEncRecTag_FAIL';

export const MappedSurgeryComponent_LIST_REQUEST = 'MappedSurgeryComponent_LIST_REQUEST';
export const MappedSurgeryComponent_LIST_SUCCESS = 'MappedSurgeryComponent_LIST_SUCCESS';
export const MappedSurgeryComponent_LIST_FAIL = 'MappedSurgeryComponent_LIST_FAIL';

export const AdvanceHead_LIST_REQUEST = 'AdvanceHead_LIST_REQUEST';
export const AdvanceHead_LIST_SUCCESS = 'AdvanceHead_LIST_SUCCESS';
export const AdvanceHead_LIST_FAIL = 'AdvanceHead_LIST_FAIL';

export const SurgeryCompSvcMapp_LIST_REQUEST = 'SurgeryCompSvcMapp_LIST_REQUEST';
export const SurgeryCompSvcMapp_LIST_SUCCESS = 'SurgeryCompSvcMapp_LIST_SUCCESS';
export const SurgeryCompSvcMapp_LIST_FAIL = 'SurgeryCompSvcMapp_LIST_FAIL';

export const SurgeryCompSvcMapp_SAVE_REQUEST = 'SurgeryCompSvcMapp_SAVE_REQUEST';
export const SurgeryCompSvcMapp_SAVE_SUCCESS = 'SurgeryCompSvcMapp_SAVE_SUCCESS';
export const SurgeryCompSvcMapp_SAVE_FAIL = 'SurgeryCompSvcMapp_SAVE_FAIL';

export const SurgeryComponent_LIST_REQUEST = 'SurgeryComponent_LIST_REQUEST';
export const SurgeryComponent_LIST_SUCCESS = 'SurgeryComponent_LIST_SUCCESS';
export const SurgeryComponent_LIST_FAIL = 'SurgeryComponent_LIST_FAIL';

export const SurgeryComponent_SAVE_REQUEST = 'SurgeryComponent_SAVE_REQUEST';
export const SurgeryComponent_SAVE_SUCCESS = 'SurgeryComponent_SAVE_SUCCESS';
export const SurgeryComponent_SAVE_FAIL = 'SurgeryComponent_SAVE_FAIL';

export const SurgeryCompTariffMapp_LIST_REQUEST = 'SurgeryCompTariffMapp_LIST_REQUEST';
export const SurgeryCompTariffMapp_LIST_SUCCESS = 'SurgeryCompTariffMapp_LIST_SUCCESS';
export const SurgeryCompTariffMapp_LIST_FAIL = 'SurgeryCompTariffMapp_LIST_FAIL';

export const SurgeryCompTariffMapp_SAVE_REQUEST = 'SurgeryCompTariffMapp_SAVE_REQUEST';
export const SurgeryCompTariffMapp_SAVE_SUCCESS = 'SurgeryCompTariffMapp_SAVE_SUCCESS';
export const SurgeryCompTariffMapp_SAVE_FAIL = 'SurgeryCompTariffMapp_SAVE_FAIL';

export const invoiceSettlementData_REQUEST = 'invoiceSettlementData_REQUEST';
export const invoiceSettlementData_SUCCESS = 'invoiceSettlementData_SUCCESS';
export const invoiceSettlementData_FAIL = 'invoiceSettlementData_FAIL';

export const cxlIPBillInitiate_REQUEST = 'cxlIPBillInitiate_REQUEST';
export const cxlIPBillInitiate_SUCCESS = 'cxlIPBillInitiate_SUCCESS';
export const cxlIPBillInitiate_FAIL = 'cxlIPBillInitiate_FAIL';

export const NoteSave_REQUEST = 'NoteSave_REQUEST';
export const NoteSave_SUCCESS = 'NoteSave_SUCCESS';
export const NoteSave_FAIL = 'NoteSave_FAIL';

export const NoteList_REQUEST = 'NoteList_REQUEST';
export const NoteList_SUCCESS = 'NoteList_SUCCESS';
export const NoteList_FAIL = 'NoteList_FAIL';

export const cxlIPBillDischarge_REQUEST = 'cxlIPBillDischarge_REQUEST';
export const cxlIPBillDischarge_SUCCESS = 'cxlIPBillDischarge_SUCCESS';
export const cxlIPBillDischarge_FAIL = 'cxlIPBillDischarge_FAIL';

export const ITEMDISCTEMCON_LIST_REQUEST = 'ITEMDISCTEMCON_LIST_REQUEST';
export const ITEMDISCTEMCON_LIST_SUCCESS = 'ITEMDISCTEMCON_LIST_SUCCESS';
export const ITEMDISCTEMCON_LIST_FAIL = 'ITEMDISCTEMCON_LIST_FAIL';

export const SERVICECHANGERULE_LIST_REQUEST = 'SERVICECHANGERULE_LIST_REQUEST';
export const SERVICECHANGERULE_LIST_SUCCESS = 'SERVICECHANGERULE_LIST_SUCCESS';
export const SERVICECHANGERULE_LIST_FAIL = 'SERVICECHANGERULE_LIST_FAIL';

export const SERVICECHANGERULE_SAVE_REQUEST = 'SERVICECHANGERULE_SAVE_REQUEST';
export const SERVICECHANGERULE_SAVE_SUCCESS = 'SERVICECHANGERULE_SAVE_SUCCESS';
export const SERVICECHANGERULE_SAVE_FAIL = 'SERVICECHANGERULE_SAVE_FAIL';

export const USERPASSWORD_RESET_REQUEST = 'USERPASSWORD_RESET_REQUEST';
export const USERPASSWORD_RESET_SUCCESS = 'USERPASSWORD_RESET_SUCCESS';
export const USERPASSWORD_RESET_FAIL = 'USERPASSWORD_RESET_FAIL';

export const RESETPASSWORD_SAVE_REQUEST = 'RESETPASSWORD_SAVE_REQUEST';
export const RESETPASSWORD_SAVE_SUCCESS = 'RESETPASSWORD_SAVE_SUCCESS';
export const RESETPASSWORD_SAVE_FAIL = 'RESETPASSWORD_SAVE_FAIL';

export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAIL = 'VALIDATE_OTP_FAIL';

export const RESETPASSWORD_GET_REQUEST = 'RESETPASSWORD_GET_REQUEST';
export const RESETPASSWORD_GET_SUCCESS = 'RESETPASSWORD_GET_SUCCESS';
export const RESETPASSWORD_GET_FAIL = 'RESETPASSWORD_GET_FAIL';
export const ASSOCIATE_SAVE_REQUEST = 'ASSOCIATE_SAVE_REQUEST';
export const ASSOCIATE_SAVE_SUCCESS = 'ASSOCIATE_SAVE_SUCCESS';
export const ASSOCIATE_SAVE_FAIL = 'ASSOCIATE_SAVE_FAIL';

export const ASSOCIATE_LIST_REQUEST = 'ASSOCIATE_LIST_REQUEST';
export const ASSOCIATE_LIST_SUCCESS = 'ASSOCIATE_LIST_SUCCESS';
export const ASSOCIATE_LIST_FAIL = 'ASSOCIATE_LIST_FAIL';

export const CHANGEPASSWORD_SAVE_REQUEST = 'CHANGEPASSWORD_SAVE_REQUEST';
export const CHANGEPASSWORD_SAVE_SUCCESS = 'CHANGEPASSWORD_SAVE_SUCCESS';
export const CHANGEPASSWORD_SAVE_FAIL = 'CHANGEPASSWORD_SAVE_FAIL';

export const CHANGEPIN_SAVE_REQUEST = 'CHANGEPin_SAVE_REQUEST';
export const CHANGEPIN_SAVE_SUCCESS = 'CHANGEPin_SAVE_SUCCESS';
export const CHANGEPIN_SAVE_FAIL = 'CHANGEPIN_SAVE_FAIL';

export const COMPPRINT_SAVE_REQUEST = 'COMPPRINT_SAVE_REQUEST';
export const COMPPRINT_SAVE_SUCCESS = 'COMPPRINT_SAVE_SUCCESS';
export const COMPPRINT_SAVE_FAIL = 'COMPPRINT_SAVE_FAIL';
export const ROLE_ACCESS_LIST_REQUEST = 'ROLE_ACCESS_LIST_REQUEST';
export const ROLE_ACCESS_LIST_SUCCESS = 'ROLE_ACCESS_LIST_SUCCESS';
export const ROLE_ACCESS_LIST_FAIL = 'ROLE_ACCESS_LIST_FAIL';

export const GET_CHECK_LIST_REQUEST = 'GET_CHECK_LIST_REQUEST';
export const GET_CHECK_LIST_SUCCESS = 'GET_CHECK_LIST_SUCCESS';
export const GET_CHECK_LIST_FAIL = 'GET_CHECK_LIST_FAIL';

export const ErDashBoard_LIST_REQUEST = 'ErDashBoard_LIST_REQUEST';
export const ErDashBoard_LIST_SUCCESS = 'ErDashBoard_LIST_SUCCESS';
export const ErDashBoard_LIST_FAIL = 'ErDashBoard_LIST_FAIL';

export const ErDashBoard_SAVE_REQUEST = 'ErDashBoard_SAVE_REQUEST';
export const ErDashBoard_SAVE_SUCCESS = 'ErDashBoard_SAVE_SUCCESS';
export const ErDashBoard_SAVE_FAIL = 'ErDashBoard_SAVE_FAIL';

export const TriageCategory_LIST_REQUEST = 'TriageCategory_LIST_REQUEST';
export const TriageCategory_LIST_SUCCESS = 'TriageCategory_LIST_SUCCESS';
export const TriageCategory_LIST_FAIL = 'TriageCategory_LIST_FAIL';

export const TriageCategory_SAVE_REQUEST = 'TriageCategory_SAVE_REQUEST';
export const TriageCategory_SAVE_SUCCESS = 'TriageCategory_SAVE_SUCCESS';
export const TriageCategory_SAVE_FAIL = 'TriageCategory_SAVE_FAIL';

export const SeachEmployee_LIST_REQUEST = 'SeachEmployee_LIST_REQUEST';
export const SeachEmployee_LIST_SUCCESS = 'SeachEmployee_LIST_SUCCESS';
export const SeachEmployee_LIST_FAIL = 'SeachEmployee_LIST_FAIL';

export const SeachFacilityWiseEmployee_LIST_REQUEST = 'SeachFacilityWiseEmployee_LIST_REQUEST';
export const SeachFacilityWiseEmployee_LIST_SUCCESS = 'SeachFacilityWiseEmployee_LIST_SUCCESS';
export const SeachFacilityWiseEmployee_LIST_FAIL = 'SeachFacilityWiseEmployee_LIST_FAIL';

export const SAVECOMPANY_CHECKLIST_REQUEST = 'SAVECOMPANY_CHECKLIST_REQUEST';
export const SAVECOMPANY_CHECKLIST_SUCCESS = 'SAVECOMPANY_CHECKLIST_SUCCESS';
export const SAVECOMPANY_CHECKLIST_FAIL = 'SAVECOMPANY_CHECKLIST_FAIL';

export const saveModuleFromUser_REQUEST = 'saveModuleFromUser_REQUEST';
export const saveModuleFromUser_SUCCESS = 'saveModuleFromUser_SUCCESS';
export const saveModuleFromUser_FAIL = 'saveModuleFromUser_SUCCESS';

export const cxlIPFinancialClearance_REQUEST = 'cxlIPFinancialClearance_REQUEST';
export const cxlIPFinancialClearance_SUCCESS = 'cxlIPFinancialClearance_SUCCESS';
export const cxlIPFinancialClearance_FAIL = 'cxlIPFinancialClearance_FAIL';

export const EmplScrollSummary_REQUEST = 'EmplScrollSummary_REQUEST';
export const EmplScrollSummary_SUCCESS = 'EmplScrollSummary_SUCCESS';
export const EmplScrollSummary_FAIL = 'EmplScrollSummary_FAIL';

export const PatientLedger_LIST_REQUEST = 'PatientLedger_LIST_REQUEST';
export const PatientLedger_LIST_SUCCESS = 'PatientLedger_LIST_SUCCESS';
export const PatientLedger_LIST_FAIL = 'PatientLedger_LIST_FAIL';

export const PatientLedger_REQUEST = 'PatientLedger_REQUEST';
export const PatientLedger_SUCCESS = 'PatientLedger_SUCCESS';
export const PatientLedger_FAIL = 'PatientLedger_FAIL';

export const InsRoomRentAuto_REQUEST = 'InsRoomRentAuto_REQUEST';
export const InsRoomRentAuto_SUCCESS = 'InsRoomRentAuto_SUCCESS';
export const InsRoomRentAuto_FAIL = 'InsRoomRentAuto_FAIL';

export const getEmpLastSubmitScroll_REQUEST = 'getEmpLastSubmitScroll_REQUEST';
export const getEmpLastSubmitScroll_SUCCESS = 'getEmpLastSubmitScroll_SUCCESS';
export const getEmpLastSubmitScroll_FAIL = 'getEmpLastSubmitScroll_FAIL';

export const CurrencyDenomination_REQUEST = 'CurrencyDenomination_REQUEST';
export const CurrencyDenomination_SUCCESS = 'CurrencyDenomination_SUCCESS';
export const CurrencyDenomination_FAIL = 'CurrencyDenomination_FAIL';

export const Currency_REQUEST = 'Currency_REQUEST';
export const Currency_SUCCESS = 'Currency_SUCCESS';
export const Currency_FAIL = 'Currency_FAIL';

export const RECEIPT_SEARCH_REQUEST = 'RECEIPT_SEARCH_REQUEST';
export const RECEIPT_SEARCH_SUCCESS = 'RECEIPT_SEARCH_SUCCESS';
export const RECEIPT_SEARCH_FAIL = 'RECEIPT_SEARCH_FAIL';

export const saveCashierScroll_REQUEST = 'saveCashierScroll_REQUEST';
export const saveCashierScroll_SUCCESS = 'saveCashierScroll_SUCCESS';
export const saveCashierScroll_FAIL = 'saveCashierScroll_FAIL';

export const getEmpFirstSubmitCollec_REQUEST = 'getEmpFirstSubmitCollec_REQUEST';
export const getEmpFirstSubmitCollec_SUCCESS = 'getEmpFirstSubmitCollec_SUCCESS';
export const getEmpFirstSubmitCollec_FAIL = 'getEmpFirstSubmitCollec_FAIL';

export const REFUND_SEARCH_REQUEST = 'REFUND_SEARCH_REQUEST';
export const REFUND_SEARCH_SUCCESS = 'REFUND_SEARCH_SUCCESS';
export const REFUND_SEARCH_FAIL = 'REFUND_SEARCH_FAIL';

export const getScrollData_REQUEST = 'getScrollData_REQUEST';
export const getScrollData_SUCCESS = 'getScrollData_SUCCESS';
export const getScrollData_FAIL = 'getScrollData_FAIL';

export const getScrollList_REQUEST = 'getScrollList_REQUEST';
export const getScrollList_SUCCESS = 'getScrollList_SUCCESS';
export const getScrollList_FAIL = 'getScrollList_FAIL';

export const saveCashierCollection_REQUEST = 'saveCashierCollection_REQUEST';
export const saveCashierCollection_SUCCESS = 'saveCashierCollection_SUCCESS';
export const saveCashierCollection_FAIL = 'saveCashierCollection_FAIL';

export const ERServiceOrder_SAVE_REQUEST = 'ERServiceOrder_SAVE_REQUEST';
export const ERServiceOrder_SAVE_SUCCESS = 'ERServiceOrder_SAVE_SUCCESS';
export const ERServiceOrder_SAVE_FAIL = 'ERServiceOrder_SAVE_FAIL';

export const TESTORDER_SEARCH_REQUEST = 'TESTORDER_SEARCH_REQUEST';
export const TESTORDER_SEARCH_SUCCESS = 'TESTORDER_SEARCH_SUCCESS';
export const TESTORDER_SEARCH_FAIL = 'TESTORDER_SEARCH_FAIL';

export const cxlIPInvoice_REQUEST = 'cxlIPInvoice_REQUEST';
export const cxlIPInvoice_SUCCESS = 'cxlIPInvoice_SUCCESS';
export const cxlIPInvoice_FAIL = 'cxlIPInvoice_FAIL';

export const cxlERInvoice_REQUEST = 'cxlERInvoice_REQUEST';
export const cxlERInvoice_SUCCESS = 'cxlERInvoice_SUCCESS';
export const cxlERInvoice_FAIL = 'cxlERInvoice_FAIL';

export const cxlIPDischarge_REQUEST = 'cxlIPDischarge_REQUEST';
export const cxlIPDischarge_SUCCESS = 'cxlIPDischarge_SUCCESS';
export const cxlIPDischarge_FAIL = 'cxlIPDischarge_FAIL';

export const ServiceLastOrderDtl_LIST_REQUEST = 'ServiceLastOrderDtl_LIST_REQUEST';
export const ServiceLastOrderDtl_LIST_SUCCESS = 'ServiceLastOrderDtl_LIST_SUCCESS';
export const ServiceLastOrderDtl_LIST_FAIL = 'ServiceLastOrderDtl_LIST_FAIL';

export const PrescriptionOrder_SAVE_REQUEST = 'PrescriptionOrder_SAVE_REQUEST';
export const PrescriptionOrder_SAVE_SUCCESS = 'PrescriptionOrder_SAVE_SUCCESS';
export const PrescriptionOrder_SAVE_FAIL = 'PrescriptionOrder_SAVE_FAIL';

export const ERencounter_SAVE_REQUEST = 'ERencounter_SAVE_REQUEST';
export const ERencounter_SAVE_SUCCESS = 'ERencounter_SAVE_SUCCESS';
export const ERencounter_SAVE_FAIL = 'ERencounter_SAVE_FAIL';

export const updCSDtl_REQUEST = 'updCSDtl_REQUEST';
export const updCSDtl_SUCCESS = 'updCSDtl_SUCCESS';
export const updCSDtl_FAIL = 'updCSDtl_FAIL';

export const ERPatientDetailsList_REQUEST = 'ERPatientDetailsList_REQUEST';
export const ERPatientDetailsList_SUCCESS = 'ERPatientDetailsList_SUCCESS';
export const ERPatientDetailsList_FAIL = 'ERPatientDetailsList_FAIL';

export const FormName_REQUEST = 'FormName_REQUEST';
export const FormName_SUCCESS = 'FormName_SUCCESS';
export const FormName_FAIL = 'FormName_FAIL';

export const EmplScrollDtl_REQUEST = 'EmplScrollDtl_REQUEST';
export const EmplScrollDtl_SUCCESS = 'EmplScrollDtl_SUCCESS';
export const EmplScrollDtl_FAIL = 'EmplScrollDtl_FAIL';

export const ChiefComplaint_SAVE_REQUEST = 'ChiefComplaint_SAVE_REQUEST';
export const ChiefComplaint_SAVE_SUCCESS = 'ChiefComplaint_SAVE_SUCCESS';
export const ChiefComplaint_SAVE_FAIL = 'ChiefComplaint_SAVE_FAIL';

export const ChiefComplaint_LIST_REQUEST = 'ChiefComplaint_LIST_REQUEST';
export const ChiefComplaint_LIST_SUCCESS = 'ChiefComplaint_LIST_SUCCESS';
export const ChiefComplaint_LIST_FAIL = 'ChiefComplaint_LIST_FAIL';

export const DiagnoseMaster_LIST_REQUEST = 'DiagnoseMaster_LIST_REQUEST';
export const DiagnoseMaster_LIST_SUCCESS = 'DiagnoseMaster_LIST_SUCCESS';
export const DiagnoseMaster_LIST_FAIL = 'DiagnoseMaster_LIST_FAIL';

export const billListByStatus_REQUEST = 'billListByStatus_REQUEST';
export const billListByStatus_SUCCESS = 'billListByStatus_SUCCESS';
export const billListByStatus_FAIL = 'billListByStatus_FAIL';

export const billListForVerification_REQUEST = 'billListForVerification_REQUEST';
export const billListForVerification_SUCCESS = 'billListForVerification_SUCCESS';
export const billListForVerification_FAIL = 'billListForVerification_FAIL';

export const billListForDispatch_REQUEST = 'billListForDispatch_REQUEST';
export const billListForDispatch_SUCCESS = 'billListForDispatch_SUCCESS';
export const billListForDispatch_FAIL = 'billListForDispatch_FAIL';

export const billListDispatched_REQUEST = 'billListDispatched_REQUEST';
export const billListDispatched_SUCCESS = 'billListDispatched_SUCCESS';
export const billListDispatched_FAIL = 'billListDispatched_FAIL';

export const getBillAdjustDtl_REQUEST = 'getBillAdjustDtl_REQUEST';
export const getBillAdjustDtl_SUCCESS = 'getBillAdjustDtl_SUCCESS';
export const getBillAdjustDtl_FAIL = 'getBillAdjustDtl_FAIL';

export const cxlTPAClearance_REQUEST = 'cxlTPAClearance_REQUEST';
export const cxlTPAClearance_SUCCESS = 'cxlTPAClearance_SUCCESS';
export const cxlTPAClearance_FAIL = 'cxlTPAClearance_FAIL';

export const FILE_SAVE_REQUEST = 'FILE_SAVE_REQUEST';
export const FILE_SAVE_SUCCESS = 'FILE_SAVE_SUCCESS';
export const FILE_SAVE_FAIL = 'FILE_SAVE_FAIL';

export const FACILITY_MASTER_LIST_REQUEST = 'FACILITY_MASTER_LIST_REQUEST';
export const FACILITY_MASTER_LIST_FAIL = 'FACILITY_MASTER_LIST_FAIL';
export const FACILITY_MASTER_LIST_SUCCESS = 'FACILITY_MASTER_LIST_SUCCESS';
export const Camp_SAVE_REQUEST = 'Camp_SAVE_REQUEST';
export const Camp_SAVE_SUCCESS = 'Camp_SAVE_SUCCESS';
export const Camp_SAVE_FAIL = 'Camp_SAVE_FAIL';

export const Camp_LIST_REQUEST = 'Camp_LIST_REQUEST';
export const Camp_LIST_SUCCESS = 'Camp_LIST_SUCCESS';
export const Camp_LIST_FAIL = 'Camp_LIST_FAIL';

export const AnatomicSiteMst_LIST_REQUEST = 'AnatomicSiteMst_LIST_REQUEST';
export const AnatomicSiteMst_LIST_SUCCESS = 'AnatomicSiteMst_LIST_SUCCESS';
export const AnatomicSiteMst_LIST_FAIL = 'AnatomicSiteMst_LIST_FAIL';

export const SERTARHistory_LIST_REQUEST = 'SERTARHistory_LIST_REQUEST';
export const SERTARHistory_LIST_SUCCESS = 'SERTARHistory_LIST_SUCCESS';
export const SERTARHistory_LIST_FAIL = 'SERTARHistory_LIST_FAIL';

export const MappedIPPackageComponent_LIST_REQUEST = 'MappedIPPackageComponent_LIST_REQUEST';
export const MappedIPPackageComponent_LIST_SUCCESS = 'MappedIPPackageComponent_LIST_SUCCESS';
export const MappedIPPackageComponent_LIST_FAIL = 'MappedIPPackageComponent_LIST_FAIL';

export const COMPANY_DISC_MAPPING_LIST_REQUEST = 'COMPANY_DISC_MAPPING_LIST_REQUEST';
export const COMPANY_DISC_MAPPING_LIST_SUCCESS = 'COMPANY_DISC_MAPPING_LIST_SUCCESS';
export const COMPANY_DISC_MAPPING_LIST_FAIL = 'COMPANY_DISC_MAPPING_LIST_FAIL';

export const cxlInvoiceSettlement_REQUEST = 'cxlInvoiceSettlement_REQUEST';
export const cxlInvoiceSettlement_SUCCESS = 'cxlInvoiceSettlement_SUCCESS';
export const cxlInvoiceSettlement_FAIL = 'cxlInvoiceSettlement_FAIL';

export const CompanyNomenclature_LIST_REQUEST = 'CompanyNomenclature_LIST_REQUEST';
export const CompanyNomenclature_LIST_SUCCESS = 'CompanyNomenclature_LIST_SUCCESS';
export const CompanyNomenclature_LIST_FAIL = 'CompanyNomenclature_LIST_FAIL';

export const Building_SAVE_REQUEST = 'Building_SAVE_REQUEST';
export const Building_SAVE_SUCCESS = 'Building_SAVE_SUCCESS';
export const Building_SAVE_FAIL = 'Building_SAVE_FAIL';

export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS';
export const PLAN_LIST_FAIL = 'PLAN_LIST_FAIL';

export const updPrintCount_REQUEST = 'updPrintCount_REQUEST';
export const updPrintCount_SUCCESS = 'updPrintCount_SUCCESS';
export const updPrintCount_FAIL = 'updPrintCount_FAIL';

export const saveBillSettlement_REQUEST = 'saveBillSettlement_REQUEST';
export const saveBillSettlement_SUCCESS = 'saveBillSettlement_SUCCESS';
export const saveBillSettlement_FAIL = 'saveBillSettlement_FAIL';
//added 1Oct2021 for Appointment Integration
export const PatientAppointment_LIST_REQUEST = 'PatientAppointment_LIST_REQUEST';
export const PatientAppointment_LIST_SUCCESS = 'PatientAppointment_LIST_SUCCESS';
export const PatientAppointment_LIST_FAIL = 'PatientAppointment_LIST_FAIL';

export const PatientAppointmentSlots_LIST_REQUEST = 'PatientAppointmentSlots_LIST_REQUEST';
export const PatientAppointmentSlots_LIST_SUCCESS = 'PatientAppointmentSlots_LIST_SUCCESS';
export const PatientAppointmentSlots_LIST_FAIL = 'PatientAppointmentSlots_LIST_FAIL';
//end added 1Oct2021 for Appointment Integration
//added 10Dec2022 for Service Integration
export const ServiceAppointment_LIST_REQUEST = 'ServiceAppointment_LIST_REQUEST';
export const ServiceAppointment_LIST_SUCCESS = 'ServiceAppointment_LIST_SUCCESS';
export const ServiceAppointment_LIST_FAIL = 'ServiceAppointment_LIST_FAIL';
//end added 10dec2022

export const getBillDispatchNo_REQUEST = 'getBillDispatchNo_REQUEST';
export const getBillDispatchNo_SUCCESS = 'getBillDispatchNo_SUCCESS';
export const getBillDispatchNo_FAIL = 'getBillDispatchNo_FAIL';

export const saveWriteOff_REQUEST = 'saveWriteOff_REQUEST';
export const saveWriteOff_SUCCESS = 'saveWriteOff_SUCCESS';
export const saveWriteOff_FAIL = 'saveWriteOff_FAIL';

//pradhyuman work start 
export const SERVICE_CONTROL_PRICE_SAVE_REQUEST = 'SERVICE_CONTROL_PRICE_SAVE_REQUEST';
export const SERVICE_CONTROL_PRICE_SAVE_SUCCESS = 'SERVICE_CONTROL_PRICE_SAVE_SUCCESS';
export const SERVICE_CONTROL_PRICE_SAVE_FAIL = 'SERVICE_CONTROL_PRICE_SAVE_FAIL';

export const SERVICE_CONTROL_PRICE_LIST_REQUEST = 'SERVICE_CONTROL_PRICE_LIST_REQUEST';
export const SERVICE_CONTROL_PRICE_LIST_SUCCESS = 'SERVICE_CONTROL_PRICE_LIST_SUCCESS';
export const SERVICE_CONTROL_PRICE_LIST_FAIL = 'SERVICE_CONTROL_PRICE_LIST_FAIL';
//pradhyuman work end
export const getwriteoffList_REQUEST = 'getwriteoffList_REQUEST';
export const getwriteoffList_SUCCESS = 'getwriteoffList_SUCCESS';
export const getwriteoffList_FAIL = 'getwriteoffList_FAIL';

export const getwriteoffNoDtl_REQUEST = 'getwriteoffNoDtl_REQUEST';
export const getwriteoffNoDtl_SUCCESS = 'getwriteoffNoDtl_SUCCESS';
export const getwriteoffNoDtl_FAIL = 'getwriteoffNoDtl_FAIL';

export const getServDtlCrNote_REQUEST = 'getServDtlCrNote_REQUEST';
export const getServDtlCrNote_SUCCESS = 'getServDtlCrNote_SUCCESS';
export const getServDtlCrNote_FAIL = 'getServDtlCrNote_FAIL';

export const saveDisAllowed_REQUEST = 'saveDisAllowed_REQUEST';
export const saveDisAllowed_SUCCESS = 'saveDisAllowed_SUCCESS';
export const saveDisAllowed_FAIL = 'saveDisAllowed_FAIL';

export const getCreditNoteList_REQUEST = 'getCreditNoteList_REQUEST';
export const getCreditNoteList_SUCCESS = 'getCreditNoteList_SUCCESS';
export const getCreditNoteList_FAIL = 'getCreditNoteList_FAIL';

export const approveWriteOff_REQUEST = 'approveWriteOff_REQUEST';
export const approveWriteOff_SUCCESS = 'approveWriteOff_SUCCESS';
export const approveWriteOff_FAIL = 'approveWriteOff_FAIL';

export const approveDisAllowed_REQUEST = 'approveDisAllowed_REQUEST';
export const approveDisAllowed_SUCCESS = 'approveDisAllowed_SUCCESS';
export const approveDisAllowed_FAIL = 'approveDisAllowed_FAIL';

export const updateBillingService_SUCCESS = 'updateBillingService_SUCCESS';
export const updateBillingService_REQUEST = 'updateBillingService_REQUEST';
export const updateBillingService_FAIL = 'updateBillingService_FAIL';

export const ConsultSetup_SAVE_REQUEST = 'ConsultSetup_SAVE_REQUEST';
export const ConsultSetup_SAVE_SUCCESS = 'ConsultSetup_SAVE_SUCCESS';
export const ConsultSetup_SAVE_FAIL = 'ConsultSetup_SAVE_FAIL';

export const ConsultSetup_LIST_REQUEST = 'ConsultSetup_LIST_REQUEST';
export const ConsultSetup_LIST_SUCCESS = 'ConsultSetup_LIST_SUCCESS';
export const ConsultSetup_LIST_FAIL = 'ConsultSetup_LIST_FAIL';

export const SERATT_LIST_REQUEST = 'SERATT_LIST_REQUEST';
export const SERATT_LIST_SUCCESS = 'SERATT_LIST_SUCCESS';
export const SERATT_LIST_FAIL = 'SERATT_LIST_FAIL';

export const SAVESERATT_LIST_REQUEST = 'SAVESERATT_LIST_REQUEST';
export const SAVESERATT_LIST_SUCCESS = 'SAVESERATT_LIST_SUCCESS';
export const SAVESERATT_LIST_FAIL = 'SAVESERATT_LIST_FAIL';

export const SAVEBLOODSCREEN_LIST_REQUEST = 'SAVEBLOODSCREEN_LIST_REQUEST';
export const SAVEBLOODSCREEN_LIST_SUCCESS = 'SAVEBLOODSCREEN_LIST_SUCCESS';
export const SAVEBLOODSCREENT_LIST_FAIL = 'SAVEBLOODSCREEN_LIST_FAIL';

export const SERATT_SAVE_REQUEST = 'SERFAC_SAVE_REQUEST';
export const SERATT_SAVE_SUCCESS = 'SERFAC_SAVE_SUCCESS';
export const SERATT_SAVE_FAIL = 'SERFAC_SAVE_FAIL';

export const IPPayableServiceDtl_REQUEST = 'IPPayableServiceDtl_REQUEST';
export const IPPayableServiceDtl_SUCCESS = 'IPPayableServiceDtl_SUCCESS';
export const IPPayableServiceDtl_FAIL = 'IPPayableServiceDtl_FAIL';

export const IncExlPayableDtl_REQUEST = 'IncExlPayableDtl_REQUEST';
export const IncExlPayableDtl_SUCCESS = 'IncExlPayableDtl_SUCCESS';
export const IncExlPayableDtl_FAIL = 'IncExlPayableDtl_FAIL';

export const EMPBYTYPE_LIST_REQUEST = 'EMPBYTYPE_LIST_REQUEST';
export const EMPBYTYPE_LIST_SUCCESS = 'EMPBYTYPE_LIST_SUCCESS';
export const EMPBYTYPE_LIST_FAIL = 'EMPBYTYPE_LIST_FAIL';

export const SampleData_LIST_REQUEST = 'SampleData_LIST_REQUEST';
export const SampleData_LIST_SUCCESS = 'SampleData_LIST_SUCCESS';
export const SampleData_LIST_FAIL = 'SampleData_LIST_FAIL';

export const ServiceDetails_LIST_REQUEST = 'ServiceDetails_LIST_REQUEST';
export const ServiceDetails_LIST_SUCCESS = 'ServiceDetails_LIST_SUCCESS';
export const ServiceDetails_LIST_FAIL = 'ServiceDetails_LIST_FAIL';

export const SampleTAT_LIST_REQUEST = 'SampleTAT_LIST_REQUEST';
export const SampleTAT_LIST_SUCCESS = 'SampleTAT_LIST_SUCCESS';
export const SampleTAT_LIST_FAIL = 'SampleTAT_LIST_FAIL';

export const sendLISRISReportInterfaced_LIST_REQUEST = 'sendLISRISReportInterfaced_LIST_REQUEST';
export const sendLISRISReportInterfaced_LIST_SUCCESS = 'sendLISRISReportInterfaced_LIST_SUCCESS';
export const sendLISRISReportInterfaced_LIST_FAIL = 'sendLISRISReportInterfaced_LIST_FAIL';

export const SearchSVCMaster_LIST_REQUEST = 'SearchSVCMaster_LIST_REQUEST';
export const SearchSVCMaster_LIST_SUCCESS = 'SearchSVCMaster_LIST_SUCCESS';
export const SearchSVCMaster_LIST_FAIL = 'SearchSVCMaster_LIST_FAIL';

export const reasonMaster_LIST_REQUEST = 'reasonMaster_LIST_REQUEST';
export const reasonMaster_LIST_SUCCESS = 'reasonMaster_LIST_SUCCESS';
export const reasonMaster_LIST_FAIL = 'reasonMaster_LIST_FAIL';

export const GetModuleReason_LIST_REQUEST = 'GetModuleReason_LIST_REQUEST';
export const GetModuleReason_LIST_SUCCESS = 'GetModuleReason_LIST_SUCCESS';
export const GetModuleReason_LIST_FAIL = 'GetModuleReason_LIST_FAIL';

export const SaveReasonMaster_LIST_REQUEST = 'SaveReasonMaster_LIST_REQUEST';
export const SaveReasonMaster_LIST_SUCCESS = 'SaveReasonMaster_LIST_SUCCESS';
export const SaveReasonMaster_LIST_FAIL = 'SaveReasonMaster_LIST_FAIL';

export const Modules_LIST_REQUEST = 'Modules_LIST_REQUEST';
export const Modules_LIST_SUCCESS = 'Modules_LIST_SUCCESS';
export const Modules_LIST_FAIL = 'Modules_LIST_FAIL';

export const ModuleReason_LIST_REQUEST = 'ModuleReason_LIST_REQUEST';
export const ModuleReason_LIST_SUCCESS = 'ModuleReason_LIST_SUCCESS';
export const ModuleReason_LIST_FAIL = 'ModuleReason_LIST_FAIL';

export const GetModulePageEvents_LIST_REQUEST = 'GetModulePageEvents_LIST_REQUEST';
export const GetModulePageEvents_LIST_SUCCESS = 'GetModulePageEvents_LIST_SUCCESS';
export const GetModulePageEvents_LIST_FAIL = 'GetModulePageEvents_LIST_FAIL';

export const PAGE_LIST_REQUEST = 'PAGE_LIST_REQUEST';
export const PAGE_LIST_SUCCESS = 'PAGE_LIST_SUCCESS';
export const PAGE_LIST_FAIL = 'PAGE_LIST_FAIL';

export const GET_PAGE_VALIDATION_REQUEST = 'GET_PAGE_VALIDATION_REQUEST';
export const GET_PAGE_VALIDATION_SUCCESS = 'GET_PAGE_VALIDATION_SUCCESS';
export const GET_PAGE_VALIDATION_FAIL = 'GET_PAGE_VALIDATION_FAIL';

export const PendingOrder_REQUEST = 'PendingOrder_REQUEST';
export const PendingOrder_SUCCESS = 'PendingOrder_SUCCESS';
export const PendingOrder_FAIL = 'PendingOrder_FAIL';

//--------------ak-----------------------//

export const CurrencyDenomination_LIST_REQUEST = 'CurrencyDenomination_LIST_REQUEST';
export const CurrencyDenomination_LIST_FAIL = 'CurrencyDenomination_LIST_FAIL';
export const CurrencyDenomination_LIST_SUCCESS = 'CurrencyDenomination_LIST_SUCCESS';

export const CurrencyDenomination_SAVE_REQUEST = 'CurrencyDenomination_SAVE_REQUEST';
export const CurrencyDenomination_SAVE_SUCCESS = 'CurrencyDenomination_SAVE_SUCCESS';
export const CurrencyDenomination_SAVE_FAIL = 'CurrencyDenomination_SAVE_FAIL';

export const FreeServiceOrder_LIST_REQUEST = 'FreeServiceOrder_LIST_REQUEST';
export const FreeServiceOrder_LIST_FAIL = 'FreeServiceOrder_LIST_FAIL';
export const FreeServiceOrder_LIST_SUCCESS = 'FreeServiceOrder_LIST_SUCCESS';

export const FreeServiceOrder_SAVE_REQUEST = 'FreeSevriceOrder_SAVE_REQUEST';
export const FreeServiceOrder_SAVE_SUCCESS = 'FreeServiceOrder_SAVE_SUCCESS';
export const FreeServiceOrder_SAVE_FAIL = 'FreeServiceOrder_SAVE_FAIL';

export const Currency_SAVE_REQUEST = 'Currency_SAVE_REQUEST';
export const Currency_SAVE_SUCCESS = 'Currency_SAVE_SUCCESS';
export const Currency_SAVE_FAIL = 'Currency_SAVE_FAIL';

export const CurrencyExchange_LIST_REQUEST = 'CurrencyExchange_LIST_REQUEST';
export const CurrencyExchange_LIST_FAIL = 'CurrencyExchange_LIST_FAIL';
export const CurrencyExchange_LIST_SUCCESS = 'CurrencyExchange_LIST_SUCCESS';

export const GetModulePageFormTagging_LIST_REQUEST = 'GetModulePageFormTagging_LIST_REQUEST';
export const GetModulePageFormTagging_LIST_FAIL = 'GetModulePageFormTagging_LIST_FAIL';
export const GetModulePageFormTagging_LIST_SUCCESS = 'GetModulePageFormTagging_LIST_SUCCESS';

export const getModulePageFromUser_LIST_REQUEST = 'getModulePageFromUser_LIST_REQUEST';
export const getModulePageFromUser_LIST_FAIL = 'getModulePageFromUser_LIST_FAIL';
export const getModulePageFromUser_LIST_SUCCESS = 'getModulePageFromUser_LIST_SUCCESS';

export const GetModulePageFormTagging_SAVE_REQUEST = 'GetModulePageFormTagging_SAVE_REQUEST';
export const GetModulePageFormTagging_SAVE_SUCCESS = 'GetModulePageFormTagging_SAVE_SUCCESS';
export const GetModulePageFormTagging_SAVE_FAIL = 'GetModulePageFormTagging_SAVE_FAIL';

//---------------ak--------------------//
export const GET_NURSING_DISH_OPTION_MST_REQUEST = 'GET_NURSING_DISH_OPTION_MST_REQUEST';
export const GET_NURSING_DISH_OPTION_MST_SUCCESS = 'GET_NURSING_DISH_OPTION_MST_SUCCESS';
export const GET_NURSING_DISH_OPTION_MST_FAIL = 'GET_NURSING_DISH_OPTION_MST_FAIL';

export const GET_ModulePagesByModule_REQUEST = 'GET_ModulePagesByModule_REQUEST';
export const GET_ModulePagesByModule_SUCCESS = 'GET_ModulePagesByModule_SUCCESS';
export const GET_ModulePagesByModule_FAIL = 'GET_ModulePagesByModule_FAIL';

export const SAVE_ModulePageEvent_REQUEST = 'SAVE_ModulePageEvent_REQUEST';
export const SAVE_ModulePageEvent_SUCCESS = 'SAVE_ModulePageEvent_SUCCESS';
export const SAVE_ModulePageEvent_FAIL = 'SAVE_ModulePageEvent_FAIL';

export const GET_ModulePageEvent_REQUEST = 'GET_ModulePageEvent_REQUEST';
export const GET_ModulePageEvent_SUCCESS = 'GET_ModulePageEvent_SUCCESS';
export const GET_ModulePageEvent_FAIL = 'GET_ModulePageEvent_FAIL';

export const GET_ReasonWiseModulePageEvent_REQUEST = 'GET_ReasonWiseModulePageEvent_REQUEST';
export const GET_ReasonWiseModulePageEvent_SUCCESS = 'GET_ReasonWiseModulePageEvent_SUCCESS';
export const GET_ReasonWiseModulePageEvent_FAIL = 'GET_ReasonWiseModulePageEvent_FAIL';

export const UPDATE_ADVANCETYPE_REQUEST = 'UPDATE_ADVANCETYPE_REQUEST';
export const UPDATE_ADVANCETYPE_SUCCESS = 'UPDATE_ADVANCETYPE_SUCCESS';
export const UPDATE_ADVANCETYPE_FAIL = 'UPDATE_ADVANCETYPE_FAIL';

export const GetReceiptAdvanceTypeAudit_LIST_REQUEST = 'GetReceiptAdvanceTypeAudit_LIST_REQUEST';
export const GetReceiptAdvanceTypeAudit_LIST_SUCCESS = 'GetReceiptAdvanceTypeAudit_LIST_SUCCESS';
export const GetReceiptAdvanceTypeAudit_LIST_FAIL = 'GetReceiptAdvanceTypeAudit_LIST_FAIL';

export const BEDCATENC_LIST_REQUEST = 'BEDCATENC_LIST_REQUEST';
export const BEDCATENC_LIST_SUCCESS = 'BEDCATENC_LIST_SUCCESS';
export const BEDCATENC_LIST_FAIL = 'BEDCATENC_LIST_FAIL';

export const PhysicalDischargePatient_SAVE_REQUEST = 'PhysicalDischargePatient_SAVE_REQUEST';
export const PhysicalDischargePatient_SAVE_SUCCESS = 'PhysicalDischargePatient_SAVE_SUCCESS';
export const PhysicalDischargePatient_SAVE_FAIL = 'PhysicalDischargePatient_SAVE_FAIL';

export const PhysicalDischargePatient_GET_REQUEST = 'PhysicalDischargePatient_GET_REQUEST';
export const PhysicalDischargePatient_GET_SUCCESS = 'PhysicalDischargePatient_GET_SUCCESS';
export const PhysicalDischargePatient_GET_FAIL = 'PhysicalDischargePatient_GET_FAIL';

export const ScrollUser_REQUEST = 'ScrollUser_REQUEST';
export const ScrollUser_SUCCESS = 'ScrollUser_SUCCESS';
export const ScrollUser_FAIL = 'ScrollUser_FAIL';

export const GetFormBuilderStatus_REQUEST = 'GetFormBuilderStatus_REQUEST';
export const GetFormBuilderStatus_FAIL = 'GetFormBuilderStatus_FAIL';
export const GetFormBuilderStatus_SUCCESS = 'GetFormBuilderStatus_SUCCESS';
export const FIStatus_LIST_REQUEST = 'FIStatus_LIST_REQUEST';
export const FIStatus_LIST_SUCCESS = 'FIStatus_LIST_SUCCESS';
export const FIStatus_LIST_FAIL = 'FIStatus_LIST_FAIL';

export const FITC_LIST_REQUEST = 'FITC_LIST_REQUEST';
export const FITC_LIST_SUCCESS = 'FITC_LIST_SUCCESS';
export const FITC_LIST_FAIL = 'FITC_LIST_FAIL';

export const Get_SMS_Configuration_REQUEST = "Get_SMS_Configuration_REQUEST";
export const Get_SMS_Configuration_SUCCESS = "Get_SMS_Configuration_SUCCESS";
export const Get_SMS_Configuration_FAIL = "Get_SMS_Configuration_FAIL";

export const Save_SMS_Configuration_REQUEST = "Save_SMS_Configuration_REQUEST";
export const Save_SMS_Configuration_SUCCESS = "Save_SMS_Configuration_SUCCESS";
export const Save_SMS_Configuration_FAIL = "Save_SMS_Confiiguration_FAIL";

export const SMS_Configuration_Save_REQUEST = "SMS_Configuration_Save_REQUEST";
export const SMS_Configuration_Save_SUCCESS = "SMS_Configuration_Save_SUCCESS";
export const SMS_Configuration_Save_FAIL = "SMS_Configuration_Save_FAIL";

export const Get_SMS_EventDetail_REQUEST = "Get_SMS_EventDetail_REQUEST";
export const Get_SMS_EventDetail_SUCCESS = "Get_SMS_EventDetail_SUCCESS";
export const Get_SMS_EventDetail_FAIL = "Get_SMS_EventDetail_FAIL";

export const Get_SMS_Event_WithReceiverDetail_REQUEST = "Get_SMS_Event_WithReceiverDetail_REQUEST";
export const Get_SMS_Event_WithReceiverDetail_SUCCESS = "Get_SMS_Event_WithReceiverDetail_SUCCESS";
export const Get_SMS_Event_WithReceiverDetail_FAIL = "Get_SMS_Event_WithReceiverDetail_FAIL";

export const Get_Report_Config_List_REQUEST = "Get_Report_Config_List_REQUEST";
export const Get_Report_Config_List_SUCCESS = "Get_Report_Config_List_SUCCESS";
export const Get_Report_Config_List_FAIL = "Get_Report_Config_List_FAIL";

export const Get_Email_Setup_REQUEST = "Get_Email_Setup_REQUEST";
export const Get_Email_Setup_SUCCESS = "Get_Email_Setup_SUCCESS";
export const Get_Email_Setup_FAIL = "Get_Email_Setup_FAIL";

export const IPPACKAGE_Get_Payor_Change_REQUEST = "IPPACKAGE_Get_Payor_Change_REQUEST";
export const IPPACKAGE_Get_Payor_Change_SUCCESS = "IPPACKAGE_Get_Payor_Change_SUCCESS";
export const IPPACKAGE_Get_Payor_Change_FAIL = "IPPACKAGE_Get_Payor_Change_FAIL";

export const DOCTOR_SCHEDULE_LIST_REQUEST = "DOCTOR_SCHEDULE_LIST_REQUEST";
export const DOCTOR_SCHEDULE_LIST_SUCCESS = "DOCTOR_SCHEDULE_LIST_SUCCESS";
export const DOCTOR_SCHEDULE_LIST_FAIL = "DOCTOR_SCHEDULE_LIST_FAIL";

export const ImportExcel_Bulk_List_REQUEST = "ImportExcel_Bulk_List_REQUEST";
export const ImportExcel_Bulk_List_SUCCESS = "ImportExcel_Bulk_List_SUCCESS";
export const ImportExcel_Bulk_List_FAIL = "ImportExcel_Bulk_List_FAIL";

export const Mapped_Grade_LIST_REQUEST = 'Mapped_Grade_LIST_REQUEST';
export const Mapped_Grade_LIST_SUCCESS = 'Mapped_Grade_LIST_SUCCESS';
export const Mapped_Grade_LIST_FAIL = 'Mapped_Grade_LIST_FAIL';

export const ReportRevenueDepartmentWise_LIST_REQUEST = 'ReportRevenueDepartmentWise_LIST_REQUEST';
export const ReportRevenueDepartmentWise_LIST_SUCCESS = 'ReportRevenueDepartmentWise_LIST_SUCCESS';
export const ReportRevenueDepartmentWise_LIST_FAIL = 'ReportRevenueDepartmentWise_LIST_FAIL';

export const SaveExcel_Bulk_List_REQUEST = 'SaveExcel_Bulk_List_REQUEST';
export const SaveExcel_Bulk_List_SUCCESS = 'SaveExcel_Bulk_List_SUCCESS';
export const SaveExcel_Bulk_List_FAIL = 'SaveExcel_Bulk_List_FAIL';

export const MisInventoryAnalysisReport_LIST_REQUEST = 'MisInventoryAnalysisReport_LIST_REQUEST';
export const MisInventoryAnalysisReport_LIST_SUCCESS = 'MisInventoryAnalysisReport_LIST_SUCCESS';
export const MisInventoryAnalysisReport_LIST_FAIL = 'MisInventoryAnalysisReport_LIST_FAIL';

export const ValidateItemExcel_List_REQUEST = 'ValidateItemExcel_List_REQUEST';
export const ValidateItemExcel_List_SUCCESS = 'ValidateItemExcel_List_SUCCESS';
export const ValidateItemExcel_List_FAIL = 'ValidateItemExcel_List_FAIL';

export const SaveItemExcel_List_REQUEST = 'SaveItemExcel_List_REQUEST';
export const SaveItemExcel_List_SUCCESS = 'SaveItemExcel_List_SUCCESS';
export const SaveItemExcel_List_FAIL = 'SaveItemExcel_List_FAIL';

export const ProcedureTypeData_LIST_REQUEST = 'ProcedureTypeData_LIST_REQUEST';
export const ProcedureTypeData_LIST_SUCCESS = 'ProcedureTypeData_LIST_SUCCESS';
export const ProcedureTypeData_LIST_FAIL = 'ProcedureTypeData_LIST_FAIL';

export const EMR_comp_LIST_REQUEST = 'EMR_comp_LIST_REQUEST';
export const EMR_comp_LIST_SUCCESS = 'EMR_comp__LIST_SUCCESS';
export const EMR_comp_LIST_FAIL = 'EMR_comp__LIST_FAIL';

export const hpf_grw_sum_LIST_REQUEST = 'hpf_grw_sum_LIST_REQUEST';
export const hpf_grw_sum_LIST_SUCCESS = 'hpf_grw_sum__LIST_SUCCESS';
export const hpf_grw_sum_LIST_FAIL = 'hpf_grw_sum_LIST_FAIL';

export const DEPTMSTEMP_LIST_REQUEST = 'DEPTMSTEMP_LIST_REQUEST';
export const DEPTMSTEMP_LIST_SUCCESS = 'DEPTMSTEMP_LIST_SUCCESS';
export const DEPTMSTEMP_LIST_FAIL = 'DEPTMSTEMP_LIST_FAIL';

export const DEPTMSTEMP_LIST_Result_REQUEST = 'DEPTMSTEMP_LIST_Result_REQUEST';
export const DEPTMSTEMP_LIST_Result_SUCCESS = 'DEPTMSTEMP_LIST_Result_SUCCESS';
export const DEPTMSTEMP_LIST_Result_FAIL = 'DEPTMSTEMP_LIST_Result_FAIL';

export const SAPPayorCategoryDiscountSetup_LIST_REQUEST = 'SAPPayorCategoryDiscountSetup_LIST_REQUEST';
export const SAPPayorCategoryDiscountSetup_LIST_SUCCESS = 'SAPPayorCategoryDiscountSetup_LIST_SUCCESS';
export const SAPPayorCategoryDiscountSetup_LIST_FAIL = 'SAPPayorCategoryDiscountSetup_LIST_FAIL';

export const SAPPayorCategoryDiscountSetup_SAVE_REQUEST = 'SAPPayorCategoryDiscountSetup_SAVE_REQUEST';
export const SAPPayorCategoryDiscountSetup_SAVE_SUCCESS = 'SAPPayorCategoryDiscountSetup_SAVE_SUCCESS';
export const SAPPayorCategoryDiscountSetup_SAVE_FAIL = 'SAPPayorCategoryDiscountSetup_SAVE_FAIL';

export const SendDiscSummToClaimBook_LIST_REQUEST = 'SendDiscSummToClaimBook_LIST_REQUEST';
export const SendDiscSummToClaimBook_LIST_SUCCESS = 'SendDiscSummToClaimBook_LIST_SUCCESS';
export const SendDiscSummToClaimBook_LIST_FAIL = 'SendDiscSummToClaimBook_LIST_FAIL';

export const SAVEMaster_CHECKLIST_REQUEST = 'SAVEMaster_CHECKLIST_REQUEST';
export const SAVEMaster_CHECKLIST_SUCCESS = 'SAVEMaster_CHECKLIST_SUCCESS';
export const SAVEMaster_CHECKLIST_FAIL = 'SAVEMaster_CHECKLIST_FAIL';

export const Get_CourierList_REQUEST = "Get_CourierList_REQUEST";
export const Get_CourierList_SUCCESS = "Get_CourierList_SUCCESS";
export const Get_CourierList_FAIL = "Get_CourierList_FAIL";

export const saveInvoiceVerification_REQUEST = 'saveInvoiceVerification_REQUEST';
export const saveInvoiceVerification_SUCCESS = 'saveInvoiceVerification_SUCCESS';
export const saveInvoiceVerification_FAIL = 'saveInvoiceVerification_FAIL';

export const GetStockDashboard_List_REQUEST = 'GetStockDashboard_List_REQUEST';
export const GetStockDashboard_List_SUCCESS = 'GetStockDashboard_List_SUCCESS';
export const GetStockDashboard_List_FAIL = 'GetStockDashboard_List_FAIL';

export const Get_Vital_Group_List_REQUEST = 'Get_Vital_Group_List_REQUEST';
export const Get_Vital_Group_List_SUCCESS = 'Get_Vital_Group_List_SUCCESS';
export const Get_Vital_Group_List_FAIL = 'Get_Vital_Group_List_FAIL';



export const Get_SMS_Config_Parameter_REQUEST = "Get_SMS_Config_Parameter_REQUEST";
export const Get_SMS_Config_Parameter_SUCCESS = "Get_SMS_Config_Parameter_SUCCESS";
export const Get_SMS_Config_Parameter_FAIL = "Get_SMS_Config_Parameter_FAIL";


export const Get_SMS_Config_Sub_Parameter_REQUEST = "Get_SMS_Config_Sub_Parameter_REQUEST";
export const Get_SMS_Config_Sub_Parameter_SUCCESS = "Get_SMS_Config_Sub_Parameter_SUCCESS";
export const Get_SMS_Config_Sub_Parameter_FAIL = "Get_SMS_Config_Sub_Parameter_FAIL";

export const Ins_Coll_ReportDB_REQUEST = "Ins_Coll_ReportDB_REQUEST";
export const Ins_Coll_ReportDB_SUCCESS = "Ins_Coll_ReportDB_SUCCESS";
export const Ins_Coll_ReportDB_FAIL = "Ins_Coll_ReportDB_FAIL";

export const TAXSETUP_REQ_LIST_REQUEST = "TAXSETUP_REQ_LIST_REQUEST";
export const TAXSETUP_REQ_LIST_SUCCESS = "TAXSETUP_REQ_LIST_SUCCESS";
export const TAXSETUP_REQ_LIST_FAIL = "TAXSETUP_REQ_LIST_FAIL";

export const ReSync_REQ_LIST_REQUEST = "ReSync_REQ_LIST_REQUEST";
export const ReSync_REQ_LIST_SUCCESS = "ReSync_REQ_LIST_SUCCESS";
export const ReSync_REQ_LIST_FAIL = "ReSync_REQ_LIST_FAIL";

export const ReSyncIntergation_REQ_LIST_REQUEST = "ReSync_REQ_LIST_REQUEST";
export const ReSyncIntergation_REQ_LIST_FAIL = "ReSync_REQ_LIST_SUCCESS";
export const ReSyncIntergation_REQ_LIST_SUCCESS = "ReSync_REQ_LIST_FAIL";

export const InvoiceData_REQ_LIST_REQUEST = "InvoiceData_REQ_LIST_REQUEST";
export const InvoiceData_REQ_LIST_SUCCESS = "InvoiceData_REQ_LIST_SUCCESS";
export const InvoiceData_REQ_LIST_FAIL = "InvoiceData_REQ_LIST_FAIL";

export const settlementReSync_REQ_LIST_REQUEST = "settlementReSync_REQ_LIST_REQUEST";
export const settlementReSync_REQ_LIST_SUCCESS = "settlementReSync_REQ_LIST_SUCCESS";
export const settlementReSync_REQ_LIST_FAIL = "settlementReSync_REQ_LIST_FAIL";

export const SettlementData_REQ_LIST_REQUEST = "SettlementData_REQ_LIST_REQUEST";
export const SettlementData_REQ_LIST_SUCCESS = "SettlementData_REQ_LIST_SUCCESS";
export const SettlementData_REQ_LIST_FAIL = "SettlementData_REQ_LIST_FAIL";

export const receiptReSync_REQ_LIST_REQUEST = "receiptReSync_REQ_LIST_REQUEST";
export const receiptReSync_REQ_LIST_SUCCESS = "receiptReSync_REQ_LIST_SUCCESS";
export const receiptReSync_REQ_LIST_FAIL = "receiptReSync_REQ_LIST_FAIL";

export const refundReSync_REQ_LIST_REQUEST = "refundReSync_REQ_LIST_REQUEST";
export const refundReSync_REQ_LIST_SUCCESS = "refundReSync_REQ_LIST_SUCCESS";
export const refundReSync_REQ_LIST_FAIL = "refundReSync_REQ_LIST_FAIL";

export const CollectionData_REQ_LIST_REQUEST = "CollectionData_REQ_LIST_REQUEST";
export const CollectionData_REQ_LIST_SUCCESS = "CollectionData_REQ_LIST_SUCCESS";
export const CollectionData_REQ_LIST_FAIL = "CollectionData_REQ_LIST_FAIL";

export const creditnoteReSync_REQ_LIST_REQUEST = "creditnoteReSync_REQ_LIST_REQUEST";
export const creditnoteReSync_REQ_LIST_SUCCESS = "creditnoteReSync_REQ_LIST_SUCCESS";
export const creditnoteReSync_REQ_LIST_FAIL = "creditnoteReSync_REQ_LIST_FAIL";

export const CreditnoteData_REQ_LIST_REQUEST = "CreditnoteData_REQ_LIST_REQUEST";
export const CreditnoteData_REQ_LIST_SUCCESS = "CreditnoteData_REQ_LIST_SUCCESS";
export const CreditnoteData_REQ_LIST_FAIL = "CreditnoteData_REQ_LIST_FAIL";

export const writeoffReSync_REQ_LIST_REQUEST = "writeoffReSync_REQ_LIST_REQUEST";
export const writeoffReSync_REQ_LIST_SUCCESS = "writeoffReSyncc_REQ_LIST_SUCCESS";
export const writeoffReSync_REQ_LIST_FAIL = "writeoffReSync_REQ_LIST_FAIL";

export const WriteoffData_REQ_LIST_REQUEST = "WriteoffData_REQ_LIST_REQUEST";
export const WriteoffData_REQ_LIST_SUCCESS = "WriteoffData_REQ_LIST_SUCCESS";
export const WriteoffData_REQ_LIST_FAIL = "WriteoffData_REQ_LIST_FAIL";

export const encounterReSync_REQ_LIST_REQUEST = "encounterReSync_REQ_LIST_REQUEST";
export const encounterReSync_REQ_LIST_SUCCESS = "encounterReSync_REQ_LIST_SUCCESS";
export const encounterReSync_REQ_LIST_FAIL = "encounterReSync_REQ_LIST_FAIL";

export const encounterData_REQ_LIST_REQUEST = "encounterData_REQ_LIST_REQUEST";
export const encounterData_REQ_LIST_SUCCESS = "encounterData_REQ_LIST_SUCCESS";
export const encounterData_REQ_LIST_FAIL = "encounterData_REQ_LIST_FAIL";

export const cxlinvoiceReSync_REQ_LIST_REQUEST = "cxlinvoiceReSync_REQ_LIST_REQUEST";
export const cxlinvoiceReSync_REQ_LIST_SUCCESS = "cxlinvoiceReSync_REQ_LIST_SUCCESS";
export const cxlinvoiceReSync_REQ_LIST_FAIL = "cxlinvoiceReSync_REQ_LIST_FAIL";

export const cxlinvoiceData_REQ_LIST_REQUEST = "cxlinvoiceData_REQ_LIST_REQUEST";
export const cxlinvoiceData_REQ_LIST_SUCCESS = "cxlinvoiceData_REQ_LIST_SUCCESS";
export const cxlinvoiceData_REQ_LIST_FAIL = "cxlinvoiceData_REQ_LIST_FAIL";

export const classifiedinvoiceReSync_REQ_LIST_REQUEST = "classifiedinvoiceReSync_REQ_LIST_REQUEST";
export const classifiedinvoiceReSync_REQ_LIST_SUCCESS = "classifiedinvoiceReSync_REQ_LIST_SUCCESS";
export const classifiedinvoiceReSync_REQ_LIST_FAIL = "classifiedinvoiceReSync_REQ_LIST_FAIL";

export const classifiedinvoiceData_REQ_LIST_REQUEST = "classifiedinvoiceData_REQ_LIST_REQUEST";
export const classifiedinvoiceData_REQ_LIST_SUCCESS = "classifiedinvoiceData_REQ_LIST_SUCCESS";
export const classifiedinvoiceData_REQ_LIST_FAIL = "classifiedinvoiceData_REQ_LIST_FAIL";

export const tdsReSync_REQ_LIST_REQUEST = "tdsReSync_REQ_LIST_REQUEST";
export const tdsReSync_REQ_LIST_SUCCESS = "tdsReSync_REQ_LIST_SUCCESS";
export const tdsReSync_REQ_LIST_FAIL = "tdsReSync_REQ_LIST_FAIL";

export const tdsData_REQ_LIST_REQUEST = "tdsData_REQ_LIST_REQUEST";
export const tdsData_REQ_LIST_SUCCESS = "tdsData_REQ_LIST_SUCCESS";
export const tdsData_REQ_LIST_FAIL = "tdsData_REQ_LIST_FAIL";

export const ReportFacilityMapping_LIST_REQUEST = "ReportFacilityMapping_LIST_REQUEST";
export const ReportFacilityMapping_LIST_SUCCESS = "ReportFacilityMapping_LIST_SUCCESS";
export const ReportFacilityMapping_LIST_FAIL = "ReportFacilityMapping_LIST_FAIL";

export const IPBillEditing_SAVE_REQUEST = 'IPBillEditing_SAVE_REQUEST';
export const IPBillEditing_SAVE_SUCCESS = 'IPBillEditing_SAVE_SUCCESS';
export const IPBillEditing_SAVE_FAIL = 'IPBillEditing_SAVE_FAIL';

export const VALIDATE_SETTLEMENT_DATA = "VALIDATE_SETTLEMENT_DATA";
export const ERROR_VALIDATE_SETTLEMENT_DATA = "ERROR_VALIDATE_SETTLEMENT_DATA";

export const GET_SETTLEMENT_DATA_CONST = "GET_SETTLEMENT_DATA_CONST";
export const ERROR_GET_SETTLEMENT_DATA_CONST = "ERROR_GET_SETTLEMENT_DATA_CONST";

export const EXCEL_FILE_UPLOAD_SETTLEMENT = "EXCEL_FILE_UPLOAD_SETTLEMENT";
export const ERROR_EXCEL_FILE_UPLOAD_SETTLEMENT = "ERROR_EXCEL_FILE_UPLOAD_SETTLEMENT";

export const BULK_SETTLEMENT_ADJUSTMENT = "BULK_SETTLEMENT_ADJUSTMENT";
export const Error_BULK_SETTLEMENT_ADJUSTMENT = "Error_BULK_SETTLEMENT_ADJUSTMENT";

export const DOWNLOD_BULK_ENV_CONST = "DOWNLOD_BULK_ENV_CONST";
export const ERROR_DOWNLOD_BULK_ENV_CONST = "ERROR_DOWNLOD_BULK_ENV_CONST";


export const InPatientRequestData_REQUEST = 'InPatientRequestData_REQUEST';
export const InPatientRequestData_SUCCESS = 'InPatientRequestData_SUCCESS';
export const InPatientRequestData_FAIL = 'InPatientRequestData_FAIL';

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';

export const INPATIENTREQCATEGORY_LIST_REQUEST = "INPATIENTREQCATEGORY_LIST_REQUEST";
export const INPATIENTREQCATEGORY_LIST_SUCCESS = "INPATIENTREQCATEGORY_LIST_SUCCESS";
export const INPATIENTREQCATEGORY_LIST_FAIL = "INPATIENTREQCATEGORY_LIST_FAIL";

export const INPATIENTREQCATEGORY_SAVE_REQUEST = 'INPATIENTREQCATEGORY_SAVE_REQUEST';
export const INPATIENTREQCATEGORY_SAVE_SUCCESS = 'INPATIENTREQCATEGORY_SAVE_SUCCESS';
export const INPATIENTREQCATEGORY_SAVE_FAIL = 'INPATIENTREQCATEGORY_SAVE_FAIL';

export const UPDATE_MASTER_REPORT_DATA_REQUEST = 'UPDATE_MASTER_REPORT_DATA_REQUEST';
export const UPDATE_MASTER_REPORT_DATA_SUCESS = 'UPDATE_MASTER_REPORT_DATA_SUCESS';
export const UPDATE_MASTER_REPORT_DATA_FAIL = 'UPDATE_MASTER_REPORT_DATA_FAIL';

export const serviceReSync_REQ_LIST_REQUEST = "serviceReSync_REQ_LIST_REQUEST";
export const serviceReSync_REQ_LIST_SUCCESS = "serviceReSync_REQ_LIST_SUCCESS";
export const serviceReSync_REQ_LIST_FAIL = "serviceReSync_REQ_LIST_FAIL";

export const serviceData_REQ_LIST_REQUEST = "serviceData_REQ_LIST_REQUEST";
export const serviceData_REQ_LIST_SUCCESS = "serviceData_REQ_LIST_SUCCESS";
export const serviceData_REQ_LIST_FAIL = "serviceData_REQ_LIST_FAIL";

export const DoctorSlotTiming_LIST_REQUEST = "DoctorSlotTiming_LIST_REQUEST";
export const DoctorSlotTiming_LIST_SUCCESS = "DoctorSlotTiming_LIST_SUCCESS";
export const DoctorSlotTiming_LIST_FAIL = "DoctorSlotTiming_LIST_FAIL";

export const Hospitaldashbaordsetup_LIST_REQUEST = 'Hospitaldashbaordsetup_LIST_REQUEST';
export const Hospitaldashbaordsetup_LIST_SUCCESS = 'Hospitaldashbaordsetup_LIST_SUCCESS';
export const Hospitaldashbaordsetup_LIST_FAIL = 'Hospitaldashbaordsetup_LIST_FAIL';

export const IpBillAudit_REQ_LIST_REQUEST = "IpBillAudit_REQ_LIST_REQUEST";
export const IpBillAudit_REQ_LIST_SUCCESS = "IpBillAudit_REQ_LIST_SUCCESS";
export const IpBillAudit_REQ_LIST_FAIL = "IpBillAudit_REQ_LIST_FAIL";

export const Registration_Alert_Block_REQUEST = 'Registration_Alert_Block_REQUEST';
export const Registration_Alert_Block_FAIL = 'Registration_Alert_Block_FAIL';
export const Registration_Alert_Block_SUCCESS = 'Registration_Alert_Block_SUCCESS';

export const SaveRegistration_Alert_Block_REQUEST = 'SaveRegistration_Alert_Block_REQUEST';
export const SaveRegistration_Alert_Block_FAIL = 'SaveRegistration_Alert_Block_FAIL';
export const SaveRegistration_Alert_Block_SUCCESS = 'SaveRegistration_Alert_Block_SUCCESS';


export const RECEIPT_LIST_IPBILL_REQUEST = "RECEIPT_LIST_IPBILL_REQUEST";
export const RECEIPT_LIST_IPBILL_FAIL = "RECEIPT_LIST_IPBILL_FAIL";
export const RECEIPT_LIST_IPBILL_SUCCESS = "RECEIPT_LIST_IPBILL_SUCCESS";

export const WEBSITE_SERTARDTL_LIST_REQUEST = 'WEBSITE_SERTARDTL_LIST_REQUEST';
export const WEBSITE_SERTARDTL_LIST_SUCCESS = 'WEBSITE_SERTARDTL_LIST_SUCCESS';
export const WEBSITE_SERTARDTL_LIST_FAIL = 'WEBSITE_SERTARDTL_LIST_FAIL';

export const WEBSITE_CONSTAR_LIST_REQUEST = 'WEBSITE_CONSTAR_LIST_REQUEST';
export const WEBSITE_CONSTAR_LIST_SUCCESS = 'WEBSITE_CONSTAR_LIST_SUCCESS';
export const WEBSITE_CONSTAR_LIST_FAIL = 'WEBSITE_CONSTAR_LIST_FAIL';

export const WEBSITE_SERVICE_SAVE_REQUEST = 'WEBSITE_SERVICE_SAVE_REQUEST';
export const WEBSITE_SERVICE_SAVE_SUCCESS = 'WEBSITE_SERVICE_SAVE_SUCCESS';
export const WEBSITE_SERVICE_SAVE_FAIL = 'WEBSITE_SERVICE_SAVE_FAIL';

export const BATCH_NO_LIST_REQUEST = 'BATCH_NO_LIST_REQUEST';
export const BATCH_NO_LIST_SUCCESS_DATA = 'BATCH_NO_LIST_SUCCESS_DATA';
export const BATCH_NO_LIST_FAIL_DATA = 'BATCH_NO_LIST_FAIL_DATA';

export const RESOURCEDEPARTMENT_LIST_REQUEST = 'RESOURCEDEPARTMENT_LIST_REQUEST';
export const RESOURCEDEPARTMENT_LIST_SUCCESS = 'RESOURCEDEPARTMENT_LIST_SUCCESS';
export const RESOURCEDEPARTMENT_LIST_FAIL = 'RESOURCEDEPARTMENT_LIST_FAIL';

export const RESOURCESUBDEPARTMENT_LIST_REQUEST = 'RESOURCESUBDEPARTMENT_LIST_REQUEST';
export const RESOURCESUBDEPARTMENT_LIST_SUCCESS = 'RESOURCESUBDEPARTMENT_LIST_SUCCESS';
export const RESOURCESUBDEPARTMENT_LIST_FAIL = 'RESOURCESUBDEPARTMENT_LIST_FAIL';

export const RESOURCEMASTER_LIST_REQUEST = 'RESOURCEMASTER_LIST_REQUEST';
export const RESOURCEMASTER_LIST_SUCCESS = 'RESOURCEMASTER_LIST_SUCCESS';
export const RESOURCEMASTER_LIST_FAIL = 'RESOURCEMASTER_LIST_FAIL';

export const VulnerableType_SAVE_REQUEST = "VulnerableType_SAVE_REQUEST";
export const VulnerableType_SAVE_SUCCESS = "VulnerableType_SAVE_SUCCESS";
export const VulnerableType_SAVE_FAIL = "VulnerableType_SAVE_FAIL";

export const VulnerableType_LIST_REQUEST = "VulnerableType_LIST_REQUEST";
export const VulnerableType_LIST_SUCCESS = "VulnerableType_LIST_SUCCESS";
export const VulnerableType_LIST_FAIL = "VulnerableType_LIST_FAIL";

export const ORDERMEDICINE_LIST_REQUEST = "ORDERMEDICINE_LIST_REQUEST";
export const ORDERMEDICINE_LIST_SUCCESS = "ORDERMEDICINE_LIST_SUCCESS";
export const ORDERMEDICINE_LIST_FAIL = "ORDERMEDICINE_LIST_FAIL";

export const ValidateDiscountCoupon_REQUEST = 'ValidateDiscountCoupon_REQUEST';
export const ValidateDiscountCoupon_SUCCESS = 'ValidateDiscountCoupon_SUCCESS';
export const ValidateDiscountCoupon_FAIL = 'ValidateDiscountCoupon_FAIL';

export const StandardCodesAudit_LIST_REQUEST = 'StandardCodesAudit_LIST_REQUEST';
export const StandardCodesAudit_LIST_SUCCESS = 'StandardCodesAudit_LIST_SUCCESS';
export const StandardCodesAudit_LIST_FAIL = 'StandardCodesAudit_LIST_FAIL';

export const POSTBILLPACKAGEMULREGDET_LIST_REQUEST = 'POSTBILLPACKAGEMULREGDET_LIST_REQUEST';
export const POSTBILLPACKAGEMULREGDET_LIST_SUCCESS = 'POSTBILLPACKAGEMULREGDET_LIST_SUCCESS';
export const POSTBILLPACKAGEMULREGDET_LIST_FAIL = 'POSTBILLPACKAGEMULREGDET_LIST_FAIL';

export const POSTBILLOPPackageRender_LIST_REQUEST = 'POSTBILLOPPackageRender_LIST_REQUEST';
export const POSTBILLOPPackageRender_LIST_SUCCESS = 'POSTBILLOPPackageRender_LIST_SUCCESS';
export const POSTBILLOPPackageRender_LIST_FAIL = 'POSTBILLOPPackageRender_LIST_FAIL';

export const PAYMENTTRANSACTION_LIST_REQUEST = 'PAYMENTTRANSACTION_LIST_REQUEST';
export const PAYMENTTRANSACTION_LIST_SUCCESS = 'PAYMENTTRANSACTION_LIST_SUCCESS';
export const PAYMENTTRANSACTION_LIST_FAIL = 'PAYMENTTRANSACTION_LIST_FAIL';

export const GSTBillServiceOrder_REQUEST = 'GSTBillServiceOrder_REQUEST';
export const GSTBillServiceOrder_SUCCESS = 'GSTBillServiceOrder_SUCCESS';
export const GSTBillServiceOrder_FAIL = 'GSTBillServiceOrder_FAIL';

export const PINLAB_LIST_REQUEST = 'PINLAB_LIST_REQUEST';
export const PINLAB_LIST_FAIL = 'PINLAB_LIST_FAIL';
export const PINLAB_LIST_SUCCESS = 'PINLAB_LIST_SUCCESS';

export const PaymentGateway_LIST_REQUEST = 'PaymentGateway_LIST_REQUEST';
export const PaymentGateway_LIST_FAIL = 'PaymentGateway_LIST_FAIL';
export const PaymentGateway_LIST_SUCCESS = 'PaymentGateway_LIST_SUCCESS';

export const FacilityPaymentGateway_LIST_REQUEST = 'FacilityPaymentGateway_LIST_REQUEST';
export const FacilityPaymentGateway_LIST_FAIL = 'FacilityPaymentGateway_LIST_FAIL';
export const FacilityPaymentGateway_LIST_SUCCESS = 'FacilityPaymentGateway_LIST_SUCCESS';

export const OtSlabCharges_SAVE_REQUEST = 'OtSlabCharges_SAVE_REQUEST';
export const OtSlabCharges_SAVE_FAIL = 'OtSlabCharges_SAVE_FAIL';
export const OtSlabCharges_SAVE_SUCCESS = 'OtSlabCharges_SAVE_SUCCESS';

export const OtSlabCharges_LIST_REQUEST = 'OtSlabCharges_LIST_REQUEST';
export const OtSlabCharges_LIST_FAIL = 'OtSlabCharges_LIST_FAIL';
export const OtSlabCharges_LIST_SUCCESS = 'OtSlabCharges_LIST_SUCCESS';

export const FINANCIAL_LIST_REQUEST = 'FINANCIAL_LIST_REQUEST';
export const FINANCIAL_LIST_FAIL = 'FINANCIAL_LIST_FAIL';
export const FINANCIAL_LIST_SUCCESS = 'FINANCIAL_LIST_SUCCESS';

